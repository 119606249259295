<template>
  <div v-if="!loading">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ id ? t('pageTitleEditingText') : t('pageTitleAdditionText') }} {{ t('pageTitleStaticText') }} {{formData?.NAME}}</h2>
    </div>
    <div class="grid grid-cols-12 gap-12 mt-12">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div>
            <label for="crud-form-1" class="form-label">{{ t('stageNumberLabelText') }}</label>
            <input
                id="crud-form-1"
                v-model="formData.NUMBER"
                type="text"
                class="form-control w-full"
                required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-2" class="form-label">{{ t('stageNameLabelText') }}</label>
            <input
                id="crud-form-2"
                v-model="formData.NAME"
                type="text"
                class="form-control w-full"
                required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-3" class="form-label">{{ t('stageTypeLabelText') }}</label>
            <select id="crud-form-3" v-model="formData.TYPE" class="w-full form-select">
              <option value="IM">{{ t('integrationTypeOnlineStoreText') }}</option>
              <option value="PROV">{{ t('integrationTypeProviderText') }}</option>
              <option value="FF">{{ t('integrationTypeFulfillmentText') }}</option>
              <option value="MP">{{ t('integrationTypeMarketPlace') }}</option>
              <option value="CRM">{{ t('integrationTypeCRMText') }}</option>
            </select>
          </div>
          <div class="mt-3">
            <label for="crud-form-4" class="form-label">{{ t('integrationLabelText') }}</label>
            <TomSelect id="crud-form-4"
                       v-if="integrations !== null"
                       v-model="formData.INTEGRATION.ID"
                       class="w-full">
              <option v-for="(item, itemKey) in integrations" :key="itemKey" :value="item.ID">
                {{ item.NAME }}
              </option>
            </TomSelect>
          </div>
          <div class="mt-3">
            <label>{{ t('isActiveText') }}</label>
            <div class="mt-2">
              <input type="checkbox" v-model="active" class="form-check-switch" />
            </div>
          </div>
          <div class="text-right mt-5">
            <router-link :to="{ name: 'stages' }" tag="button" class="btn btn-outline-secondary w-24 mr-1">
              {{ t('cancelBtnText') }}
            </router-link>
            <button class="btn btn-primary max-w-max"
                    type="button"
                    @click.prevent="saveStage(id, '/stages')">
              {{ t('saveBtnText') }}
              <LoadingIcon icon="ball-triangle" v-if="sendingData" class="'w-4 h-4 ml-1"/>
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import { useDataSending } from '@/use/useDataSending'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'StagesForm',
  props: {
    id: String
  },
  setup(props) {
    // инициализация загрузки данных о текущем этапе
    let currentStage
    if (props.id) {
      currentStage = useStoreDataGetting(
        'stages/getCurrentStage',
        'stages/loadStageById',
        { id: props.id })
    }

    // инициализация загрузки данных об интеграциях
    const integrations = useStoreDataGetting(
      'integrations/getIntegrations',
      'integrations/loadIntegrations')

    // сохранение данных этапа
    const { active, formData, sendData, sendingData } = useDataSending(
      'stages/updateStage',
      'stages/addStage',
      currentStage?.data)
    formData.INTEGRATION = { ID: '0' }

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitleEditingText: 'تحرير',
          pageTitleAdditionText: 'إضافة',
          pageTitleStaticText: 'للمرحلة',
          stageNumberLabelText: 'المرحلة',
          stageNameLabelText: 'اسم',
          stageTypeLabelText: 'تاريخ التعديل الأخير',
          integrationLabelText: 'التكامل',
          saveBtnText: 'نقذ',
          cancelBtnText: 'إلغاء',
          integrationTypeOnlineStoreText: 'متجر على الإنترنت',
          integrationTypeProviderText: 'مزود',
          integrationTypeMarketPlace: 'سوق',
          integrationTypeFulfillmentText: 'وفاء',
          integrationTypeCRMText: 'CRM',
          isActiveText: 'نشط'
        },
        en: {
          pageTitleEditingText: 'Editing',
          pageTitleAdditionText: 'Addition',
          pageTitleStaticText: 'of stage',
          stageNumberLabelText: 'Stage number',
          stageNameLabelText: 'Name',
          stageTypeLabelText: 'Type',
          integrationLabelText: 'Integrations',
          saveBtnText: 'Save',
          cancelBtnText: 'Cancel',
          integrationTypeOnlineStoreText: 'Online store',
          integrationTypeProviderText: 'Provider',
          integrationTypeMarketPlace: 'Marketplace',
          integrationTypeFulfillmentText: 'Fulfillment',
          integrationTypeCRMText: 'CRM',
          isActiveText: 'Active'
        },
        ru: {
          pageTitleEditingText: 'Редактирование',
          pageTitleAdditionText: 'Добавление',
          pageTitleStaticText: 'этапа',
          stageNumberLabelText: 'Номер этапа',
          stageNameLabelText: 'Название',
          stageTypeLabelText: 'Тип',
          integrationLabelText: 'Интеграция',
          saveBtnText: 'Сохранить',
          cancelBtnText: 'Отмена',
          integrationTypeOnlineStoreText: 'интернет-магазин',
          integrationTypeProviderText: 'Поставщик',
          integrationTypeMarketPlace: 'Маркетплейс',
          integrationTypeFulfillmentText: 'Фулфилмент',
          integrationTypeCRMText: 'CRM',
          isActiveText: 'Активен'
        }
      }
    })

    return {
      loading: currentStage?.loading || integrations.loading,
      integrations: integrations.data,
      active,
      formData,
      saveStage: sendData,
      sendingData,
      t
    }
  }
})
</script>
