<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Транспортные компании</h2>
    <dataList v-if="true" :settings="{
              dataGetterPath:'logistics/getLogisticsCompanies',
              loadDataPath: 'logistics/loadLogisticsCompanies',
              deleteDataItemPath: 'logistics/deleteLogisticCompany',
              createItemRoute: 'logistics-companies-add',
              updateItemRoute: 'logistics-companies-edit',
              addButtonName: 'Добавить ТК',
              firstNameField: 'NAME',
              secondNameField: 'TYPE',
              deleteConfirmationContent: 'этой компанией'
            }"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LogisticsCompaniesSettings'
})
</script>
