import { helper as $h } from '@/utils/helper'
import _ from 'lodash'

const state = () => {
  return {
    products: null,
    productItem: {},
    productsPagination: null,
    productsSettings: null,
    integrationFields: null
  }
}

// getters
const getters = {
  getProducts: state => state.products,
  getProductItem: state => state.productItem,
  getProductsPagination: state => state.productsPagination,
  getProductsSettings: state => state.productsSettings,
  getIntegrationFields: state => state.integrationFields
}

// actions
const actions = {
  async loadProducts({ getters, dispatch, commit }, payload) {
    await dispatch(
      'query/sendRequest',
      {
        url: 'products/get',
        data: {},
        filter: payload.filter,
        nav: payload.nav,
        method: 'POST'
      },
      { root: true }
    ).then(res => {
      commit('setProducts', res.data.result)
      commit('setProductsPagination', res.data.nav)
    })
  },
  async loadProductItem({ state, dispatch, commit }, payload) {
    await dispatch(
      'query/sendRequest',
      {
        url: 'products/get-by-id/' + payload.id,
        method: 'POST'
      },
      { root: true }
    ).then(res => {
      if (res?.data) commit('setProductItem', res.data)
    })
  },
  async addProduct({ dispatch }, payload) {
    return await dispatch(
      'query/sendRequest',
      {
        url: 'products/add',
        data: payload,
        method: 'POST'
      },
      { root: true }
    )
  },
  async updateProduct({ dispatch }, payload) {
    return await dispatch(
      'query/sendRequest',
      {
        url: 'products/update',
        data: payload,
        method: 'POST'
      },
      { root: true }
    )
  },
  async importProducts({ dispatch }, data) {
    await dispatch(
      'query/sendRequest',
      {
        url: 'products/import',
        data,
        method: 'POST'
      },
      { root: true }
    )
  },
  async loadProductsSettings({ dispatch, commit }) {
    return await dispatch(
      'query/sendRequest',
      {
        url: 'products/get-settings',
        method: 'POST'
      },
      { root: true }
    ).then(res => commit('setProductSettings', res.data))
  },
  async saveProductsSettings({ dispatch, commit }, payload) {
    return await dispatch(
      'query/sendRequest',
      {
        url: 'products/set-settings',
        data: payload,
        method: 'POST'
      },
      { root: true }
    )
  },
  async loadIntegrationFields({ dispatch, commit }, payload) {
    return await dispatch(
      'query/sendRequest',
      {
        url: 'products/get-integration-fields',
        data: payload,
        method: 'POST'
      },
      { root: true }
    ).then(res => commit('setIntegrationFields', res.data))
  }
}

// mutations
const mutations = {
  setProducts(state, data) {
    Object.values(data).forEach(item => {
      item.PRICE = item.PRICE.length > 0 ? JSON.parse(item.PRICE) : ''
    })
    state.products = data
  },
  setProductItem(state, data) {
    data.PICTURES = data.PICTURES ? JSON.parse(data.PICTURES) : []
    data.ATTRIBUTES = data.ATTRIBUTES ? JSON.parse(data.ATTRIBUTES) : {}
    if (_.isEmpty(data.ATTRIBUTES.sfAttrs)) data.ATTRIBUTES.sfAttrs = [{}]
    if (data?.PRICE) data.PRICE = data.PRICE = JSON.parse(data.PRICE)
    if (data?.PRICE?.RETAIL_PRICE) {
      data.PRICE.RETAIL_PRICE = $h.cutFraction(data?.PRICE?.RETAIL_PRICE)
      data.PRICE.PRICE = $h.cutFraction(data.PRICE.PRICE)
    }

    state.productItem = data
  },
  setProductsPagination(state, data) {
    state.productsPagination = data
  },
  setProductSettings: (state, data) => {
    state.productsSettings = data
  },
  setIntegrationFields: (state, data) => {
    state.integrationFields = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
