<template>
  <div>
<!--    <input class="mt-4" type="file" @change="onChange">-->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ t('pageTitle') }}</h2>
      <ItemsAmountShown v-if="!tableLoading" paginationGetterPath="movements/getMovementsPagination"/>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <a href="javascript:;"
           class="btn btn-primary shadow-md mr-2"
           data-toggle="modal"
           data-target="#import-from-excel-modal"
           @click.prevent="setNewVueKeyForImport">
          {{ t('importFromExcelBtn') }}
        </a>
        <router-link :to="{ name: 'movement-adding' }" tag="a" class="btn btn-primary shadow-md">
          {{ t('createMovementBrn') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y box px-2 py-3  mt-5">
      <BaseFiltration v-if="!stagesLoading && !tableLoading"
                      :filtersParams="[
                        { prop: '%STAGE_FROM', name: t('filterLabelFrom'), type: 'select', selectData: stagesData },
                        { prop: '%STAGE_TO', name: t('filterLabelTo'), type: 'select', selectData: stagesData },
                        { prop: '%EXTERNAL_ID', name: 'Внешний ID' }
                      ]">
    </BaseFiltration>
      <!-- BEGIN: HTML Table Data -->
      <DataTable dataGetterPath="movements/getMovements"
                 actionDataPath="movements/loadMovements"
                 @loading="tableLoading = $event">
        <template v-slot:tableHead>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('movementColID') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('filterPropExternalID') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('movementColFrom') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('movementColTo') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('dateOfMovementCreationText') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('movementColDetails') }}
          </th>
        </template>
        <template v-slot:tableBody="tableBody">
          <td class="border-b whitespace-nowrap">
            <RouterLink
              :to="{
                name: 'movement',
                params: {
                  id: tableBody.item.ID
                }
              }">
              {{ tableBody.item.ID }}
            </RouterLink>
          </td>
          <td class="border-b whitespace-nowrap">{{ tableBody.item.EXTERNAL_ID }}</td>
          <td class="border-b whitespace-nowrap">{{ tableBody.item.STAGE_FROM.NAME }}</td>
          <td class="border-b whitespace-nowrap">{{ tableBody.item.STAGE_TO.NAME }}</td>
          <td class="border-b whitespace-nowrap">{{ tableBody.item.STAGE_TO.DATE }}</td>
          <td class="border-b whitespace-nowrap">
            <RouterLink
              :to="{
                name: 'movement',
                params: {
                  id: tableBody.item.ID
                }
              }"
              class="block">
              <CheckSquareIcon class="w-4 h-4 mr-1" />
              <span>{{ t('movementDetailsBtn') }}</span>
            </RouterLink>
          </td>
        </template>
      </DataTable>
      <!-- END: HTML Table Data -->
      <!-- BEGIN: Pagination -->
      <div v-if="!tableLoading" class="col-span-12 flex justify-between sm:flex-row sm:flex-nowrap items-center px-1 mt-6">
        <BasePagination paginationGetterPath="movements/getMovementsPagination"/>
        <div class="mt-5 sm:mt-0 mr-auto lg:flex hidden" id="forOutputTableTeleport"/>
        <SelectItemsPerPage/>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>
  <MovementsImport :key="newVueKeyForMovementsImport"/>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import { useReRenderingForComponent } from '@/use/useRerenderingForComponent'
import BaseFiltration from '@/components/base-filtration/Main'
import BasePagination from '@/global-components/base-pagination/Main'
import DataTable from '@/components/data-table/Main'
import MovementsImport from '@/components/movement/movements-import/Main'

export default defineComponent({
  name: 'MovementList',
  components: {
    MovementsImport,
    BasePagination,
    BaseFiltration,
    DataTable
  },
  setup() {
    const tableLoading = ref()

    // инициализация загрузки данных об этапах
    const stages = useStoreDataGetting(
      'stages/getStages',
      'stages/loadStages'
    )

    // ре-рендеринг модального окна с импортом из документа
    const { newVueKeyForMovementsImport, setNewVueKeyForImport } = useReRenderingForComponent()

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitle: 'حركات',
          importFromExcelBtn: 'استيراد من التفوق',
          createMovementBrn: 'لخلق حركة',
          movementColID: 'ID',
          filterPropExternalID: 'خارج ID',
          tableCellExternalID: 'خارج ID',
          movementColFrom: 'من',
          movementColTo: 'ل',
          movementColDetails: 'تفاصيل',
          filterLabelFrom: 'من',
          filterLabelTo: 'ل',
          movementDetailsBtn: 'حرر',
          editMovementModalTitle: 'بارامترات ترتيب التغيير',
          dateOfMovementCreationText: ''
        },
        en: {
          pageTitle: 'Movements',
          importFromExcelBtn: 'Import from excel',
          createMovementBrn: 'Create movement',
          movementColID: 'ID',
          filterPropExternalID: 'External ID',
          tableCellExternalID: 'External ID',
          movementColFrom: 'From',
          movementColTo: 'To',
          movementColDetails: 'Details',
          filterLabelFrom: 'From',
          filterLabelTo: 'To',
          movementDetailsBtn: 'Show details',
          editMovementModalTitle: 'Change order parameters',
          dateOfMovementCreationText: 'Date of creation'
        },
        ru: {
          pageTitle: 'Перемещения',
          importFromExcelBtn: 'Импортировать из excel',
          createMovementBrn: 'Создать перемещение',
          movementColID: 'ID',
          filterPropExternalID: 'Внешний ID',
          tableCellExternalID: 'Внешний ID',
          movementColFrom: 'Откуда',
          movementColTo: 'Куда',
          movementColDetails: 'Детали',
          filterLabelFrom: 'Откуда',
          filterLabelTo: 'Куда',
          movementDetailsBtn: 'Подробнее',
          editMovementModalTitle: 'Изменить параметры заказа',
          dateOfMovementCreationText: 'Дата создания'
        }
      }
    })

    return {
      stagesData: stages.data,
      stagesLoading: stages.loading,
      newVueKeyForMovementsImport,
      setNewVueKeyForImport,
      tableLoading,
      t
    }
  }
})
</script>
<style scoped lang="scss">
body {
  user-select: none;
}

#draggable {
  text-align: center;
  background: white;
}

.dropzone1 {
  width: 200px;
  height: 20px;
  background: blueviolet;
  margin: 10px;
  padding: 10px;
}
</style>
