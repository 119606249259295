<template>
  <div class="overflow-x-auto">
    <table
      v-if="!$_.isEmpty(shownItems)"
      class="table mb-2"
    >
      <thead>
        <tr class="bg-gray-200 dark:bg-dark-1">
          <slot name="tableHead"/>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in shownItems"
          :key="itemKey + index"
          :class="{ 'bg-gray-200 dark:bg-dark-1': index % 2 === 1 }"
        >
          <slot
            name="tableBody"
            :item="item"
            :index="index"
          />
        </tr>
      </tbody>
    </table>
    <div
      v-if="data?.length > itemsPerPage"
      class="flex">
      <button class="p-1 border rounded-sm mr-1" @click="currentPage = 1">
        <ChevronsLeftIcon class="w-4 h-4"/>
      </button>
      <button class="p-1 border rounded-sm mx-1" @click="decreasePage">
        <ChevronLeftIcon class="w-4 h-4"/>
      </button>
      <span class="py-1 px-2 border rounded-sm mx-1">{{ currentPage }}</span>
      <button class="p-1 border rounded-sm mx-1" @click="incrementPage">
        <ChevronRightIcon class="w-4 h-4"/>
      </button>
      <button class="p-1 border rounded-sm ml-1" @click="currentPage = pagesCount">
        <ChevronsRightIcon class="w-4 h-4"/>
      </button>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'LocalTable',
  props: {
    data: Array,
    itemKey: String
  },
  setup (props) {
    const currentPage = ref(1)
    const itemsPerPage = ref(10)
    const pagesCount = computed(() => Math.ceil(props.data.length / itemsPerPage.value))
    const shownItems = computed(() => {
      if (props.data?.length < itemsPerPage.value) return props.data

      return props.data.filter((item, index) => {
        const itemNumber = index + 1
        const firstItemOfPage = currentPage.value * itemsPerPage.value - (itemsPerPage.value - 1)
        const lastItemOfPage = currentPage.value * itemsPerPage.value

        return itemNumber > firstItemOfPage && itemNumber <= lastItemOfPage
      })
    })

    const incrementPage = () => {
      if (currentPage.value === pagesCount.value) return
      currentPage.value++
    }

    const decreasePage = () => {
      if (currentPage.value === 1) return
      currentPage.value--
    }

    return {
      currentPage,
      pagesCount,
      itemsPerPage,
      shownItems,
      incrementPage,
      decreasePage
    }
  }
})
</script>

<style scoped>

</style>
