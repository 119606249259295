<template>
  <div v-if="!loading">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ id ? 'Редактирование' : 'Добавление' }} задания {{formData?.NAME}}</h2>
      <router-link v-if="id" :to="{ name: 'task-logbook', params: { page: '1', pageSize: '10' }, query: { ID_TASK: id} }" tag="a" class="btn btn-primary w-24">
        Журнал
      </router-link>
    </div>
    <div class="grid grid-cols-12 gap-12 mt-12">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div>
            <label for="crud-form-1" class="form-label">Название</label>
            <input
              id="crud-form-1"
              v-model="formData.NAME"
              type="text"
              class="form-control w-full"
              placeholder="Введите название"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-2" class="form-label">Действия</label>
            <select id="crud-form-2" v-model="formData.ENTITY" class="w-full form-select">
              <option value="PRICES">Цены</option>
              <option value="STOCKS">Остатки</option>
              <option value="ORDERS">Заказы</option>
            </select>
          </div>
          <div class="mt-3" v-if="formData.ENTITY">
            <select id="crud-form-3" v-model="formData.MOVE" class="w-full form-select">
              <option value="FROM">Загрузить в систему из</option>
              <option value="TO">Выгрузить из системы в</option>
            </select>
          </div>
          <div class="mt-3" v-if="formData.MOVE">
            <TomSelect id="crud-form-4" v-model="formData.INTEGRATION.ID" class="w-full">
              <option v-for="(item, itemKey) in integrations" :key="itemKey" :value="item.ID">
                {{ item.NAME }}
              </option>
            </TomSelect>
          </div>

          <div class="mt-3" v-if="formData.INTEGRATION.ID !== '0'">
            <label class="form-label">Настройки</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group">
                <div id="input-group-3" class="input-group-text whitespace-nowrap">Периодичность в часах</div>
                <select id="input-group-3" v-model="formData.SETTINGS.period" class="w-full form-select border-l-0 rounded-l-none">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="6">6</option>
                  <option value="12">12</option>
                  <option value="24">24</option>
                </select>
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-4_3" class="input-group-text whitespace-nowrap">Экспорт с этапа</div>
                <select id="crud-form-4_3" v-model="formData.SETTINGS.stage" class="w-full form-select border-l-0 rounded-l-none">
                  <option v-for="(item, itemKey) in stages" :key="itemKey" :value="item.ID">
                    {{ item.NAME }}
                  </option>
                </select>
              </div>
              <BaseInput v-if="currentIntegrationName === 'Ozon'"
                         v-model="formData.SETTINGS.warehouseId"
                         id="warehouseId"
                         label="Целевой склад на Ozon"
                         placeholder="ID склада в Ozon"
              />
              <BaseInput v-if="currentIntegrationName === 'Ozon'"
                         v-model="formData.SETTINGS.googleDocLink"
                         id="googleDocLink"
                         label="ID документа в Google Doc"
                         placeholder="ID документа в Google Doc"
              />
              <BaseInput v-if="currentIntegrationName === 'RetailCRM'"
                         v-model="formData.SETTINGS.orderType"
                         id="orderType"
                         label="Код типа заказа в RetailCRM"
                         placeholder="Код типа заказа"
              />
              <BaseInput v-if="currentIntegrationName === 'RetailCRM'"
                         v-model="formData.SETTINGS.firstName"
                         id="firstName"
                         label="Имя покупателя в RetailCRM"
                         placeholder="Имя покупателя"
              />
              <BaseInput v-if="currentIntegrationName === 'RetailCRM'"
                         v-model="formData.SETTINGS.lastName"
                         id="lastName"
                         label="Фамилия покупателя в RetailCRM"
                         placeholder="Фамилия покупателя"
              />
              <BaseInput v-if="currentIntegrationName === 'RetailCRM'"
                         v-model="formData.SETTINGS.patronymic"
                         id="patronymic"
                         label="Отчество покупателя в RetailCRM"
                         placeholder="Отчество покупателя"
              />
              <BaseInput v-if="currentIntegrationName === 'RetailCRM'"
                         v-model="formData.SETTINGS.phone"
                         id="phone"
                         label="Телефон покупателя в RetailCRM"
                         placeholder="Телефон покупателя"
              />
              <BaseInput v-if="currentIntegrationName === 'RetailCRM'"
                         v-model="formData.SETTINGS.email"
                         id="email"
                         label="Email покупателя в RetailCRM"
                         placeholder="Email покупателя"
              />
              <BaseInput v-if="currentIntegrationName === 'RetailCRM'"
                         v-model="formData.SETTINGS.site"
                         id="site"
                         label="Сайт в RetailCRM"
                         placeholder="Сайт в системе RetailCRM"
              />
            </div>
          </div>
          <div class="mt-3">
            <label>Активен</label>
            <div class="mt-2">
              <input type="checkbox" v-model="active" class="form-check-switch" />
            </div>
          </div>
          <div class="text-right mt-5">
            <router-link :to="{ name: 'automation' }" tag="button" class="btn btn-outline-secondary w-24 mr-1">
              Отмена
            </router-link>
            <button type="button" class="btn btn-primary w-24" @click="saveAutomation">
              Сохранить
              <LoadingIcon icon="ball-triangle" v-if="sendingData" class="'w-4 h-4 ml-1"/>
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import { useDataSending } from '@/use/useDataSending'
import BaseInput from '@/components/base-input/Main'

export default defineComponent({
  name: 'AutomationForm',
  components: { BaseInput },
  props: {
    id: String
  },
  setup(props) {
    // Загрузка списка интеграций
    const integrationsList = useStoreDataGetting(
      'integrations/getIntegrations',
      'integrations/loadIntegrations')

    // Загрузка списка этапов
    const stages = useStoreDataGetting(
      'stages/getStages',
      'stages/loadStages')

    // Загрузка данных о текущем этапе
    let currentAutomation
    if (props.id) {
      currentAutomation = useStoreDataGetting(
        'automations/getCurrentAutomation',
        'automations/loadAutomationById',
        { id: props.id || '' })
    }

    // сохранение данных задания
    const { active, formData, sendData, sendingData } = useDataSending(
      'automations/updateAutomation',
      'automations/addAutomation',
      currentAutomation?.data)
    formData.INTEGRATION = { ID: '0' }
    formData.SETTINGS = {}

    const saveAutomation = () => {
      formData.SETTINGS = JSON.stringify(formData.SETTINGS)
      sendData(props.id, '/automation')
    }

    // Изменение списка интеграций
    const mapActions = {
      STOCKS: ['Ozon', 'WB'],
      PRICES: ['Ozon', 'WB', 'RetailCRM'],
      ORDERS: ['SDT', 'RetailCRM']
    }
    const integrations = ref([])
    const currentIntegrationName = computed(() => formData.INTEGRATION?.TYPE)

    watch([formData, integrationsList.loading], () => {
      if (formData?.MOVE === 'TO' && !integrationsList.loading.value) {
        integrations.value = integrationsList.data.value.filter(item => {
          return mapActions[formData.ENTITY].includes(item.TYPE)
        })
      } else {
        integrations.value = integrationsList.data.value
      }
    }, { deep: true, immediate: true })

    return {
      stages: stages.data,
      active,
      formData,
      saveAutomation,
      loading: currentAutomation?.loading || integrationsList?.loading || stages?.loading,
      integrations,
      currentIntegrationName,
      sendingData
    }
  }
})
</script>
