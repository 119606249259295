<template>
  <!-- BEGIN: HTML Table Data -->
  <div class="overflow-x-auto px-1">
    <IntroPreloader v-if="loading"/>
    <table v-if="!loading"
           ref="table"
           class="table p-1">
      <thead>
        <tr class="bg-gray-200 dark:bg-dark-1">
          <slot name="tableHead"/>
        </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in data"
          :key="item.ID"
          :class="{ 'bg-gray-200 dark:bg-dark-1': index % 2 === 1 }"
      >
        <slot name="tableBody"
              :item="item"
              :index="index"/>
      </tr>
      </tbody>
    </table>

    <OutputTable v-if="!loading" :table="table"/>
  </div>
  <!-- END: HTML Table Data -->
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'DataTable',
  props: {
    requiredFilter: String,
    dataGetterPath: String,
    actionDataPath: String
  },
  setup(props, { emit }) {
    // инициализация таблицы
    const store = useStore()
    const route = useRoute()
    const loading = ref(true)
    const table = ref()
    const data = computed(() => store.getters[props.dataGetterPath])

    const getData = () => {
      loading.value = true
      emit('loading', true)
      store.dispatch(props.actionDataPath, {
        nav: route.params,
        filter: route.query
      }, { root: true })
        .finally(() => {
          loading.value = false
          emit('loading', false)
        })
    }

    watch([
      () => route.query,
      () => route.params.page,
      () => route.params.pageSize,
      () => route.name
    ], (newVal, oldVal) => {
      if (props.requiredFilter && !route.query[props.requiredFilter]) return
      if (newVal[3] !== oldVal[3]) return
      getData()
    })

    onMounted(() => {
      getData()
    })

    return {
      table,
      data,
      loading
    }
  }
})
</script>

<style scoped>

</style>
