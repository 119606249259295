<template>
  <h2 class="text-xl font-medium mr-auto mt-4 mb-3">{{ t('pageTitle') }}</h2>
  <div class="pos mb-5">
    <div class="intro-y">
      <div class="box p-2">
        <div class="pos__tabs nav nav-tabs justify-center flex-col sm:flex-row" role="tablist">
          <a class="flex-1 py-2 rounded-md text-center active"
             id="base-tab"
             data-toggle="tab"
             data-target="#base"
             href="javascript:;"
             role="tab"
             aria-controls="base"
             aria-selected="true"
          >
            <SettingsIcon class="w-4 h-4 mr-2" /> {{ t('settingsTabBtn') }}
          </a>
          <a class="flex-1 py-2 rounded-md text-center"
             id="add-tab"
             data-toggle="tab"
             data-target="#loyalty_statuses"
             href="javascript:;"
             role="tab"
             aria-controls="loyalty_statuses"
             aria-selected="false"
          >
            <BoxIcon class="w-4 h-4 mr-2" /> {{ t('loyaltyStatusesTabBtn') }}
          </a>
          <a class="flex-1 py-2 rounded-md text-center"
             id="add-tab"
             data-toggle="tab"
             data-target="#spec_dates"
             href="javascript:;"
             role="tab"
             aria-controls="spec_dates"
             aria-selected="false"
          >
            <BoxIcon class="w-4 h-4 mr-2" /> {{ t('specialDatesTabBtn') }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-content">
    <!-- BEGIN: Основные данные -->
    <div class="intro-y box tab-pane active"
         id="base"
         role="tabpanel"
         aria-labelledby="base-tab">
      <LoyaltySettings/>
    </div>
    <!-- END: Основные данные -->
    <!-- BEGIN: Статусы лояльности -->
    <div class="intro-y mt-5 tab-pane"
         id="loyalty_statuses"
         role="tabpanel"
         aria-labelledby="add-tab"
    >
      <data-list :settings="{
              dataGetterPath:'loyaltyStatuses/getLoyaltyStatuses',
              loadDataPath: 'loyaltyStatuses/loadLoyaltyStatuses',
              deleteDataItemPath: 'loyaltyStatuses/deleteLoyaltyStatus',
              createItemRoute: 'loyalty-status-add',
              updateItemRoute: 'loyalty-status-edit',
              addButtonName: t('addStatusButtonName'),
              firstNameField: 'NAME',
              secondNameFieldBefore: t('statusSecondNameFieldBefore'),
              secondNameField: 'SUM_FROM',
              secondNameFieldAfter: t('statusSecondNameFieldAfter'),
              secondNameFieldLast: 'SUM_TO',
              deleteModalId: 'delete-loyalty-status-modal',
              deleteConfirmationContent: t('statusDeleteConfirmText')
            }"
      />
    </div>
    <!-- END: Статусы лояльности -->
    <!-- BEGIN: Специальные даты -->
    <div class="intro-y mt-5 tab-pane"
         id="spec_dates"
         role="tabpanel"
         aria-labelledby="add-tab"
    >
      <data-list :settings="{
              dataGetterPath:'loyaltyDates/getLoyaltyDates',
              loadDataPath: 'loyaltyDates/loadLoyaltyDates',
              deleteDataItemPath: 'loyaltyDates/deleteLoyaltyDate',
              createItemRoute: 'loyalty-dates-add',
              updateItemRoute: 'loyalty-dates-edit',
              addButtonName: t('addSpecialDateBtnText'),
              secondNameFieldBefore: t('specialDateSecondNameFieldBeforeText'),
              firstNameField: 'DATE',
              secondNameField: 'POINTS',
              deleteModalId: 'delete-special-date-modal',
              deleteConfirmationContent: t('specialDateDeleteConfirmText')
            }"
      />
    </div>
    <!-- END: Специальные даты -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import LoyaltySettings from '@/components/loyalty-settings/Main'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'LoyaltySettingsMain',
  components: { LoyaltySettings },
  setup() {
    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitle: 'ولاء',
          settingsTabBtn: 'إعدادات',
          loyaltyStatusesTabBtn: 'حالات الولاء',
          specialDatesTabBtn: 'تواريخ خاصة',
          addStatusButtonName: 'وضع جديد',
          statusSecondNameFieldBefore: 'من',
          statusSecondNameFieldAfter: 'إلى',
          statusDeleteConfirmText: 'هذا الوضع',
          addSpecialDateBtnText: 'تاريخ خاص جديد',
          specialDateSecondNameFieldBeforeText: 'نقاط',
          specialDateDeleteConfirmText: 'هذا التاريخ'
        },
        en: {
          pageTitle: 'Loyalty',
          settingsTabBtn: 'Settings',
          loyaltyStatusesTabBtn: 'Loyalty statuses',
          specialDatesTabBtn: 'Special dates',
          addStatusButtonName: 'New status',
          statusSecondNameFieldBefore: 'From',
          statusSecondNameFieldAfter: 'to',
          statusDeleteConfirmText: 'this status',
          addSpecialDateBtnText: 'New special date',
          specialDateSecondNameFieldBeforeText: 'Points:',
          specialDateDeleteConfirmText: 'this date'
        },
        ru: {
          pageTitle: 'Лояльность',
          settingsTabBtn: 'Настройки',
          loyaltyStatusesTabBtn: 'Статусы лояльности',
          specialDatesTabBtn: 'Специальные даты',
          addStatusButtonName: 'Новый статус',
          statusSecondNameFieldBefore: 'От',
          statusSecondNameFieldAfter: 'до',
          statusDeleteConfirmText: 'этим статусом',
          addSpecialDateBtnText: 'Новая специальная дата',
          specialDateSecondNameFieldBeforeText: 'Баллов:',
          specialDateDeleteConfirmText: 'этой датой'
        }
      }
    })

    return { t }
  }
})
</script>
