<template>
  <div :class="[ 'relative', { 'input-group mt-2': inputGroup }, { 'input-form  mt-4': !inputGroup } ]">
    <template v-if="inputGroup" >
      <label :for="id" class="input-group-text whitespace-nowrap"> Время </label>
      <input
        :id="id"
        v-model="timeValue"
        ref="maskedTimeRef"
        type="text"
        class="form-control"
        placeholder="Введите время"
        :aria-describedby="id"
      />
    </template>

    <template v-if="!inputGroup" >
      <label :for="id" class="form-label mb-1 w-full flex flex-col sm:flex-row"> Время </label>
      <input
        :id="id"
        v-model="timeValue"
        ref="maskedTimeRef"
        type="text"
        class="form-control"
        placeholder="Введите время"
        :aria-describedby="id"
      />
    </template>

    <slot></slot>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import Inputmask from 'inputmask'

export default defineComponent({
  name: 'BaseTimeInput',
  props: {
    id: String,
    modelValue: [String, Number],
    inputGroup: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const timeLength = 5
    const maskedTimeRef = ref()
    let maskedTime

    const timeValue = computed({
      get: () => props.modelValue,
      set: () => {
        const unmaskedVal = maskedTimeRef.value.inputmask.unmaskedvalue()
        const emittedVal = unmaskedVal.slice(0, 2) + ':' + unmaskedVal.slice(2)
        emit('update:modelValue', emittedVal)
      }
    })

    const init = () => {
      maskedTime = new Inputmask('99:99')
      maskedTime.mask(maskedTimeRef.value)
    }

    onMounted(() => {
      init()
    })

    return {
      timeLength,
      maskedTimeRef,
      timeValue
    }
  }
})
</script>

<style scoped>

</style>
