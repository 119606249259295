<template>
  <!-- BEGIN: Super Large Slide Over Content -->
  <div id="import-from-excel-modal" class="modal modal-slide-over" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header p-5">
          <h2 class="font-medium text-base mr-auto">
            Импортировать перемещения из excel файла
          </h2>
        </div>
        <form class="modal-body" @submit.prevent="onSubmitData">
          <div class="input-form relative">
            <label for="delivery_date_input" class="form-label mb-1 w-full flex flex-col sm:flex-row"> Предполагаемая дата поставки </label>
            <Litepicker id="delivery_date_input" v-model="formData.date"
                        placeholder="Выберите дату"
                        :options=" {
                        autoApply: true,
                        format: 'DD.MM.YYYY',
                        lang: 'ru',
                        setInitValue: false,
                        dropdowns: {
                          minYear: 1900,
                          months: true,
                          years: true
                        }
                      }"
                        class="form-control"/>
            <BaseErrorMessage :validationState="v$" validatedProperty="date"/>
          </div>
          <BaseTimeInput v-model="formData.time"
                         class="mt-6"
                         id="delivery_id_input"
                         :inputGroup="false">
            <BaseErrorMessage :validationState="v$" validatedProperty="time"/>
          </BaseTimeInput>
          <BaseInput v-model="formData.id"
                     class="mt-6"
                     label="ID поставки"
                     id="delivery_id_input"
                     placeholder="Введите ID"
                     :inputGroup="false">
            <BaseErrorMessage :validationState="v$" validatedProperty="id"/>
          </BaseInput>
          <div class="select-block input-form mt-6 relative">
            <label for="delivery_comparison_input"  class="form-label mb-1 w-full flex flex-col sm:flex-row">
              Сопоставить с
            </label>
            <select class="form-select w-full"
                    id="delivery_comparison_input"
                    v-model="formData.comparison">
              <option :value="undefined"
                      selected
                      disabled>Выбрать...</option>
              <option value="article">Артикул</option>
              <option value="barcode">Штрихкод</option>
            </select>
            <BaseErrorMessage :validationState="v$" validatedProperty="comparison"/>
          </div>
          <div class="relative">
            <Dropzone paramName="image"
                      ref-key="dropzoneExcel"
                      :options="{
                          url: 'https://apisf.nikit.me/file.php',
                          dictCancelUploadConfirmation: 'Вы уверены что хотите прервать загрузку файла?',
                          dictCancelUpload: 'Остановить',
                          dictRemoveFile: 'Удалить',
                          addRemoveLinks: true,
                          multipleUpload: true,
                          createImageThumbnails: false,
                          thumbnailWidth: 250,
                          thumbnailHeight: 250,
                          maxFilesize: 5,
                          maxFiles: 1,
                          acceptedFiles: '.xlsx,.xls,.ods',
                          init: watchDropzoneEvents
                        }" class="dropzone mt-6">
              <div class="text-lg font-medium">
                Перетащите сюда документ для загрузки.
              </div>
              <div class="text-gray-600">
                Максимальный размер одного файла 5МБ.
              </div>
            </Dropzone>
            <BaseErrorMessage :validationState="v$" validatedProperty="items"/>
          </div>
          <button  class="btn btn-primary shadow-md mt-6" :disabled="importing">
            Импортировать
            <LoadingIcon v-show="importing" icon="ball-triangle" color="white" class="ml-2 w-4 h-4" />
          </button>
        </form>
      </div>
    </div>
  </div>
  <!-- END: Super Large Slide Over Content -->
  <!-- BEGIN: Modal Content -->
  <SuccessModal id="import_success_modal" title="Выполнено"
                message="Импортирование успешно завершено"/>
  <!-- END: Modal Content -->
</template>

<script>
import { computed, defineComponent, provide, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { helpers, minLength, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import XLSX from 'xlsx'
import BaseInput from '@/components/base-input/Main'
import BaseErrorMessage from '@/components/base-error-message/Main'
import SuccessModal from '@/components/success-modal/Main'
import BaseTimeInput from '@/components/base-time-input/Main'

export default defineComponent({
  name: 'MovementsImport',
  components: { BaseTimeInput, SuccessModal, BaseErrorMessage, BaseInput },
  setup() {
    // отправка данных
    const store = useStore()
    const formData = reactive({})
    const importing = ref(false)

    const onSubmitData = async () => {
      const result = await v$.value.$validate()
      if (result) {
        const payload = {
          ...formData,
          date: formData.date + ' ' + formData.time
        }
        delete payload.time
        importing.value = true
        const response = await store.dispatch('movements/importDeliveriesToSDT', payload)
        importing.value = false
        if (response.data.success) {
          cash('#import-from-excel-modal').modal('hide')
          cash('#import_success_modal').modal('show')
        }
      }
    }

    // dropzone
    const dropzoneExcelRef = ref()
    const dropError = ref(false)
    provide('bind[dropzoneExcel]', el => {
      dropzoneExcelRef.value = el
    })

    const checkDocsFormat = (file) => {
      const validFormats = ['.xlsx', '.xls', '.ods']

      return validFormats.some(item => {
        return file.name.includes(item)
      })
    }

    const clearItems = () => {
      if (!dropzoneExcelRef.value.dropzone.getRejectedFiles()?.length) dropError.value = false
      if (!dropzoneExcelRef.value.dropzone.getAcceptedFiles()?.length) formData.items = []
    }

    const parseJson = async (file) => {
      if (!checkDocsFormat(file)) return []

      const docObject = await file.arrayBuffer()
      const workbook = XLSX.read(docObject, { type: 'buffer' })
      const jsonData = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        { header: ['NAME', 'SKU', 'ARTICLE', 'COUNT', 'PRICE', 'TOTAL_PRICE', 'MARK'] })
      jsonData.shift()
      formData.items = jsonData
    }

    const watchDropzoneEvents = function () {
      this.on('success', file => { parseJson(file) })
      this.on('removedfile', () => { clearItems() })
      this.on('error', () => { dropError.value = true })
    }

    // валидация
    const timeStringLength = 5
    const validationRules = computed(() => ({
      date: { required: helpers.withMessage('Выберите дату', required) },
      time: {
        required: helpers.withMessage('Введите время', required),
        minLength: helpers.withMessage('Необходимо ввести 4 цифры', minLength(timeStringLength))
      },
      comparison: { required: helpers.withMessage('Выберите сопоставление', required) },
      id: { required: helpers.withMessage('Поле не должно быть пустым', required) },
      items: {
        required: helpers.withMessage('Некорректный формат файла или файл не выбран', required),
        docsCount: helpers.withMessage('Можно выбрать только один документ', () => !dropError.value)
      }
    }))
    const v$ = useVuelidate(validationRules, formData)

    return {
      parseJson,
      formData,
      onSubmitData,
      watchDropzoneEvents,
      importing,
      v$
    }
  }
})
</script>

<style scoped>

</style>
