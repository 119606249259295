const state = () => {
  return {
    clients: null,
    clientsPagination: null,
    currentClient: null,
    pointsWriteOffData: null,
    pointsWritePagination: null,
    earningPointsData: null,
    earningPointsPagination: null,
    phoneStatus: false,
    phoneErrorMessage: false,
    verificationStatus: false
  }
}

// getters
const getters = {
  getClients: state => state.clients,
  getClientsPagination: state => state.clientsPagination,
  getCurrentClient: state => state.currentClient,
  getPointsWriteOffData: state => state.pointsWriteOffData,
  getPointsWriteOffPagination: state => state.pointsWritePagination,
  getEarningPointsData: state => state.earningPointsData,
  getEarningPointsPagination: state => state.earningPointsPagination,
  getPhoneStatus: state => state.phoneStatus,
  getPhoneErrorMessage: state => state.phoneErrorMessage,
  getVerificationStatus: state => state.verificationStatus
}

// actions
const actions = {
  async loadClients({ dispatch, commit }, payload) {
    await dispatch(
      'query/sendRequest',
      {
        url: 'clients/get',
        data: {},
        filter: payload?.filter,
        nav: payload?.nav,
        method: 'POST'
      },
      { root: true }
    ).then(res => {
      commit('setClients', res.data.result)
      commit('setClientsPagination', res.data.nav)
    })
  },
  async loadClientByID({ dispatch, commit }, payload) {
    await dispatch(
      'query/sendRequest',
      {
        url: 'clients/get-by-id/' + payload.id,
        data: {},
        method: 'POST'
      },
      { root: true }
    ).then(res => {
      commit('setCurrentClient', res.data)
    })
  },
  async updateClient({ dispatch, commit }, payload) {
    await dispatch(
      'query/sendRequest',
      {
        url: 'clients/update',
        data: { ...payload },
        method: 'POST'
      },
      { root: true }
    ).then(() => {
      dispatch('loadClientByID', { id: payload.ID })
    })
  },
  async loadPointsWriteOffInfo({ dispatch, commit }, payload) {
    await dispatch(
      'query/sendRequest',
      {
        url: 'clients/get-pay-points/',
        data: { clientId: payload.id },
        method: 'POST'
      },
      { root: true }
    ).then(res => {
      commit('setPointsWriteOffData', res.data)
    })
  },
  async loadEarningPointsInfo({ dispatch, commit }, payload) {
    await dispatch(
      'query/sendRequest',
      {
        url: 'clients/get-collect-points/',
        data: { clientId: payload.id },
        method: 'POST'
      },
      { root: true }
    ).then(res => {
      commit('setEarningPointsData', res.data)
    })
  },
  async collectPoints({ dispatch, commit }, payload) {
    await dispatch(
      'query/sendRequest',
      {
        url: 'collect-points/add',
        data: payload,
        method: 'POST'
      },
      { root: true }
    ).then(() => {
      dispatch('loadEarningPointsInfo', { id: payload.client })
    })
  },
  async writeOffPoint({ dispatch, commit }, payload) {
    await dispatch(
      'query/sendRequest',
      {
        url: 'pay-points/add',
        data: payload,
        method: 'POST'
      },
      { root: true }
    ).then(res => {
      commit('setPointsWriteOffData', res.data)
    })
  },
  async addClient({ dispatch, commit }, payload) {
    await dispatch(
      'query/sendRequest',
      {
        url: 'clients/add',
        data: payload,
        method: 'POST'
      },
      { root: true }
    ).then(res => {
      if (res.data.error) return

      dispatch('loadClients')
    })
  },
  async checkPhone({ dispatch, commit }, payload) {
    commit('setPhoneStatus', false)
    await dispatch(
      'query/sendRequest',
      {
        url: 'clients/check-phone',
        data: payload,
        method: 'POST'
      },
      { root: true }
    ).then(res => {
      if (res.data.status === 'success') commit('setPhoneStatus', true)
      if (res.data.status === 'error') {
        commit('setPhoneStatus', false)
        commit('setPhoneErrorMessage', res.data.comment)
      }
    })
  },
  async checkCode({ dispatch, commit }, payload) {
    commit('setVerificationStatus', false)
    await dispatch(
      'query/sendRequest',
      {
        url: 'clients/check-code',
        data: payload,
        method: 'POST'
      },
      { root: true }
    ).then(res => {
      if (res.data.success) commit('setVerificationStatus', res.data.success)
    })
  }
}

// mutations
const mutations = {
  setClients(state, data) {
    data = Object.values(data)
    state.clients = data
  },
  setClientsPagination(state, data) {
    state.clientsPagination = data
  },
  setCurrentClient(state, data) {
    state.currentClient = data
  },
  setPointsWriteOffData(state, data) {
    data = Object.values(data)
    state.pointsWriteOffData = data
  },
  setEarningPointsData(state, data) {
    state.earningPointsData = data
  },
  setPhoneStatus(state, data) {
    state.phoneStatus = data
  },
  setPhoneErrorMessage(state, data) {
    state.phoneErrorMessage = data
  },
  setVerificationStatus(state, data) {
    state.verificationStatus = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
