<template>
  <IntroPreloader v-if="tableLoading"/>
  <IdNotFound v-else-if="!tableLoading && tableData === false" :essenceName="t('essenceNameText')"/>
  <template v-else>
    <div class="flex justify-between items-center">
      <div v-if="$_.isEmpty(tableData)" class="text-md font-medium">{{ t('noDataText') }}</div>
      <a href="javascript:;"
         class="btn btn-primary shadow-md"
         data-toggle="modal" data-target="#superlarge-slide-over-size-preview">
        {{ t('addWriteOffBtnText') }}
      </a>
    </div>
    <template v-if="!$_.isEmpty(tableData)">
      <table ref="tableElem"
             class="table p-1 mt-4">
        <thead>
        <tr class="bg-gray-200 dark:bg-dark-1">
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('writeOffIdColText') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('orderIDColText') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('writtenOffBtnText') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('orderIDColText') }}

          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('operationDateColText') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in tableData"
            :key="item.ID"
            :class="{ 'bg-gray-200 dark:bg-dark-1': index % 2 === 1 }">
          <td class="border-b whitespace-nowrap">
            {{ item.ID }}
          </td>
          <td class="border-b whitespace-nowrap">
            <router-link :to="{ name: 'order-detail', params: { id: item.ORDER } }" v-if="item.ORDER">
              {{ item.ORDER }}
            </router-link>
            <div v-else>{{ t('manualWriteOffBtnText') }}</div>
          </td>
          <td class="border-b whitespace-nowrap">
            {{ item.POINTS }}
          </td>
          <td class="border-b whitespace-nowrap">
            {{ item.COMMENT }}
          </td>
          <td class="border-b whitespace-nowrap">
            {{ item.DATE }}
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </template>
  <AddPointsWriteOff :clientID="id"/>
</template>

<script>
import { defineComponent } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import AddPointsWriteOff from '@/components/client/add-points-write-off/Main'
import { useI18n } from 'vue-i18n'
import IdNotFound from '@/components/id-not-found/Main'

export default defineComponent({
  name: 'PointsWriteOffTable',
  components: { IdNotFound, AddPointsWriteOff },
  props: {
    id: String
  },
  setup(props) {
    // инициализация таблицы
    const table = useStoreDataGetting(
      'clients/getPointsWriteOffData',
      'clients/loadPointsWriteOffInfo',
      { id: props.id }
    )

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          essenceNameText: 'عميل',
          noDataText: 'أي بيانات',
          addWriteOffBtnText: 'إضافة الشطب',
          writeOffIdColText: 'شطب ID',
          orderIDColText: 'نظام ID',
          writtenOffBtnText: 'شطب',
          commentColText: 'تعليق',
          operationDateColText: 'تاريخ التشغيل',
          manualWriteOffBtnText: 'الشطب اليدوي'
        },
        en: {
          essenceNameText: 'Client',
          noDataText: 'No data',
          addWriteOffBtnText: 'Add write-off',
          writeOffIdColText: 'write-off ID',
          orderIDColText: 'Order ID',
          writtenOffBtnText: 'Written off',
          commentColText: 'Comment',
          operationDateColText: 'Date of operation',
          manualWriteOffBtnText: 'Manual write-off'
        },
        ru: {
          essenceNameText: 'Клиент',
          noDataText: 'Данные отсутствуют',
          addWriteOffBtnText: 'Добавить списание',
          writeOffIdColText: 'ID списания',
          orderIDColText: 'ID заказа',
          writtenOffBtnText: 'Списано',
          commentColText: 'Комментарий',
          operationDateColText: 'Дата операции',
          manualWriteOffBtnText: 'Ручное списание'
        }
      }
    })
    return {
      tableData: table.data,
      tableLoading: table.state,
      t
    }
  }
})
</script>

<style scoped>

</style>
