<template>
  <!-- BEGIN: Super Large Slide Over Content -->
  <div id="superlarge-slide-over-size-preview" class="modal modal-slide-over rtl:right-auto rtl:left-0" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header p-5">
          <h2 class="font-medium text-base mr-auto">
            {{ t('pageTitleText') }}
          </h2>
        </div>
        <div class="modal-body">
          <div class="input-form relative">
            <label for="write_off_points_sum"
                   class="form-label w-full flex flex-col sm:flex-row">
              {{ t('bonusQuantityLabelText') }}
            </label>
            <input v-model.number="formData.POINTS"
                   id="validation-form-4"
                   type="number"
                   class="form-control">
            <BaseErrorMessage :validationState="v$" validatedProperty="POINTS"/>
          </div>
          <div class="input-form mt-5 block relative">
            <label for="write_off_points_comment"
                   class="form-label w-full flex flex-col sm:flex-row">
              {{ t('commentLabelText') }}
            </label>
            <textarea v-model="formData.COMMENT"
                      id="write_off_points_comment"
                      class="form-control block"></textarea>
            <BaseErrorMessage :validationState="v$" validatedProperty="COMMENT"/>
          </div>
          <!-- BEGIN: Hide Slide Over Toggle -->
          <a href="javascript:;" class="btn btn-primary mt-8" @click="onWriteOffPoints()">
            {{ t('writeOffBtnText') }}
            <LoadingIcon v-if="sendingData"
                         icon="ball-triangle"
                         color="white"
                         class="'w-4 h-4 ml-1"/>
          </a>
          <!-- END: Hide Slide Over Toggle -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Super Large Slide Over Content -->

</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, minLength, minValue } from '@vuelidate/validators'
import BaseErrorMessage from '@/components/base-error-message/Main'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AddPointsWriteOff',
  components: { BaseErrorMessage },
  props: {
    clientID: String
  },
  setup(props) {
    const store = useStore()
    const formData = reactive({})

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitleText: 'الشطب اليدوي لنقاط العملاء',
          bonusQuantityLabelText: 'مبلغ نقطة المكافأة',
          commentLabelText: 'تعليق',
          requiredFieldText: 'حقل مطلوب',
          writeOffBtnText: 'شطب',
          minLengthErrText: 'طول التعليق لا يقل عن 10 أحرف',
          minValueErrText: 'يجب ألا تقل القيمة عن 1'
        },
        en: {
          pageTitleText: 'Manual write-off of customer points',
          bonusQuantityLabelText: 'Bonus point quantity',
          commentLabelText: 'Comment',
          requiredFieldText: 'Required field',
          writeOffBtnText: 'Write off',
          minLengthErrText: 'Comment length not less than 10 characters',
          minValueErrText: 'Value must not be less than 1'
        },
        ru: {
          pageTitleText: 'Ручное списание баллов клиента',
          bonusQuantityLabelText: 'Количество баллов',
          commentLabelText: 'Комментарий',
          requiredFieldText: 'Обязательное поле',
          writeOffBtnText: 'Списать',
          minLengthErrText: 'Длина комментария не меньше 10 символов',
          minValueErrText: 'Значение не должно быть меньше единицы'
        }
      }
    })

    // валидация данных
    const validationRules = {
      POINTS: {
        required: helpers.withMessage(t('requiredFieldText'), required),
        positiveValue: helpers.withMessage(t('minValueErrText'), minValue(1))
      },
      COMMENT: {
        required: helpers.withMessage(t('requiredFieldText'), required),
        minLength: helpers.withMessage(t('minLengthErrText'), minLength(10))
      }
    }
    const v$ = useVuelidate(validationRules, formData)

    // отправка данных
    const sendingData = ref(false)
    const onWriteOffPoints = async () => {
      const result = await v$.value.$validate()
      if (result) {
        sendingData.value = true
        formData.CLIENT = props.clientID
        store.dispatch('clients/writeOffPoint', formData)
          .finally(() => {
            sendingData.value = false
            cash('#superlarge-slide-over-size-preview').modal('hide')
          })
      }
    }

    return {
      formData,
      onWriteOffPoints,
      sendingData,
      t,
      v$
    }
  }
})
</script>

<style scoped>

</style>
