<template>
  <div class="relative">
    <input v-model="currentCount"
           class="h-6 p-2 rounded-md border"
           type="number"
           placeholder="Введите количество">
    <BaseErrorMessage :validationState="v$"/>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { helpers, minValue, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import BaseErrorMessage from '@/components/base-error-message/Main'

export default defineComponent({
  name: 'MovementProductsCount',
  components: { BaseErrorMessage },
  props: {
    modelValue: [String, Number],
    balance: [String, Number]
  },
  setup(props, { emit }) {
    const currentCount = computed({
      get: () => props.modelValue,
      set: (val) => emit('update:modelValue', val)
    })

    const validationRules = {
      required: helpers.withMessage('Введите количество', required),
      minValue: helpers.withMessage('Минимальное количество 1', minValue(1)),
      incorrectValue: helpers.withMessage('Количество перемещаемых товаров превышает остаток', () => {
        return !(+props.balance < currentCount.value)
      })
    }

    const v$ = useVuelidate(validationRules, currentCount)

    return {
      currentCount,
      v$
    }
  }
})
</script>

<style scoped>

</style>
