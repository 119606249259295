<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ id ? 'Редактирование' : 'Добавление' }} ТК</h2>
    </div>
    <div class="grid grid-cols-12 gap-12 mt-12">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div>
            <label for="crud-form-1" class="form-label">Название</label>
            <input
              id="crud-form-1"
              v-model="formData.NAME"
              type="text"
              class="form-control w-full"
              placeholder="Введите название"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-2" class="form-label">Тип</label>
            <TomSelect id="crud-form-2" v-model="formData.TYPE" class="w-full">
              <option value="DPD">DPD</option>
              <option value="PEC">ПЭК</option>
              <option value="Shiptor">Шиптор</option>
              <option value="RuPost">Почта россии</option>
              <option value="Dostavista">Достависта</option>
              <option value="YandexDelivery">Яндекс Доставка</option>
              <option value="DeliveryGuru">Доставка Гуру</option>
              <option value="Takengo">Take'n'go</option>
              <option value="EBulky">Easy Bulky</option>
              <option value="KIT">KIT</option>
            </TomSelect>
          </div>
          <div class="mt-3">
            <label class="form-label">Доступы для работы API</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group">
                <div id="input-group-3" class="input-group-text">Токен</div>
                <input
                  v-model="formData.ACCESS.token"
                  type="text"
                  class="form-control"
                  placeholder="Токен"
                  aria-describedby="input-group-3"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0">
                <div id="input-group-4_3" class="input-group-text">Endpoint</div>
                <input
                  v-model="formData.ACCESS.endpoint"
                  type="text"
                  class="form-control"
                  placeholder="Адрес API"
                  aria-describedby="input-group-4"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0" v-show="formData.TYPE === 'RuPost'">
                <div id="input-group-4_3" class="input-group-text">Key</div>
                <input
                  v-model="formData.ACCESS.key"
                  type="text"
                  class="form-control"
                  placeholder="Ключ авторизации"
                  aria-describedby="input-group-4"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0" v-show="formData.TYPE === 'DeliveryGuru' || formData.TYPE === 'DPD'">
                <div id="input-group-4_4" class="input-group-text">ID&nbsp;магазина</div>
                <input
                  v-model="formData.ACCESS.partner_id"
                  type="text"
                  class="form-control"
                  placeholder="ID магазина"
                  aria-describedby="input-group-4"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0" v-show="formData.TYPE === 'PEC'">
                <div id="input-group-4_5" class="input-group-text">Логин&nbsp;ЛК</div>
                <input
                  v-model="formData.ACCESS.login"
                  type="text"
                  class="form-control"
                  placeholder="Логин ЛК"
                  aria-describedby="input-group-4_5"
                />
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label>Активен</label>
            <div class="mt-2">
              <input type="checkbox" v-model="active" class="form-check-switch" />
            </div>
          </div>
          <div class="text-right mt-5">
            <router-link :to="{ name: 'logistics-companies' }" tag="button" class="btn btn-outline-secondary w-24 mr-1">
              Отмена
            </router-link>
            <button type="button" class="btn btn-primary max-w-max" @click="saveLogisticCompany(id, '/logistics-companies')">
              <LoadingIcon icon="ball-triangle" v-if="sendingData" class="'w-4 h-4 ml-1"/>
              Сохранить
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import { useDataSending } from '@/use/useDataSending'

export default defineComponent({
  name: 'LogisticCompaniesForm',
  props: {
    id: String
  },
  setup(props) {
    // загрузка текущей интеграции
    let currentLogisticCompany
    if (props.id) {
      currentLogisticCompany = useStoreDataGetting(
        'logistics/getCurrentLogisticsCompany',
        'logistics/loadLogisticCompanyByID',
        { id: props.id || '' })
    }

    // сохранение данных интеграции
    const { active, formData, sendData, sendingData } = useDataSending(
      'logistics/updateLogisticCompany',
      'logistics/addLogisticCompany',
      currentLogisticCompany?.data)
    formData.ACCESS = {}

    return {
      active,
      formData,
      saveLogisticCompany: sendData,
      sendingData
    }
  }
})
</script>
