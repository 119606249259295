<template>
  <div
    id="collect_earning_point_modal"
    class="modal modal-slide-over rtl:right-auto rtl:left-0"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header p-5">
          <h2 class="font-medium text-base mr-auto">
            Начисление баллов
          </h2>
        </div>
        <div class="modal-body">
          <div class="input-form relative">
            <label
              for="write_off_points_sum"
              class="form-label w-full flex flex-col sm:flex-row"
            >
              Сумма баллов
            </label>
            <input
              v-model.number="formData.sum"
              id="validation-form-4"
              type="number"
              class="form-control"
              placeholder="Введите число"
            />
            <BaseErrorMessage :validationState="v$" />
          </div>
          <div class="input-form mt-5 block relative">
            <label
              for="write_off_points_comment"
              class="form-label w-full flex flex-col sm:flex-row"
            >
              Дата сгорания
            </label>

            <Litepicker
              v-model="formData.date_burn"
              :options="{
                autoApply: true,
                format: 'DD.MM.YYYY',
                lang: 'ru',
                setInitValue: false,
                minDate: new Date(Date.now() + 48 * 60 * 60 * 1000).getTime(),
                dropdowns: {
                  minYear: 1900,
                  months: true,
                  years: true
                }
              }"
              class="form-control"
            />
          </div>
          <button class="btn btn-primary mt-8" @click="collectPoints">
            Начислить
            <LoadingIcon
              v-if="sendingData"
              icon="ball-triangle"
              color="white"
              class="'w-4 h-4 ml-1"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, minValue } from '@vuelidate/validators'
import BaseErrorMessage from '@/components/base-error-message/Main'
import Litepicker from '@/global-components/litepicker/Main.vue'

export default defineComponent({
  name: 'CollectEarningPoints',
  components: { Litepicker, BaseErrorMessage },
  props: {
    clientID: String
  },
  setup(props) {
    const store = useStore()
    const formData = reactive({
      client: props.clientID,
      date_burn: '',
      sum: ''
    })

    // валидация данных
    const validationRules = {
      sum: {
        required: helpers.withMessage('Обязательное поле', required),
        positiveValue: helpers.withMessage(
          'Количество не может быть отрицательным',
          minValue(1)
        )
      }
    }
    const v$ = useVuelidate(validationRules, formData)

    // отправка данных
    const sendingData = ref(false)
    const collectPoints = async () => {
      const result = await v$.value.$validate()
      if (result) {
        sendingData.value = true
        formData.CLIENT = props.clientID
        store.dispatch('clients/collectPoints', formData).finally(() => {
          sendingData.value = false
          cash('#collect_earning_point_modal').modal('hide')
        })
      }
    }

    return {
      formData,
      collectPoints,
      sendingData,
      v$
    }
  }
})
</script>

<style scoped></style>
