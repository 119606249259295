<template>
  <!-- BEGIN: Language Switcher-->
  <div :class="['shadow-md box dark:bg-dark-2 border rounded-full flex items-center justify-center px-1 sm:px-3 relative', height]">
    <GlobeIcon class="mr-1"/>
    <select v-model="currentLang"
            class="w-min border-0 rounded-xl cursor-pointer outline-none">
      <option value="ar">AR</option>
      <option value="en">EN</option>
      <option value="ru">RU</option>
    </select>
  </div>
  <!-- END: Language Switcher-->
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'LanguageSwitcher',
  props: {
    height: {
      type: String,
      default: 'h-9'
    }
  },
  setup() {
    const store = useStore()
    const i18n = useI18n()

    const setTextDirection = (val) => {
      if (val === 'ar') {
        cash('html').attr('dir', 'rtl')
      } else {
        cash('html').attr('dir', 'ltr')
      }
    }

    const currentLang = computed({
      get: () => store.getters['main/getLanguage'],
      set: value => {
        store.dispatch('main/setLanguage', value)
        i18n.locale.value = value

        setTextDirection(value)
      }
    })

    onMounted(() => {
      setTextDirection(currentLang.value)
    })

    return {
      currentLang
    }
  }
})
</script>
