<template>
  <td class="text-left border-b dark:border-dark-5 w-32 font-medium"></td>
  <td class="text-right border-b dark:border-dark-5 w-32 font-medium">
    <div v-if="item?.return_data !== undefined && typeof item?.return_data !== 'string'">
      <template v-if="!$_.isEmpty(item?.return_data?.validate)">
        <template v-for="(validateItem, type) in item.return_data.validate" :key="type + 'KIT-validate-item'"> <!-- places -->
          <template v-for="(type, index) in validateItem" :key="index + 'KIT-type-item'"> <!-- placeItems [0, 1, 2] -->
            <template v-for="(prop, propName) in type" :key="propName + 'KIT-prop-item'"> <!-- itemProp (weight) -->
              <div class="text-gray-600" v-for="(propValue, valueIndex) in prop" :key="valueIndex + 'KIT-value-item'"> <!-- itemProp (weight) -->
                 {{ propValue }}
              </div>
            </template>
          </template>
        </template>
      </template>
      <div v-else
           class="flex justify-end"
           v-for="(term, termIndex) in item?.return_data[0]"
           :key="termIndex + 'KIT'" >
        <div class="whitespace-nowrap mr-2">
          {{ term.name }}
        </div>|
        <div class="text-gray-600 whitespace-nowrap mr-2 ml-2">
          Дней: {{term.time}}
        </div>|
        <div class="ml-0.5 w-20">
          {{ Math.round(term.cost) }}&nbsp;руб.
        </div>
      </div>
    </div>
    <CalculationDataError v-else :error-message="item?.error"/>
  </td>
</template>

<script>
import { defineComponent } from 'vue'
import CalculationDataError
from '@/components/logistics-price-calculation-form/calculation-result-items/calculation-data-error/Main'

export default defineComponent({
  name: 'KitCalculation',
  components: { CalculationDataError },
  props: {
    item: Object
  }
})
</script>

<style scoped>

</style>
