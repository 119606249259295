<template>
  <!-- BEGIN: Super Large Slide Over Content -->
  <div :id="`compare_${comparedProperty}_modal`" class="modal modal-slide-over" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header p-5">
          <h2 class="font-medium text-base mr-auto">
            Сопоставить свойства
          </h2>
        </div>
        <IntroPreloader v-if="loading"/>
        <div v-if="!loading"
             class="modal-body">
          <BaseSelect v-for="(field, index) in comparedFields"
                      :key="comparedProperty + field"
                      :id="`${field}_select`"
                      v-model="currentSettings[field]"
                      :inputGroup="false"
                      :items="integrationsFields"
                      :class="{ 'mt-0': index === 0, 'mt-6': index !== 0 }"
                      :label="field">
            <BaseErrorMessage  :validationState="v$" :validatedProperty="field"/>
          </BaseSelect>
          <button  class="btn btn-primary shadow-md mt-6"
                   @click="onSetCompare">
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Super Large Slide Over Content -->
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import BaseSelect from '@/components/base-select/Main'
import BaseErrorMessage from '@/components/base-error-message/Main'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'CompareProductsFields',
  components: { BaseErrorMessage, BaseSelect },
  props: {
    modelValue: Object,
    comparedProperty: String,
    comparedFields: Array,
    id: [String, Number]
  },
  setup(props, { emit }) {
    const store = useStore()
    const currentSettings = reactive({})
    const integrationsFields = computed(() => store.getters['products/getIntegrationFields'])
    const loading = ref(false)

    const onSetCompare = async () => {
      const result = await v$.value.$validate()
      if (result) {
        emit('update:modelValue', currentSettings)
        cash(`#compare_${props.comparedProperty}_modal`).modal('hide')
      }
    }

    watch(() => props.id, () => {
      loading.value = true
      store.dispatch('products/loadIntegrationFields', { id: props.id })
        .finally(() => {
          props.comparedFields.forEach(field => {
            currentSettings[field] = props?.modelValue[field]
          })
          loading.value = false
        })
    }, { immediate: true })

    // валидация
    const validationRules = computed(() => {
      const rulesObject = {}
      props.comparedFields.forEach(field => {
        rulesObject[field] = { required: helpers.withMessage('Обязательный параметр', required) }
      })
      return rulesObject
    })
    const v$ = useVuelidate(validationRules, currentSettings, { $stopPropagation: true })

    return {
      integrationsFields,
      loading,
      currentSettings,
      onSetCompare,
      v$
    }
  }
})
</script>

<style scoped>

</style>
