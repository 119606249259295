<template>
  <td class="text-left border-b dark:border-dark-5 w-32 font-medium"></td>
  <td class="text-right border-b dark:border-dark-5 w-32 font-medium">
    <template v-if="$_.isEmpty(item?.error)">
      <template v-for="(term, termIndex) in item" :key="termIndex + 'RuPost'" >
        <div v-if="$_.isEmpty(term?.error) && !$_.isEmpty(term.delivery)"
             class="flex justify-end">
          <div class="whitespace-nowrap mr-2">
            {{ term?.name }}
          </div>|
          <template v-if="term?.delivery?.min !== term?.delivery?.max" >
            <div class="text-gray-600 whitespace-nowrap mr-2 ml-2">
              Дней: от {{ term?.delivery?.min }} до {{ term?.delivery?.max }}
            </div>
            <span>|</span>
          </template>
          <template v-else>
            <div class="text-gray-600 whitespace-nowrap mr-2 ml-2">
              Дней: {{ term?.delivery?.min }}
            </div>
            <span>|</span>
          </template>
          <div class="ml-0.5 w-20">
            {{ Math.round(term?.sum) }}&nbsp;руб.
          </div>
        </div>
        <div v-else class="flex">
          <template v-for="(error, errIndex) in term?.error"
                    :key="errIndex + 'RuPostError'">
            <CalculationDataError :errorMessage="error?.msg"
                                  class="mr-2 ml-2"/>
          </template>
        </div>
      </template>
    </template>
    <CalculationDataError v-else :errorMessage="item?.error"/>
  </td>
</template>

<script>
import { defineComponent } from 'vue'
import CalculationDataError
from '@/components/logistics-price-calculation-form/calculation-result-items/calculation-data-error/Main'

export default defineComponent({
  name: 'RuPostCalculation',
  components: { CalculationDataError },
  props: {
    item: Object
  }
})
</script>

<style scoped>

</style>
