import { notifications as $notifications } from '@/utils/showNotification'

const state = {
  status: '',
  profile: localStorage.getItem('profile')
    ? JSON.parse(localStorage.getItem('profile'))
    : {},
  widgetToken: '',
  hooksToken: ''
}

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.name,
  getWidgetToken: state => state.widgetToken,
  getHooksToken: state => state.hooksToken
}

const actions = {
  userRequest: ({ commit }, data) => {
    commit('userRequest')
    commit('userSuccess', {
      id: data.ID,
      name: data.NAME,
      phone: data.PHONE,
      login: data.LOGIN,
      photo: data?.AVATAR
    })
  },
  async loadWidgetToken({ dispatch, commit }) {
    await dispatch('query/sendRequest', {
      url: 'companies/get-widget-token',
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setWidgetToken', res.data)
      })
  },
  async loadHooksToken({ dispatch, commit }) {
    await dispatch('query/sendRequest', {
      url: 'companies/get-hooks-token',
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setHooksToken', res.data)
      })
  },
  async updateAvatar({ dispatch, commit }, payload) {
    return await dispatch('query/sendRequest', {
      url: 'companies/update',
      method: 'POST',
      data: payload
    }, { root: true })
      .then(res => {
        if (res.data.response) {
          commit('avatarUpdatingSuccess', {
            photo: payload?.AVATAR
          })
          $notifications.showSuccessMessage('Фото профиля обновлено')
        } else {
          $notifications.showError('Проищошла ошибка обновления фото профиля')
        }
      })
  },
  async updateProfile({ dispatch, commit }, payload) {
    return await dispatch('query/sendRequest', {
      url: 'companies/update',
      method: 'POST',
      data: payload
    }, { root: true })
      .then(res => {
        if (res.data.response) {
          commit('userSuccess', {
            id: payload?.ID,
            name: payload?.NAME,
            phone: payload?.PHONE,
            login: payload?.LOGIN,
            photo: payload?.AVATAR
          })

          $notifications.showSuccessMessage('Данные успешно обновлены')
        } else {
          $notifications.showError('Проищошла ошибка обновления данных')
        }
      })
  }
}

const mutations = {
  userRequest: state => {
    state.status = 'loading'
  },
  userSuccess: (state, data) => {
    state.status = 'success'
    localStorage.setItem('profile', JSON.stringify(data))
    state.profile = data
  },
  avatarUpdatingSuccess: (state, data) => {
    const profile = state.profile
    profile.photo = data.photo
    localStorage.setItem('profile', JSON.stringify(profile))
    state.profile = profile
  },
  authLogout: state => {
    state.profile = {}
    localStorage.setItem('profile', '')
  },
  setWidgetToken: (state, data) => {
    state.widgetToken = data
  },
  setHooksToken: (state, data) => {
    state.hooksToken = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
