const state = () => {
  return {
    stages: null,
    currentStage: null
  }
}

// getters
const getters = {
  getStages: state => state.stages,
  getCurrentStage: state => state.currentStage
}

// actions
const actions = {
  async loadStages({ dispatch, commit }) {
    await dispatch('query/sendRequest', {
      url: 'stages/get',
      data: {},
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setStages', res.data.result)
      })
  },
  async loadStageById({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'stages/get-by-id/' + payload.id,
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setCurrentStage', res.data)
      })
  },
  async addStage({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'stages/add',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async updateStage({ dispatch, commit }, payload) {
    const data = { ...payload }
    data.INTEGRATION = payload.INTEGRATION.ID
    await dispatch('query/sendRequest', {
      url: 'stages/update',
      data,
      method: 'POST'
    }, { root: true })
  },
  async deleteStage({ dispatch, commit }, data) {
    await dispatch('query/sendRequest', {
      url: 'stages/delete',
      data: data.items,
      method: 'POST'
    }, { root: true })
      .then(() => {
        commit('deleteStageItem', data.items[0])
      })
  }
}

// mutations
const mutations = {
  setStages(state, data) {
    const stagesList = Object.values(data)
    stagesList.forEach(item => {
      if (item.NUMBER.includes('_')) item.NUMBER = item.NUMBER.replace('_', '.')
    })
    state.stages = stagesList.sort((a, b) => {
      return Number(a.NUMBER) - Number(b.NUMBER)
    })
  },
  deleteStageItem(state, id) {
    state.stages = state.stages.filter(item => item.ID !== id)
  },
  setCurrentStage(state, data) {
    state.currentStage = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
