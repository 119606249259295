<template>
  <td class="text-left border-b dark:border-dark-5 w-32 font-medium"/>
  <td v-if="!item?.error" class="text-right font-medium border-b dark:border-dark-5 w-32">
    <template v-if="item.result?.error === undefined">
      <div v-for="(itemDPD, indexDPD) in item.result" :key="indexDPD" class="flex justify-end">
        <div class="whitespace-nowrap mr-2">
          {{ itemDPD.SERVICE_NAME }}
        </div>|
        <div class="text-gray-600 text-sm whitespace-nowrap ml-2 mr-2">
          Дней: {{itemDPD.DAYS}}
        </div>|
        <div class="ml-0.5 w-20">
          {{ Math.round(parseInt(itemDPD.COST)) }}&nbsp;руб.
        </div>
      </div>
    </template>
    <CalculationDataError v-else-if="item.result?.error" :errorMessage="item.result?.error"/>
  </td>
  <td v-else class="font-medium border-b dark:border-dark-5 w-32">
    <CalculationDataError :errorMessage="item?.error"/>
  </td>
</template>

<script>
import { defineComponent } from 'vue'
import CalculationDataError
from '@/components/logistics-price-calculation-form/calculation-result-items/calculation-data-error/Main'

export default defineComponent({
  name: 'DpdCalculation',
  components: { CalculationDataError },
  props: {
    item: Object
  }
})
</script>

<style scoped>

</style>
