<template>
  <div class="input-group mt-2 sm:mt-0 relative">
    <label class="input-group-text max-w-max hidden sm:block" :for="id">{{ t('label') }}</label>
    <select class="form-select w-20 sm:border-l-0 sm:rounded-l-none dark:disabled:bg-dark-2"
            :id="id"
            :value="$route.params.pageSize"
            @change="setItemsPerPage($event)">
      <option>10</option>
      <option>25</option>
      <option>50</option>
      <option>100</option>
      <option>500</option>
    </select>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'SelectItemsPerPage',
  props: {
    id: {
      type: String,
      default: 'items_count_select'
    }
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const setItemsPerPage = (e) => {
      e.target.blur()
      router.push({
        params: {
          pageSize: e.target.value
        },
        query: route.query
      })
    }

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          label: 'صفوف في الطاولة'
        },
        en: {
          label: 'Rows in the table'
        },
        ru: {
          label: 'Строк в таблице'
        }
      }
    })

    return {
      setItemsPerPage,
      t
    }
  }
})
</script>

<style scoped>

</style>
