const state = () => {
  return {
    loyaltyDates: null,
    currentLoyaltyDate: null
  }
}

// getters
const getters = {
  getLoyaltyDates: state => state.loyaltyDates,
  getCurrentLoyaltyDate: state => state.currentLoyaltyDate
}

// actions
const actions = {
  async loadLoyaltyDates({ dispatch, commit }) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-dates/get',
      data: {},
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setLoyaltyDates', res.data.result)
      })
  },
  async loadLoyaltyDateById({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-dates/get-by-id/' + payload.id,
      data: {},
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setCurrentLoyaltyDate', res.data)
      })
  },
  async addLoyaltyDate({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-dates/add',
      data: payload,
      method: 'POST'
    }, { root: true })
      .then(async() => {
        // await dispatch('loadLoyaltyDates', { newRequest: true })
      })
  },
  async updateLoyaltyDate({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-dates/update',
      data: payload,
      method: 'POST'
    }, { root: true })
      .then(async() => {
        // await dispatch('loadLoyaltyDates', { newRequest: true })
      })
  },
  async deleteLoyaltyDate({ dispatch, commit }, data) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-dates/delete',
      data: data.items,
      method: 'POST'
    }, { root: true })
      .then(async() => {
        commit('deleteLoyaltyDate', data.items[0])
      })
  }
}

// mutations
const mutations = {
  setLoyaltyDates(state, data) {
    state.loyaltyDates = Object.values(data)
  },
  setCurrentLoyaltyDate(state, data) {
    state.currentLoyaltyDate = data
  },
  deleteLoyaltyDate(state, id) {
    state.loyaltyDates = state.loyaltyDates.filter(item => item.ID !== id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
