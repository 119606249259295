import * as types from './mutation-types'

const state = () => {
  return {
    darkMode: localStorage.getItem('darkMode') === 'true',
    language: localStorage.getItem('language') || 'ru'
  }
}

// getters
const getters = {
  darkMode: state => state.darkMode,
  getLanguage: state => state.language
}

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode })
    localStorage.setItem('darkMode', darkMode)
  },
  setLanguage({ commit }, language) {
    commit(types.SET_LANGUAGE, { language })
    localStorage.setItem('language', language)
  }
}

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode
  },
  [types.SET_LANGUAGE](state, { language }) {
    state.language = language
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
