<template>
  <div class="p-4">
    <div class="2xl:w-1/2">
      <div>
        <label for="loyalty-settings-form-1" class="form-label">
          {{ t('earningDaysLabel') }}
        </label>
        <input
          id="loyalty-settings-form-1"
          type="number"
          class="form-control"
          :placeholder="t('daysCountInput')"
          v-model="loyaltySettings.DAYS"
        />
      </div>
      <div class="mt-3">
        <label for="loyalty-settings-form-2" class="form-label">
          {{ t('paymentUpTo') }}
        </label>
        <input
          id="loyalty-settings-form-2"
          type="number"
          class="form-control"
          :placeholder="t('paymentUpTo')"
          v-model="loyaltySettings.CAN_PAY"
        />
      </div>
      <div class="mt-3">
        <label for="loyalty-settings-form-3" class="form-label">
          {{ t('bonusActivationDate') }}
        </label>
        <Litepicker v-model="loyaltySettings.DATE_START"
                    :options=" {
                          autoApply: true,
                          format: 'DD.MM.YYYY',
                          lang: 'ru',
                          singleMode: true,
                          setInitValue: false,
                          dropdowns: {
                            minYear: 2018,
                            months: true,
                            years: true
                          }
                        }"
                    id="loyalty-settings-form-3"
                    class="form-control w-full"/>
      </div>
      <div class="mt-3">
        <label for="loyalty-settings-form-4" class="form-label">
          {{ t('daysBeforeWriteOffBonus') }}
        </label>
        <input
          id="loyalty-settings-form-4"
          type="number"
          class="form-control"
          :placeholder="t('daysCountInput')"
          v-model="loyaltySettings.DAY_LOST"
        />
      </div>
    </div>

    <div class="2xl:w-1/2 mt-4">
      <h3 class="text-lg font-medium border-b pb-1">{{ t('birthdayBonus') }}</h3>
      <div class="mt-2">
        <label for="loyalty-birtday-form-1" class="form-label">
          {{ t('bonusQuantity') }}
        </label>
        <input
          id="loyalty-birtday-form-1"
          type="number"
          class="form-control"
          :placeholder="t('bonusQuantity')"
          v-model="loyaltySettings.DOB_GIFT"
        />
      </div>
      <div class="mt-3">
        <label for="loyalty-birtday-form-2" class="form-label">
          {{ t('dayBeforeBirthday') }}
        </label>
        <input
          id="loyalty-birtday-form-2"
          type="number"
          class="form-control"
          :placeholder="t('daysCountInput')"
          v-model="loyaltySettings.DOB_BEFORE_DAYS"
        />
      </div>
      <div class="mt-3">
        <label for="loyalty-birtday-form-4" class="form-label">
          {{ t('dayAfterBirthday') }}
        </label>
        <input
          id="loyalty-birtday-form-4"
          type="number"
          class="form-control"
          :placeholder="t('daysCountInput')"
          v-model="loyaltySettings.DOB_AFTER_DAYS"
        />
      </div>
    </div>

    <button class="btn btn-primary mr-auto mt-4" type="button" @click="saveSettings(loyaltySettings?.ID)">
      <CheckIcon class="w-4 h-4 mr-1"/>
      {{ t('saveBtn') }}
    </button>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import { useDataSending } from '@/use/useDataSending'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'LoyaltySettings',
  setup() {
    // загрузка текущих настроек лояльности
    const loyaltySettingsData = useStoreDataGetting(
      'loyaltySettings/getLoyaltySettings',
      'loyaltySettings/loadLoyaltySettings')
    const currentSettings = computed(() => {
      return (loyaltySettingsData.data.value === null) ? {} : loyaltySettingsData.data.value[0]
    })

    // сохранение настроек
    const { formData, sendData } = useDataSending(
      'loyaltySettings/updateLoyaltySettings',
      'loyaltySettings/addLoyaltySettings',
      currentSettings)

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          earningDaysLabel: 'أيام قبل التسجيل',
          paymentUpTo: 'الدفع حتى في المائة',
          bonusActivationDate: 'تاريخ التفعيل',
          daysBeforeWriteOffBonus: 'قبل أيام من نقاط الاحتراق',
          birthdayBonus: 'مكافأة عيد ميلاد',
          bonusQuantity: 'كمية المكافأة',
          dayBeforeBirthday: 'رسوم (عدد) أيام قبل عيد الميلاد',
          dayAfterBirthday: 'شطب (عدد) أيام بعد عيد الميلاد',
          daysCountInput: 'كمية أيام الإدخال',
          saveBtn: 'نقذ'
        },
        en: {
          earningDaysLabel: 'Days before enrollment',
          paymentUpTo: 'Payment up to %',
          bonusActivationDate: 'The date of activation',
          daysBeforeWriteOffBonus: 'Days before the combustion points',
          birthdayBonus: 'Birthday Bonus',
          bonusQuantity: 'Bonus quantity',
          dayBeforeBirthday: 'Charge (number) days before birthday',
          dayAfterBirthday: 'Write off (number) days after birthday',
          daysCountInput: 'Input days quantity',
          saveBtn: 'Save'
        },
        ru: {
          earningDaysLabel: 'Дней до зачисления',
          paymentUpTo: 'Оплата до %',
          bonusActivationDate: 'Дата начала работы',
          daysBeforeWriteOffBonus: 'Дней до сгорания баллов',
          birthdayBonus: 'Баллы на день рождения',
          bonusQuantity: 'Количество баллов',
          dayBeforeBirthday: 'Начислять за (число) дней до дня рождения',
          dayAfterBirthday: 'Списать через (число) дней после дня рождения',
          daysCountInput: 'Введите количество дней',
          saveBtn: 'Сохранить'
        }
      }
    })

    return {
      loading: loyaltySettingsData.loading,
      loyaltySettings: formData,
      saveSettings: sendData,
      t
    }
  }
})
</script>

<style scoped>

</style>
