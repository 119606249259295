<template>
  <div class="flex justify-end text-gray-600">
    <div v-if="errorMessage"> {{ errorMessage }} </div>
    <div v-else> Не удалось получить стоимость доставки </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CalculationDataError',
  props: {
    errorMessage: String
  }
})
</script>

<style scoped>

</style>
