<template>
  <div class="grid grid-cols-2 gap-4">
    <BaseSelect id="size_integration_select"
                class="mt-0"
                :label="t('productComparison')"
                v-model="formData.baseField"
                :inputGroup="false"
                :items="[{ value: 'article', name: t('article') },
                           { value: 'sku', name: t('barcode') }]"
                itemName="name"
                itemValue="value">
      <BaseErrorMessage  :validationState="v$" validatedProperty="baseField"/>
    </BaseSelect>
    <div class="flex justify-between">
      <BaseSelect id="base_integration_select"
                  :class="['mt-0 w-full', { 'mr-4': formData.baseIntegration?.type === 'IMSite' }]"
                  :label="t('productBaselineData')"
                  v-model="formData.baseIntegration.id"
                  :inputGroup="false"
                  :items="integrations"
                  itemValue="ID"
                  itemName="NAME">
        <BaseErrorMessage  :validationState="v$" validatedProperty="baseIntegration"/>
      </BaseSelect>
      <a v-if="formData.baseIntegration?.type === 'IMSite'"
         class="btn btn-primary shadow-md mt-6 whitespace-nowrap"
         href="javascript:;"
         data-toggle="modal"
         data-target="#compare_baseIntegration_modal"
         @click="showComponents.baseIntegration = true">
        {{ t('compareProps') }}
      </a>
    </div>
    <div class="flex justify-between">
      <BaseSelect id="price_integration_select"
                  :class="['mt-0 w-full', { 'mr-4': formData.priceIntegration?.type === 'IMSite' }]"
                  :label="t('pricesSrc')"
                  v-model="formData.priceIntegration.id"
                  :inputGroup="false"
                  :items="integrations"
                  itemValue="ID"
                  itemName="NAME">
        <BaseErrorMessage  :validationState="v$" validatedProperty="priceIntegration"/>
      </BaseSelect>
      <a v-if="formData.priceIntegration?.type === 'IMSite'"
         class="btn btn-primary shadow-md mt-6 whitespace-nowrap"
         href="javascript:;"
         data-toggle="modal"
         data-target="#compare_priceIntegration_modal"
         @click="showComponents.priceIntegration = true">
        {{ t('compareProps') }}
      </a>
    </div>
    <div class="flex justify-between">
      <BaseSelect id="size_integration_select"
                  :class="['mt-0 w-full', { 'mr-4': formData?.sizeIntegration?.type === 'IMSite' || formData?.sizeIntegration?.type === 'RetailCRM' }]"
                  :label="t('productSizeDataSource')"
                  v-model="formData.sizeIntegration.id"
                  :inputGroup="false"
                  :items="integrations"
                  itemValue="ID"
                  itemName="NAME">
        <BaseErrorMessage  :validationState="v$" validatedProperty="sizeIntegration"/>
      </BaseSelect>
      <a v-if="formData?.sizeIntegration?.type === 'IMSite' || formData?.sizeIntegration?.type === 'RetailCRM'"
         class="btn btn-primary shadow-md mt-6 whitespace-nowrap"
         href="javascript:;"
         data-toggle="modal"
         data-target="#compare_sizeIntegration_modal"
         @click="showComponents.sizeIntegration = true">
        {{ t('compareProps') }}
      </a>
    </div>
    <div class="flex justify-between">
      <BaseSelect id="pic_integration_select"
                  :class="['mt-0 w-full', { 'mr-4': formData.picIntegration?.type === 'IMSite' }]"
                  :label="t('imgsSrcs')"
                  v-model="formData.picIntegration.id"
                  :inputGroup="false"
                  :items="integrations"
                  itemValue="ID"
                  itemName="NAME">
        <BaseErrorMessage  :validationState="v$" validatedProperty="picIntegration"/>
      </BaseSelect>
      <a v-if="formData.picIntegration?.type === 'IMSite'"
         class="btn btn-primary shadow-md mt-6 whitespace-nowrap"
         href="javascript:;"
         data-toggle="modal"
         data-target="#compare_picIntegration_modal"
         @click="showComponents.picIntegration = true">
        {{ t('compareProps') }}
      </a>
    </div>
  </div>
  <button @click="onSubmit"
          class="btn btn-primary shadow-md mt-6 whitespace-nowrap">
    {{ t('saveChanges') }}
  </button>
  <CompareProductsFields v-if="showComponents.baseIntegration"
                         v-model="formData.baseIntegration.compareFields"
                         comparedProperty="baseIntegration"
                         :comparedFields="['name', 'article', 'barcode', 'groupId', 'externalId']"
                         :id="formData.baseIntegration?.id"/>
  <CompareProductsFields v-if="showComponents.priceIntegration"
                         v-model="formData.priceIntegration.compareFields"
                         comparedProperty="priceIntegration"
                         :comparedFields="['basePrice', 'retailPrice']"
                         :id="formData.priceIntegration?.id"/>
  <CompareProductsFields v-if="showComponents.sizeIntegration"
                         v-model="formData.sizeIntegration.compareFields"
                         comparedProperty="sizeIntegration"
                         :comparedFields="['length', 'width', 'height', 'weight']"
                         :id="formData.sizeIntegration?.id"/>
  <CompareProductsFields v-if="showComponents.picIntegration"
                         v-model="formData.picIntegration.compareFields"
                         comparedProperty="picIntegration"
                         :comparedFields="['gallery']"
                         :id="formData.picIntegration?.id"/>
  <SuccessModal id="product_setting_sending_success" :title="t('modalSuccessTitle')" :message="t('modalSuccessContent')"/>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { helpers, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import BaseSelect from '@/components/base-select/Main'
import BaseErrorMessage from '@/components/base-error-message/Main'
import CompareProductsFields from '@/components/products/compare-products-fields/Main'
import SuccessModal from '@/components/success-modal/Main'
import _ from 'lodash'

export default defineComponent({
  name: 'ProductsSettings',
  components: { SuccessModal, BaseErrorMessage, BaseSelect, CompareProductsFields },
  setup() {
    const store = useStore()
    const showComponents = reactive({})
    const integrations = computed(() => store.getters['integrations/getIntegrations'])
    const productSettings = computed(() => store.getters['products/getProductsSettings'])
    const dataSending = ref(false)
    const formData = reactive({
      baseIntegration: { compareFields: {} },
      priceIntegration: { compareFields: {} },
      sizeIntegration: { compareFields: {} },
      picIntegration: { compareFields: {} }
    })

    const onSubmit = async () => {
      const result = await v$.value.$validate()
      if (result) {
        const payload = {}
        Object.keys(formData).forEach(item => {
          if (item === 'baseField') payload[item] = formData[item]
          else payload[item] = _.omit(formData[item], ['type'])
        })
        dataSending.value = true
        store.dispatch('products/saveProductsSettings', payload).then(() => {
          cash('#product_setting_sending_success').modal('show')
          dataSending.value = false
        })
      }
    }

    const getIntegrationTypeById = (id) => {
      const selectedIntegration = integrations.value.filter(item => +item.ID === +id)
      return selectedIntegration[0].TYPE
    }

    const updateSetting = (prop, integrationTypes, id, oldId) => {
      if (!id) return
      if (oldId !== undefined) formData[prop].compareFields = {}
      formData[prop].type = getIntegrationTypeById(id)
    }

    watch(() => formData?.baseIntegration?.id, (val, oldVal) => {
      updateSetting('baseIntegration', ['IMSite'], val, oldVal)
    })

    watch(() => formData?.priceIntegration?.id, (val, oldVal) => {
      updateSetting('priceIntegration', ['IMSite'], val, oldVal)
    })

    watch(() => formData?.sizeIntegration?.id, (val, oldVal) => {
      updateSetting('sizeIntegration', ['RetailCRM', 'IMSite'], val, oldVal)
    })

    watch(() => formData?.picIntegration?.id, (val, oldVal) => {
      updateSetting('picIntegration', ['IMSite'], val, oldVal)
    })

    onMounted(() => {
      Promise.all([
        store.dispatch('integrations/loadIntegrations'),
        store.dispatch('products/loadProductsSettings')
      ]).then(() => {
        Object.keys(productSettings.value).forEach(key => {
          formData[key] = productSettings.value[key]
        })
      })
    })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          productComparison: 'قارن المنتجات بـ...',
          article: 'مادة',
          barcode: 'الباركود',
          pricesSrc: 'مصدر البيانات المتعلقة بأسعار المنتجات',
          imgsSrcs: 'مصدر صورة المنتج',
          productBaselineData: 'بيانات خط أساس المنتج',
          productSizeDataSource: 'مصدر بيانات حجم المنتج',
          compareProps: 'قارن الخصائص',
          saveChanges: 'نقذ',
          modalSuccessTitle: 'نجاح',
          modalSuccessContent: 'تم حفظ البيانات بنجاح',
          requiredFieldText: 'حقل مطلوب',
          comparedFieldsErrorText: 'يتطلب هذا التكامل رسم خرائط ميدانية'
        },
        en: {
          productComparison: 'Сompare products to...',
          article: 'Article',
          barcode: 'Barcode',
          pricesSrc: 'Source of data on product prices',
          imgsSrcs: 'Product Image Source',
          productBaselineData: 'Product baseline data',
          productSizeDataSource: 'Product size data source',
          compareProps: 'Compare properties',
          saveChanges: 'Save Changes',
          modalSuccessTitle: 'Success',
          modalSuccessContent: 'Data successfully saved',
          requiredFieldText: 'Required field',
          comparedFieldsErrorText: 'This integration requires field mapping'
        },
        ru: {
          productComparison: 'Сопоставлять продукты по...',
          article: 'Артикул',
          barcode: 'Штрихкод',
          pricesSrc: 'Источник данных о ценах продукта',
          imgsSrcs: 'Источник изображений продукта',
          productBaselineData: 'Базовые данные продукта',
          productSizeDataSource: 'Источник данных о габаритах продукта',
          compareProps: 'Сопоставить поля',
          saveChanges: 'Сохранить изменения',
          modalSuccessTitle: 'Успех',
          modalSuccessContent: 'Данные сохранены',
          requiredFieldText: 'Обязательное поле',
          comparedFieldsErrorText: 'Данная интеграция требует сопоставления полей'
        }
      }
    })

    // валидация
    const rulesForId = { required: helpers.withMessage(t('requiredFieldText'), required) }
    const validationRules = computed(() => ({
      baseField: { required: helpers.withMessage(t('requiredFieldText'), required) },
      baseIntegration: {
        id: rulesForId,
        comparedFields: {
          required: helpers.withMessage(t('comparedFieldsErrorText'), () => formData?.baseIntegration?.type === 'IMSite' ? !_.isEmpty(formData.baseIntegration.compareFields) : true)
        }
      },
      priceIntegration: {
        id: rulesForId,
        comparedFields: {
          required: helpers.withMessage(t('comparedFieldsErrorText'), () => formData?.priceIntegration?.type === 'IMSite' ? !_.isEmpty(formData.priceIntegration.compareFields) : true)
        }
      },
      sizeIntegration: {
        id: rulesForId,
        comparedFields: {
          required: helpers.withMessage(t('comparedFieldsErrorText'), () => {
            if (formData?.sizeIntegration?.type === 'IMSite' ||
              formData?.sizeIntegration?.type === 'RetailCRM') return !_.isEmpty(formData.sizeIntegration.compareFields)
            return true
          })
        }
      },
      picIntegration: {
        id: rulesForId,
        comparedFields: {
          required: helpers.withMessage(t('comparedFieldsErrorText'), () => formData?.picIntegration?.type === 'IMSite' ? !_.isEmpty(formData.picIntegration.compareFields) : true)
        }
      }
    }))
    const v$ = useVuelidate(validationRules, formData)

    return {
      showComponents,
      dataSending,
      onSubmit,
      integrations,
      formData,
      v$,
      t
    }
  }
})
</script>

<style scoped>

</style>
