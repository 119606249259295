<template>
  <div class="2xl:order-2">
    <div class="flex justify-end mb-4">
      <div class="preview w-full mx-6">
        <TinySlider
          v-if="!$_.isEmpty(pictures)"
          :options="{
            autoplay: false,
            controls: true,
            items: 1,
            responsive: {
              600: {
                items: 2
              }
            },
          }"
        >
          <div
            v-for="(pic, index) in pictures"
            :key="'product-img-' + index"
            class="w-full h-64 px-2"
          >
            <img
              :src="pic"
              alt="Фото продукта"
              data-action="zoom"
            />
          </div>
        </TinySlider>
      </div>
    </div>
    <Dropzone paramName="image"
              :options="{
                          url: 'https://apisf.nikit.me/file.php',
                          dictCancelUploadConfirmation: t('dictCancelUploadConfirmationText'),
                          dictMaxFilesExceeded: t('uploadAreaDescriptionText'),
                          dictCancelUpload:  t('uploadFileCancelBtnText'),
                          dictRemoveFile:  t('deleteUploadedFileBtnText'),
                          addRemoveLinks: true,
                          multipleUpload: true,
                          maxFilesize: 5,
                          maxFiles: 20,
                          init: addDropzoneEventListeners
                        }"
              class="dropzone">
      <div class="text-lg font-medium">
        {{ t('uploadAreaTitleText') }}
      </div>
      <div class="text-gray-600">
        {{ t('uploadAreaDescriptionText') }}
      </div>
    </Dropzone>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { notifications as $notifications } from '@/utils/showNotification'
import localizationContent from './index'

export default defineComponent({
  name: 'ProductImages',
  props: {
    pictures: [Object, Array]
  },
  setup(props, { emit }) {
    // локализация
    const { t } = useI18n(localizationContent)

    /* Start upload file */
    const store = useStore()

    const uploadFiles = (file) => {
      const formData = new FormData()
      formData.append('file', file)

      store.dispatch('query/sendRequest', {
        url: 'products/upload-pic/',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        formData: formData
      }).then(res => emit('pushPic', res.data[0]))
    }

    const addDropzoneEventListeners = function () {
      this.on('success', file => uploadFiles(file))
      this.on('error', () => $notifications.showError(t('uploadAreaDescriptionText')))
    }

    const onDestroySlider = () => {
    }

    /* End upload file */
    return {
      t,
      addDropzoneEventListeners,
      onDestroySlider
    }
  }
})
</script>

<style scoped>

</style>
