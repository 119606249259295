<template>
  <template v-if="validatedProperty && nestedValidatedProperty">
    <div :class="['text-theme-6', 'top-full', 'whitespace-nowrap', position]"
         v-for="error in validationState[validatedProperty][nestedValidatedProperty].$errors"
         :key="error.$uid">
      {{ error.$message }}
    </div>
  </template>
  <template v-else-if="validatedProperty">
    <div :class="['text-theme-6', 'top-full', 'whitespace-nowrap', position]"
         v-for="error in validationState[validatedProperty].$errors"
         :key="error.$uid">
      {{ error.$message }}
    </div>
  </template>
  <template v-else>
    <div :class="['text-theme-6', 'top-full', 'whitespace-nowrap', position]"
         v-for="error in validationState.$errors"
         :key="error.$uid">
      {{ error.$message }}
    </div>
  </template>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseErrorMessage',
  props: {
    validationState: Object,
    validatedProperty: String,
    nestedValidatedProperty: String,
    position: {
      type: String,
      default: 'absolute'
    }
  }
})
</script>

<style scoped>

</style>
