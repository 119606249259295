<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-8">
      <h2 class="text-lg font-medium mr-auto">{{ t('pageTitle') }}</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link class="btn btn-primary shadow-md mr-2" :to="{ name: 'logistics-companies' }">
          {{ t('transportCompanySettingsBtn') }}
        </router-link>
      </div>
    </div>
    <div class="shadow-sm bg-white dark:bg-dark-3 p-3 rounded-md mb-4">
      <CargoData v-for="(cargoItem, index) in formData.cargoData.cargoList"
                 :key="index"
                 :index="index"
                 :cargoItem="cargoItem"
                 @update:cargoItem="formData.cargoData.cargoList[index] = $event.target.value"
                 @deleteItem="formData.cargoData.cargoList.splice(index, 1)"/>
      <button class="btn btn-success w-24" @click="addCargoItem"><PlusIcon class="w-5 h-5"/></button>
    </div>
    <ItineraryData v-model="formData.itineraryData" :deliveryAddress="currentOrder?.DELIVERY_DATA?.ADDRESS"/>
    <AdditionalData v-model="formData.additionalData"/>
    <div class="flex items-center mt-8 mb-8">
      <button type="button" class="btn btn-primary" @click="submitData">
        {{ t('calculateBtn') }}
      </button>
      <LoadingIcon v-show="loading" icon="ball-triangle" class="ml-4 w-8 h-8" />
      <div class="ml-4" v-show="!loading">
        {{ t('feedbackQuestion') }}
        <a href="javascript:;"
           class="text-theme-1 dark:text-theme-10"
           data-toggle="modal"
           data-target="#calculation_feedback_modal">
          {{ t('clickHereLink') }}
        </a>
        {{ t('contactUsText') }}
      </div>
    </div>
    <CalculationResultList :calculationData="calculationResult"/>
    <LogisticsFeedbackForm :requestData="formData" :responseData="calculationResult"/>
  </div>
</template>

<script>
import { reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import useVuelidate from '@vuelidate/core'
import CargoData from '@/components/logistics-price-calculation-form/cargo-data/Main.vue'
import ItineraryData from '@/components/logistics-price-calculation-form/itinerary-data/Main.vue'
import AdditionalData from '@/components/logistics-price-calculation-form/additional-data/Main.vue'
import CalculationResultList from '@/components/logistics-price-calculation-form/calculation-result/Main'
import LogisticsFeedbackForm from '@/components/logistics-price-calculation-form/feedback-form/Main'
import { useI18n } from 'vue-i18n'

export default {
  name: 'LogisticsPriceCalculation',
  props: {
    id: String
  },
  components: {
    LogisticsFeedbackForm,
    CalculationResultList,
    CargoData,
    ItineraryData,
    AdditionalData
  },
  setup(props) {
    // загрузка данных о транспортных компаниях
    const transportCompanies = useStoreDataGetting(
      'logistics/getLogisticsCompanies',
      'logistics/loadLogisticsCompanies')

    // работа с состоянием
    const formData = reactive({
      cargoData: {
        cargoList: props.id ? [] : [{}]
      },
      itineraryData: {
        leave: { delivery: 'courier' },
        arrive: { delivery: 'courier' }
      },
      additionalData: []
    })
    const addCargoItem = () => {
      formData.cargoData.cargoList.push({})
    }

    // данные текущего заказа
    let currentOrder
    const getCurrentOrderCargoData = (data) => {
      data.PRODUCTS.forEach(product => {
        const cargoItem = {}
        const size = JSON.parse(data.PRODUCTS_SYSTEM[product.ARTICLE].SIZE)

        cargoItem.count = product.CNT
        cargoItem.cost = 2000
        cargoItem.width = size.WIDTH * 10
        cargoItem.height = size.HEIGHT * 10
        cargoItem.length = size.LENGTH * 10
        cargoItem.weight = size.WEIGHT / 1000

        /* data.PRODUCTS_SYSTEM[product.ARTICLE].ATTRIBUTES.forEach(attribute => {
          if (attribute.name === 'packingwidth') cargoItem.width = attribute.value * 10
          if (attribute.name === 'packingheight') cargoItem.height = attribute.value * 10
          if (attribute.name === 'packinglength') cargoItem.length = attribute.value * 10
          if (attribute.name === 'packingweight') cargoItem.weight = attribute.value / 1000
        }) */

        formData.cargoData.cargoList.push(cargoItem)
      })
    }

    if (props.id) {
      currentOrder = useStoreDataGetting(
        'orders/getCurrentOrder',
        'orders/loadOrderByID',
        { id: props.id }).data

      watch(() => currentOrder.value, () => {
        getCurrentOrderCargoData(currentOrder.value)
      })
    }

    // отправка данных для расчет и обработка ответа
    const store = useStore()
    const v$ = useVuelidate()
    const calculationResult = reactive([])
    const loading = ref(false)

    const setResult = (data) => {
      Object.entries(data).forEach(([key, val]) => calculationResult.push({
        name: key,
        data: val
      }))
    }

    const getCalculation = async () => {
      await store.dispatch('query/sendRequest', {
        url: 'transport-companies/calculate',
        data: formData,
        method: 'POST'
      }).then(response => {
        setResult(response.data)
      })
    }

    const submitData = async () => {
      const result = await v$.value.$validate()
      if (result) {
        let counter = 0
        loading.value = true
        calculationResult.length = 0

        transportCompanies.data.value.forEach(company => {
          formData.tc = company.TYPE
          getCalculation().finally(() => {
            formData.tc = ''
            counter++
            loading.value = counter < transportCompanies.data.value.length
          })
        })
      }
    }

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitle: 'حساب تكاليف الشحن',
          transportCompanySettingsBtn: 'إعدادات شركة النقل',
          calculateBtn: 'حسب',
          feedbackQuestion: 'هل وجدت حشرة ؟',
          clickHereLink: 'انقر هنا',
          contactUsText: 'والكتابة إلينا'
        },
        en: {
          pageTitle: 'Shipping cost calculation',
          transportCompanySettingsBtn: 'Transport company settings',
          calculateBtn: 'Calculate',
          feedbackQuestion: 'Have you found a bug?',
          clickHereLink: 'Click here',
          contactUsText: 'and write to us'
        },
        ru: {
          pageTitle: 'Расчет стоимости доставки',
          transportCompanySettingsBtn: 'Настройки ТК',
          calculateBtn: 'Рассчитать',
          feedbackQuestion: 'Нашли ошибку?',
          clickHereLink: 'Нажмите сюда',
          contactUsText: 'и напишите нам'
        }
      }
    })

    return {
      loading,
      calculationResult,
      currentOrder,
      formData,
      addCargoItem,
      submitData,
      v$,
      t
    }
  }
}
</script>
