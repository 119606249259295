<template>
  <div>
    <h3 v-if="data.customer" class="text-lg font-medium mr-auto">Получатель</h3>
    <div v-if="data.customer">
      <div>
        <span class="font-medium mr-1">Фамилия:</span>
        {{ data.customer.lastname }}
      </div>
      <div>
        <span class="font-medium mr-1">Имя:</span>
        {{ data.customer.firstname }}
      </div>
    </div>
    <h3 v-if="data.order" class="text-lg font-medium mr-auto mt-3">Данные по
      зазказу</h3>
    <div v-if="data.order">
      <div>
        <span class="font-medium mr-1">ID заказа в СДТ:</span>
        {{ data.order.order_id }}
      </div>
      <div class="mr-3">
        <span class="font-medium mr-1">Статус:</span>
        {{ sdtStatuses[data.order.status] }}
        ({{ data.order.date_status }})
      </div>
      <div class="mr-3">
        <span class="font-medium mr-1">Вес(г):</span>
        {{ data.order.weight }}
      </div>
      <div class="mr-3">
        <span class="font-medium mr-1">Адрес доставки:</span>
        {{ data.order.city_name }},
        {{ data.order.street }}
      </div>
      <div class="mr-3">
        <span class="font-medium mr-1">Тип оплаты:</span>
        <span v-if="data.order.payment_type === 1">При получении</span>
        <span v-if="data.order.payment_type === 2">Онлайн</span>
      </div>
      <div class="mr-3">
        <span class="font-medium mr-1">Тип доставки:</span>
        <span v-if="data.order.type === 1">Курьерская доставка СДТ</span>
        <span v-if="data.order.type === 2">Самовывоз</span>
        <span v-if="data.order.type === 3">Почта</span>
        <span v-if="data.order.type === 4">Курьерская доставка</span>
      </div>
      <div class="mr-3">
        <span class="font-medium mr-1">Планируемая дата доставки клиенту:</span>
        {{ data.order.send_date }}
      </div>
      <div class="mr-3">
        <span class="font-medium mr-1">Номер отслеживания:</span>
        {{ data.order.post_barcode }}
      </div>
      <div class="mr-3">
        <span class="font-medium mr-1">Комментарий:</span>
        {{ data.order.comment }}
      </div>
    </div>

    <h3 class="text-lg font-medium mt-3">Состав заказа</h3>
    <div class="overflow-x-auto mt-2" v-if="data.items">
      <table class="table p-1">
        <thead>
        <tr class="bg-gray-200 dark:bg-dark-1">
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            Название
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            Артикул/ШК
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            Количество
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            Цена
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            Возврат
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in data.items"
            :key="item.item_id"
            :class="{ 'bg-gray-200 dark:bg-dark-1': index % 2 === 1 }">
          <td class="border-b whitespace-nowrap">
            {{ item.name }}
          </td>
          <td class="border-b whitespace-nowrap">
            {{ item.item_id }}
          </td>
          <td class="border-b whitespace-nowrap">
            {{ item.quantity }}
          </td>
          <td class="border-b whitespace-nowrap">
            {{ $h.cutFraction(item.price) }} р.
          </td>
          <td class="border-b whitespace-nowrap">
            {{ item.date_return }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <button class="btn btn-primary shadow-md w-5/12 mt-2"
            v-if="data?.order?.options?.manual_confirm === 1"
            @click="confirmSdtOrderById(data.order.order_id)">
      Подтвердить отправку
    </button>
  </div>
</template>

<script>
import sdtStatuses from '@/data/sdtStatuses'
import { useStore } from 'vuex'

export default {
  name: 'SdtInfo',
  props: {
    data: Object
  },
  setup() {
    const store = useStore()
    const confirmSdtOrderById = (id) => {
      store.dispatch('query/sendRequest', {
        url: 'orders/confirm-pack-to-sdt/',
        data: { id: id },
        method: 'POST'
      }).then(response => {
        if (!response.data.success) alert(response.data.comment)
      })
    }

    return {
      sdtStatuses,
      confirmSdtOrderById
    }
  }
}
</script>

<style lang="scss" scoped>
  .order-details.accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    @apply text-gray-800 dark:text-gray-100;
  }
</style>
