<template>
  <div>
    <div
      v-for="(document, index) in documents"
      :key="document.id"
      :class="[
        'bg-white rounded-lg p-2 border',
        { 'mb-2': index !== document.length - 1 }]"
    >
      <div class="flex flex-wrap -mx-3">
        <div class="mx-3 my-1">
          <span class="font-medium">Тип документа:</span>
          {{ document?.type_name }}
        </div>
        <div class="mx-3 my-1">
          <span class="font-medium">Дата создания:</span>
          {{ document?.date_create }}
        </div>
        <div class="mx-3 my-1">
          <span class="font-medium">Статус документа:</span>
          {{ document?.status_name }}
        </div>
      </div>
      <div class="overflow-x-auto mt-1">
        <table
          v-if="!$_.isEmpty(document.items)"
          class="table"
        >
          <thead>
          <tr class="bg-gray-300 dark:bg-dark-1 dark:text-white">
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Артикул</th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Название товара</th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Количество</th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Цена</th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Сумма</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(product, index) in document?.items"
            :key="'documentItemProduct' + product.id + index"
          >
            <td>{{ product.item_id }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.quantity }}</td>
            <td>{{ product.price_nds }}</td>
            <td>{{ product.sum_nds }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MovementDocuments',
  props: {
    documents: Array
  },
  setup () {
    return {
    }
  }
})
</script>

<style scoped>

</style>
