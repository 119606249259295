<template>
  <td class="text-left border-b dark:border-dark-5 w-32 font-medium">
    <div v-if="item?.error === undefined">
      <div v-for="(service, serviceIndex) in item?.transfers[0].services"
           :key="serviceIndex"
           class="whitespace-nowrap">
        <div v-show="serviceIndex > 0">
          {{ service.info }}
        </div>
      </div>
    </div>
  </td>
  <td class="text-right border-b dark:border-dark-5 w-32 font-medium">
    <div v-if="item?.error === undefined">
      <div v-for="(term, termIndex) in item?.transfers" :key="termIndex + 'pec'" >
        <!--&& item?.commonTerms[termIndex] !== undefined-->
        <div class="flex justify-end" v-if="parseInt(term?.costTotal) > 0 && pecTransportingType[term?.transportingType] !== 'Easy Way'">
          <div class="whitespace-nowrap mr-2">
            {{ pecTransportingType[term?.transportingType] }}
          </div>|
          <div class="text-gray-600 whitespace-nowrap mr-2 ml-2" v-if="item?.commonTerms.length">
            Дней: {{ item?.commonTerms[termIndex]?.transportingWithDeliveryWithPickup ?? 0 }}
          </div>
          <div class="text-gray-600 whitespace-nowrap mr-2 ml-2" v-else>
            Дней: 1
          </div>|
          <div class="ml-0.5 w-20">
            {{ Math.round(parseInt(term?.costTotal)) }}&nbsp;руб.
          </div>
        </div>
      </div>
    </div>

    <CalculationDataError v-else :error-message="item?.error"/>
  </td>
</template>

<script>
import { defineComponent } from 'vue'
import CalculationDataError
from '@/components/logistics-price-calculation-form/calculation-result-items/calculation-data-error/Main'

export default defineComponent({
  name: 'PecCalculation',
  components: { CalculationDataError },
  props: {
    item: Object
  },
  setup() {
    const pecTransportingType = {
      1: 'Авто',
      2: 'Авиа',
      12: 'Easy Way'
    }

    return {
      pecTransportingType
    }
  }
})
</script>

<style scoped>

</style>
