<template>
  <!-- BEGIN: Profile Info -->
  <div class="intro-y py-4 pos">
    <h2 class="text-lg font-medium mb-4">{{ t('pageTitleText') }}</h2>
    <div class="box p-2">
      <div class="pos__tabs nav nav-tabs justify-center flex-col sm:flex-row"
           role="tablist">
        <a id="profile-tab"
           data-toggle="tab"
           data-target="#details"
           href="javascript:;"
           class="flex-1 py-2 rounded-md text-center"
           :class="{ active: $route.params.tab === 'client-info' }"
           role="tab"
           aria-controls="profile"
           :aria-selected="$route.params.tab === 'client-info'"
           @click="$router.push({ params: { tab: 'client-info' } })" >
          {{ t('clientDataTabBtnText') }}
        </a>
        <a id="change-password-tab"
          data-toggle="tab"
           data-target="#earning_point"
           href="javascript:;"
           class="flex-1 py-2 rounded-md text-center"
           :class="{ active: $route.params.tab === 'earning-point' }"
           role="tab"
           :aria-selected="$route.params.tab === 'earning-point'"
           @click="$router.push({ params: { tab: 'earning-point' } })">
          {{ t('earningPointsTabBtnText') }}
        </a>
        <a id="settings-tab"
           data-toggle="tab"
           data-target="#points_write_off"
           href="javascript:;"
           class="flex-1 py-2 rounded-md text-center"
           :class="{ active: $route.params.tab === 'writing-off-points' }"
           role="tab"
           :aria-selected="$route.params.tab === 'writing-off-points'"
           @click="$router.push({ params: { tab: 'writing-off-points' } })">
          {{ t('pointsWhiteOffTabBtnText') }}
        </a>
      </div>
    </div>
  </div>
  <!-- END: Profile Info -->
  <div class="tab-content box p-4">
    <div :class="{ active: $route.params.tab === 'client-info'}"
         id="details"
         class="tab-pane"
         role="tabpanel"
         aria-labelledby="client-details-tab">
      <ClientInfo v-if="$route.params.tab === 'client-info'" :id="id"/>
    </div>
    <div :class="{ active: $route.params.tab === 'earning-point'}"
         id="earning_point"
         class="tab-pane"
         role="tabpanel"
         aria-labelledby="client-details-tab">
      <EarningPointsTable v-if="$route.params.tab === 'earning-point'" :id="id"/>
    </div>
    <div :class="{ active: $route.params.tab === 'writing-off-points'}"
         id="points_write_off"
         class="tab-pane"
         role="tabpanel"
         aria-labelledby="client-details-tab">
      <PointsWriteOffTable v-if="$route.params.tab === 'writing-off-points'" :id="id"/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import PointsWriteOffTable from '@/components/client/points-write-off-table/Main'
import EarningPointsTable from '@/components/client/earning-points-table/Main'
import ClientInfo from '@/components/client/client-info/Main'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ClientDetails',
  components: { ClientInfo, PointsWriteOffTable, EarningPointsTable },
  props: {
    id: String,
    tab: String
  },
  setup() {
    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitleText: 'معلومات العميل',
          clientDataTabBtnText: 'بيانات العميل',
          earningPointsTabBtnText: 'نقطة الكسب',
          pointsWhiteOffTabBtnText: 'شطب النقاط'
        },
        en: {
          pageTitleText: 'Client information',
          clientDataTabBtnText: 'Client data',
          earningPointsTabBtnText: 'Earning point',
          pointsWhiteOffTabBtnText: 'Points write-off'
        },
        ru: {
          pageTitleText: 'Информация о клиенте',
          clientDataTabBtnText: 'Данные клиента',
          earningPointsTabBtnText: 'Начисления баллов',
          pointsWhiteOffTabBtnText: 'Списания баллов'
        }
      }
    })

    return { t }
  }
})
</script>

<style scoped>

</style>
