<template>
  <div v-bind="$attrs">
    <div
      v-for="(anomaly, index) in anomalies"
      :key="anomaly.task_id"
      :class="[
        'bg-white rounded-lg p-2 border',
        { 'mb-2': index !== anomalies.length - 1 }]"
    >
      <div class="flex flex-wrap -mx-3 mb-1">
        <div class="mx-3 my-1">
          <span class="font-medium">Создано:</span>
          {{ anomaly?.date_create }}
        </div>
        <div class="mx-3 my-1">
          <span class="font-medium">Статус:</span>
          {{ anomaly?.status_name }}
        </div>
        <div class="mx-3 my-1">
          <span class="font-medium">ID задачи в хелпдеск</span>
          {{ anomaly?.task_id }}
        </div>
      </div>
      <div class="overflow-x-auto">
        <table
          v-if="!$_.isEmpty(anomaly.items)"
          class="table"
        >
          <thead>
          <tr class="bg-gray-300 dark:bg-dark-1 dark:text-white">
            <th
              v-if="containsArticles(anomaly?.items)"
              class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Артикул
            </th>
            <th
              v-else
              class="border-b-2 dark:border-dark-5 whitespace-nowrap"
            >
              Штрихкод
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Комментарий</th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Действия</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(product, index) in anomaly?.items"
            :key="'anomalyItemProduct' + product?.item_id + index"
            :class="{ 'bg-gray-200': index % 2 === 1 }"
          >
            <td v-if="containsArticles(anomaly?.items)">
              {{ product.article }}
            </td>
            <td v-else>{{ product.barcode[0] }}</td>
            <td>{{ product.comment }}</td>
            <td>
              <a
                class="font-medium underline cursor-pointer"
                href="javascript:;"
                data-toggle="modal"
                data-target="#anomaly_photos_modal"
                @click="currentAnomalyID = anomaly.task_id; currentAnomalyProductID = product?.item_id"
              >
                Посмотреть фотографии
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- BEGIN: Anomaly Photos Modal -->
  <div
    v-if="!$_.isEmpty(currentAnomalyPhotos)"
    :key="'key' + currentAnomalyID + currentAnomalyProductID"
    id="anomaly_photos_modal"
    class="modal rtl:right-auto rtl:left-0"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <TinySlider
        :options="{
                autoplay: false,
                // autoHeight: true,
                controls: true,
                items: 1
              }"
      >
        <img
          v-for="photo in currentAnomalyPhotos"
          :key="'anomaly-photo-' + photo"
          class="h-[85vh] object-contain"
          :src="photo"
          alt="Изображение продукта"
        />
      </TinySlider>
    </div>
  </div>
  <!-- END: Anomaly Photos Modal -->
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
// import _ from 'lodash'

export default defineComponent({
  name: 'MovementAnomalies',
  props: {
    anomalies: Array
  },
  setup (props) {
    const currentAnomalyID = ref()
    const currentAnomalyProductID = ref()

    const currentAnomalyPhotos = computed(() => {
      const anomaly = ref()
      const anomalyProduct = ref()

      anomaly.value = props.anomalies.find(item => item.task_id === currentAnomalyID.value)
      if (anomaly.value?.items.length) {
        anomalyProduct.value = anomaly.value.items.find(product => product.item_id === currentAnomalyProductID.value)
      }
      return anomalyProduct.value?.photos
    })

    const containsArticles = items => {
      const firstArticleIndex = items.findIndex(item => item.article)
      return firstArticleIndex > -1
    }

    return {
      currentAnomalyID,
      currentAnomalyProductID,
      currentAnomalyPhotos,
      containsArticles
    }
  }
})
</script>

<style scoped>

</style>
