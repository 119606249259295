<template>
  <div class="grid grid-cols-12 gap-4 mb-5">
    <div class="col-span-12 lg:col-span-5 flex flex-col">
      <div class="font-medium text-base mb-2">{{ t('cargoNumberText') }} {{ index + 1 }}</div>
      <div class="grid grid-cols-3 gap-2 lg:h-full">
        <div class="input-group col-span-3 sm:col-span-1 relative">
          <input
            id="cargo-weight"
            type="number"
            class="form-control right:bg-theme-6"
            v-model="item.weight"
          />
          <BaseErrorMessage :validationState="v$" validatedProperty="weight"/>
          <label for="cargo-weight" class="input-group-text ">
            {{ t('weightLabelText') }}
          </label>
        </div>
        <div class="input-group col-span-3 sm:col-span-1 relative">
          <input
            id="cargo-quantity"
            type="number"
            class="form-control"
            v-model="item.count"
          />
          <BaseErrorMessage :validationState="v$" validatedProperty="count"/>
          <label for="cargo-quantity" class="input-group-text">
            {{ t('unitsLabelText') }}
          </label>
        </div>
        <div class="input-group col-span-3 sm:col-span-1 relative">
          <input
            id="cargo-cost"
            type="text"
            class="form-control"
            v-model="item.cost"
          />
          <BaseErrorMessage :validationState="v$" validatedProperty="cost"/>
          <label for="cargo-cost" class="input-group-text">
            {{ t('costLabelText') }}
          </label>
        </div>
      </div>
    </div>
    <div class="col-span-12 lg:col-span-7">
      <div class="font-medium text-base mb-2">{{ t('dimensionsSubtitleText') }}</div>
      <div class="grid grid-cols-10 gap-2">
        <div class="input-group col-span-10 sm:col-span-3 relative">
          <input
            id="cargo-width"
            type="number"
            class="form-control"
            :value="item.width ? +item.width / 10 : ''"
            @input="item.width = +$event.target.value * 10"
          />
          <BaseErrorMessage :validationState="v$" validatedProperty="width"/>
          <label for="cargo-width" class="input-group-text whitespace-nowrap">
            {{ t('widthLabelText') }}
          </label>
        </div>
        <div class="input-group col-span-10 sm:col-span-3 relative">
          <input
            id="cargo-height"
            type="number"
            class="form-control"
            :value="item.height ? +item.height / 10 : ''"
            @input="item.height = +$event.target.value * 10"
          />
          <BaseErrorMessage :validationState="v$" validatedProperty="height"/>
          <label for="cargo-height" class="input-group-text whitespace-nowrap">
            {{ t('heightLabelText') }}
          </label>
        </div>
        <div class="input-group col-span-10 sm:col-span-3 relative">
          <input
            id="cargo-length"
            type="number"
            class="form-control"
            :value="item.length ? +item.length / 10 : ''"
            @input="item.length = +$event.target.value * 10"
          />
          <BaseErrorMessage :validationState="v$" validatedProperty="length"/>
          <label for="cargo-length" class="input-group-text whitespace-nowrap">
            {{ t('lengthLabelText') }}
          </label>
        </div>
        <button class="btn btn-danger col-span-3 sm:col-span-1" @click="$emit('deleteItem')">
          <TrashIcon/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import BaseErrorMessage from '@/components/base-error-message/Main.vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'CargoData',
  components: { BaseErrorMessage },
  props: {
    index: Number,
    cargoItem: Object
  },
  setup(props, { emit }) {
    const item = computed({
      get: () => props.cargoItem,
      set: (val) => { emit('update:cargoItem', val) }
    })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          cargoNumberText: 'بضاعة ',
          costLabelText: ' ₽',
          weightLabelText: 'kg',
          unitsLabelText: 'pcs.',
          dimensionsSubtitleText: 'أبعاد',
          lengthLabelText: '(cm) L',
          widthLabelText: '(cm) W',
          heightLabelText: '(cm) H',
          requiredFieldText: 'حقل مطلوب'
        },
        en: {
          cargoNumberText: 'Cargo № ',
          costLabelText: ' ₽',
          weightLabelText: 'kg',
          unitsLabelText: 'pcs.',
          dimensionsSubtitleText: 'Dimensions',
          lengthLabelText: 'L (cm)',
          widthLabelText: 'W (cm)',
          heightLabelText: 'H (cm)',
          requiredFieldText: 'Required field'
        },
        ru: {
          cargoNumberText: 'Груз № ',
          costLabelText: ' ₽',
          weightLabelText: 'кг',
          unitsLabelText: 'шт.',
          dimensionsSubtitleText: 'Габариты',
          lengthLabelText: 'Д (см)',
          widthLabelText: 'Ш (см)',
          heightLabelText: 'В (см)',
          requiredFieldText: 'Обязательное поле'
        }
      }
    })

    // валидация
    const validationRules = computed(() => ({
      weight: { required: helpers.withMessage(t('requiredFieldText'), required) },
      count: { required: helpers.withMessage(t('requiredFieldText'), required) },
      cost: { required: helpers.withMessage(t('requiredFieldText'), required) },
      width: { required: helpers.withMessage(t('requiredFieldText'), required) },
      height: { required: helpers.withMessage(t('requiredFieldText'), required) },
      length: { required: helpers.withMessage(t('requiredFieldText'), required) }
    }))
    const v$ = useVuelidate(validationRules, item)

    return {
      item,
      v$,
      t
    }
  }
}
</script>

<style scoped>

</style>
