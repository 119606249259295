<template>
<div class="p-4">
<pre>
Каждый из виджетов встраивается в глобальный объект window.
Обратиться можно через window.appPickUpPointsSDT

Вы можете выбрать из двух виджетов:
- с выбором способа доставки (курьер или пункт выдачи).
- только пункт выдачи. Обратиться можно через window.appPickupPointsSdt

Чтобы запустить виджет необходимо вызвать метод setProperties (window.appPickUpPointsSDTsetProperties)
Функция в качества аргумент принимает объект, в котором вы можете задать входные параметры (настройки) для виджета.
Список свойств приведен ниже.

виджет с выбором способа доставки:

address.
Строка (например, 'г Москва, Нагорный проезд, д 12Б'). Если изначально передать адрес в качестве настройки, то пользователь не сможет изменить его вручную.
Если же данный параметр отсутствует, то пользователю будет доступен ручной ввод, в котором он может указать нужный ему адрес для поиска ближайших доступных пунктов выдачи и доставки.

disableCompanies.
Массив. Каждый элемент массива - строка содержащая название компании. В таком случае данная компания не будет выводиться результатах поиска и будет недоступна для пользователя.
Если массив пустой, то будут выводиться все компании
Пример ['BOXBERRY']

disableCourierDelivery. Массив. Каждый элемент массива - строка содержащая название компании. Отличие от предыдущего параметра в том,
что скрываются не все доступные способы доставки данной компании, а только курьерские службы данной компании. Например, если в данном массиве указать
компанию 'cdek', то она будет недоступна в качество курьерской службы, но постоматы данной компании будут все так же отображаться на карте.
Пример ['BOXBERRY','CDEK','TopDelivery','CSE','DPD']

free_delivery. Boolean. Отображать все способы доставки как бесплатные.

MSKFreeDelivery: Boolean. Аналогичен предыдущему пункту, но отображает бесплатными только Московские сервисы.

MSKOnlySDTCourierDelivery: Boolean. Устанавливает курьерскую службу СДТ как единственно доступную по Москве.

order_sum. Число. Обязательный параметр. Сумма заказа.

parcel_size. Массив чисел (порядок параметров?). Габариты заказа (ширина, длина, высота). Обязательный параметр.

showDeliveryInterval: Boolean. Отображать интервал (расписание) доставки для курьерской службы СДТ, а так же предпочтительную дату доставки.

token. Строка. Обязательный параметр. Чей токен? 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NDA5MzB9.Tn_lxzWpTfRuBTx4UmNSTFDycfSidWJgIMWiP8tP-BE'

useRUPostPickupDelivery: Boolean //Отображать отделения Почты РФ как доступные пункты доставки.

useRUPostCourierDelivery: Boolean //Использовать курьерскую доставку Почтой РФ

weight. Число или строка. Масса заказа в киллограммах (или в грамма?)

callback. Колбэк =), то есть метод, который вызывается каждый раз, когда пользователь меняет, пункты выдачи или курьерскую службу, а так же интервал иди дату доставки.

Виджет отображающий только пункты выдачи.
Не принимает конкретный адрес.
В качестве входных параметров (настроек) принимает объект со следующими свойствами:

kladr. Строка. Классификатор адресов Российской Федерации. Используется для установки города (другого населенного пункта),
на территории которого будут отображаться доступные пункты выдачи заказа.

token. Строка. Обязательный параметр. Чей токен? 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NDA5MzB9.Tn_lxzWpTfRuBTx4UmNSTFDycfSidWJgIMWiP8tP-BE'

disableCompanies.
Массив. Каждый элемент массива - строка содержащая название компании. В таком случае данная компания не будет выводиться результатах поиска и будет недоступна для пользователя.
Если массив пустой, то будут выводиться все компании
Пример ['BOXBERRY']

disableFittingRoomFilter. boolean (Если true, скрывается чекбокс 'Только с примеркой' и соответственно возможность отобразить только такие пункты)

order_sum. Число. Обязательный параметр. Сумма заказа.

parcel_size. Массив чисел (порядок параметров?). Габариты заказа (ширина, длина, высота). Обязательный параметр.

weight. Число или строка. Масса заказа в киллограммах (или в грамма?)

callback. Колбэк =), то есть метод, который вызывается каждый раз, когда пользователь меняет, пункты выдачи или курьерскую службу, а так же интервал иди дату доставки.
</pre>

Примеры активации на бэкэнде:

<pre>
&lt;div id="app_pickup_points_sdt"/div&gt;
&lt;script&gt;
  ((w, d) => {
    const u = 'https://apisf.nikit.me/widgets/pickup_transport_companies/dist/';
    const dt = (Date.now() / 60000 | 0);
    const s = d.createElement('script');
    s.async = true;
    s.src = u + 'js/app.js' + '?' + dt;
    const css = d.createElement('link');
    css.href = u + 'css/app.css' + '?' + dt;
    css.rel = 'stylesheet';
    css.type = 'text/css';
    d.body.appendChild(s);
    d.head.appendChild(css);
  })(window, document);
&lt;/script&gt;
</pre>
<pre>

пример активации при инициализации:

Только самовывоз:

appPickupPointsSdt.setProperties({
  kladr: '6300000100000', // Самара
  enabledCompanies: ['CDEK'], // массив строк ['BOXBERRY', 'CDEK'] (если массив пустой, то будут выводиться все компании, иначе только указанные в массиве компании)
  hideControlBar: true,
  token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NDA5MzB9.Tn_lxzWpTfRuBTx4UmNSTFDycfSidWJgIMWiP8tP-BE',
  disableCompanies: [], // массив строк ['BOXBERRY', 'CDEK'] (если массив пустой, то будут выводиться все компании)
  disableFittingRoomFilter: false, // boolean (Если true, скрывается чекбокс 'Только с примеркой')
  weight: 100,
  parcel_size: [140, 180, 50],
  order_sum: 5000,
  withoutFixedDeliveryCost: true,
  callback: (data) => {
      console.log('callback function')
      console.log(data)
  }
})

Cамовывоз и курьерские службы:

window.onload=()=> {
  appPickUpPointsSDT.setProperties({
    address: 'Нягань, 2-й микрорайон, 17', // 'Нягань, Полярная, 4', // 'г Москва, Нагорный проезд, д 12Б',//Майкоп Западная 40
    // address: 'Костромская область, Костромской район, Кострома, ул Овражная, 1', // 'г Москва, Нагорный проезд, д 12Б',//Майкоп Западная 40
    // address: 'г Москва, Нагорный проезд, д 12Б',//Майкоп Западная 40
    MSKFreeDelivery: true,//Доставка по Москве бесплатно
    MSKOnlySDTCourierDelivery: true,//По Москве только Курьерская служба СДТ
    disableCourierDelivery: [],//массив строк допустимые значения ['BOXBERRY','CDEK','TopDelivery','CSE','DPD']

    showDeliveryInterval: true,//Показывать интервал доставки для курьерской службы СДТ
    useRUPostPickupDelivery: true,//Использовать доставку в отделения Почты РФ
    useRUPostCourierDelivery: false, //Использовать курьерскую доставку Почтой РФ
    disableCompanies: ['BOXBERRY'], // массив строк допустимые значения ['BOXBERRY','CDEK','TopDelivery','CSE','PickPoint','DPD'] (если массив пустой, то будут выводиться все компании)
    token:'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NDA5MzB9.Tn_lxzWpTfRuBTx4UmNSTFDycfSidWJgIMWiP8tP-BE',
    weight:'100',
    parcel_size:[140,180,50],
    order_sum: 5000,
    free_delivery: false,
    callback: (data) => {
        console.log('callback function')
        console.log(data)
    }
  })
}
</pre>
</div>
</template>

<script>
export default {
  name: 'WidgetDoc'
}

</script>

<style scoped>

</style>
