const pageParams = {
  page: '1',
  pageSize: '10'
}

const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'products-list',
        title: 'products',
        params: pageParams
      },
      {
        icon: 'BoxIcon',
        pageName: 'orders-list',
        title: 'orders',
        params: pageParams
      },
      {
        icon: 'ActivityIcon',
        pageName: 'stocks',
        title: 'stock',
        params: pageParams
      },
      {
        icon: 'FastForwardIcon',
        pageName: 'stages',
        title: 'stages'
      },
      {
        icon: 'LayoutIcon',
        pageName: 'integrations',
        title: 'integrations'
      },
      {
        icon: 'RepeatIcon',
        pageName: 'automation',
        title: 'automation'
      },
      {
        icon: 'UsersIcon',
        pageName: 'clients',
        title: 'clients',
        params: pageParams
      },
      {
        icon: 'SmileIcon',
        pageName: 'loyalty',
        title: 'loyalty'
      },
      {
        icon: 'TruckIcon',
        pageName: 'logistics-price-calculation',
        title: 'logistic'
      },
      {
        icon: 'MoveIcon',
        pageName: 'movement-list',
        title: 'movements',
        params: pageParams
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
