<template>
  <!-- BEGIN: Dark Mode Switcher-->
  <div
    class="dark-mode-switcher cursor-pointer shadow-md box dark:bg-dark-2 border rounded-full w-24 xl:w-48 h-12 flex items-center justify-center"
    @click="switchMode"
  >
    <MoonIcon class="mr-2"></MoonIcon>
    <div class="mr-4 text-gray-700 dark:text-gray-300 hidden xl:block">{{ t('darkMode')}}</div>
    <div
      :class="{ 'dark-mode-switcher__toggle--active': darkMode }"
      class="dark-mode-switcher__toggle border"
    ></div>
  </div>
  <!-- END: Dark Mode Switcher-->
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'DarkModeSwitcher',
  setup() {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)

    const setDarkModeClass = () => {
      darkMode.value
        ? cash('html').addClass('dark')
        : cash('html').removeClass('dark')
    }

    const switchMode = () => {
      store.dispatch('main/setDarkMode', !darkMode.value)
      setDarkModeClass()
    }

    onMounted(() => {
      setDarkModeClass()
    })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          darkMode: 'الوضع المظلم'
        },
        en: {
          darkMode: 'Dark mode'
        },
        ru: {
          darkMode: 'Ночной режим'
        }
      }
    })

    return {
      switchMode,
      darkMode,
      t
    }
  }
})
</script>
