<template>
  <div v-bind="$attrs" class="intro-y pt-5">
    <div class="flex mb-5 items-center">
      <h2 class="font-medium text-lg mr-4">
        {{ text('pageTitle') }}
      </h2>
      <a
        href="javascript:;"
        class="btn btn-primary ml-auto mr-4"
        data-toggle="modal"
        data-target="#disputed_shipment_modal"
      >
        Оспорить
      </a>
      <button class="btn btn-primary mr-4" @click="onCancelMovement">Отменить</button>
      <button class="btn btn-primary" @click="onApplyMovement">Подтвердить</button>
    </div>
    <div class="box p-3 mb-4">
      <div class="mb-2">
        <span class="font-medium">ID:</span>
        {{ currentMovement?.ID}}
      </div>
      <div class="mb-2">
        <span class="font-medium">Внешний ID:</span>
        {{ currentMovement?.EXTERNAL_ID}}
      </div>
      <div v-if="currentMovement?.COMMENT" class="mb-2">
        <span class="font-medium">{{ text('movementComment') }}:</span>
        {{ currentMovement?.COMMENT }}
      </div>
      <div v-if="currentMovement?.FF_СREATE_DATE" class="mb-2">
        <span class="font-medium">Дата создания:</span>
        {{ currentMovement?.FF_СREATE_DATE }}
      </div>
      <div class="mb-2">
        <span class="font-medium">Дата обновления:</span>
        {{ currentMovement?.FF_STATUS_DATE }}
      </div>
      <div class="mb-2">
        <span class="font-medium">Статус:</span>
        {{ currentMovement?.FF_STATUS}}
      </div>
    </div>
    <div class="accordion">
<!--      Пустой див. Костыль, чтобы блоки не слипались -->
      <div class="mb-4"></div>
      <template
        v-for="(prop, key) in currentMovement"
        :key="key + '123123'"
      >
        <div
          v-if="
            key === 'FF_WAYBILL_ITEMS' ||
            key === 'FF_WAYBILL_ANOMALIES' ||
            key === 'FF_WAYBILL_CHILDREN' ||
            key === 'FF_HELPDESK_TASK'"
          class="accordion-item box p-4 mb-4"
        >
          <div class="accordion-header">
            <button class="accordion-button collapsed text-lg font-medium flex justify-between">
              <template v-if="key === 'FF_WAYBILL_ITEMS'">Продукты</template>
              <template v-if="key === 'FF_WAYBILL_ANOMALIES'">Аномалии</template>
              <template v-if="key === 'FF_WAYBILL_CHILDREN'">Документы по накладным</template>
              <template v-if="key === 'FF_HELPDESK_TASK'">Задачи в хелпдеск</template>
              <ChevronUpIcon class="accordion-chevron transition-transform"/>
            </button>
          </div>
          <div class="accordion-collapse collapse mt-2">
            <MovementProducts
              v-if="key === 'FF_WAYBILL_ITEMS'"
              :waybills="prop"
              :key="'waybills' + Math.random()"
            />
            <MovementAnomalies
              v-if="key === 'FF_WAYBILL_ANOMALIES'"
              :anomalies="prop"
            />
            <MovementDocuments
              v-if="key === 'FF_WAYBILL_CHILDREN'"
              :documents="prop"
            />
            <HelpdeskTasks
              v-if="key === 'FF_HELPDESK_TASK'"
              :tasks="prop"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
  <div id="disputed_shipment_modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-header p-5">
          <h2 class="font-medium text-base mr-auto">
            Ваши контактные данные
          </h2>
        </div>
        <div class="modal-body p--5">
          <div class="flex flex-col items-center">
            <div class="relative mb-6 w-full">
              <BaseInput
                id="disputed_shipment_input"
                v-model="disputedMovementEmail"
                label="Email"
                placeholder="Введите Email"
                :inputGroup="false"
              />
              <BaseErrorMessage :validationState="v$"/>
            </div>
              <button
                class="btn btn-primary"
                type="button"
                @click="onDisputeMovement"
              >
                Отправить
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { helpers, email, required } from '@vuelidate/validators'
import { notifications as $notifications } from '@/utils/showNotification'
import useVuelidate from '@vuelidate/core'
import MovementAnomalies from '@/components/movement/movement-details/anomalies/Main'
import MovementDocuments from '@/components/movement/movement-details/documents-info/Main'
import MovementProducts from '@/components/movement/movement-details/movement-products/Main'
import HelpdeskTasks from '@/components/movement/movement-details/helpdesk-tasks/Main'
import BaseInput from '@/components/base-input/Main'
import BaseErrorMessage from '@/components/base-error-message/Main'

export default defineComponent({
  name: 'MovementDetails',
  components: {
    BaseErrorMessage,
    BaseInput,
    HelpdeskTasks,
    MovementProducts,
    MovementDocuments,
    MovementAnomalies
  },
  props: {
    id: String
  },
  setup(props) {
    const store = useStore()
    const currentMovement = computed(() => store.getters['movements/getCurrentMovement'])

    const onApplyMovement = () => {
      store.dispatch('movements/applyMovement', { id: props.id })
        .then(res => {
          if (res?.data?.MANAGER_APPLY) {
            console.log('here')
            $notifications.showSuccessMessage('Перемещние подтверждено')
          } else {
            $notifications.showError('Перемещние не было подтверждено')
          }
        })
    }

    const onCancelMovement = () => {
      store.dispatch('movements/cancelMovement', { id: props.id })
        .then(res => {
          if (!res?.data?.result?.success) {
            $notifications.showError(res?.data?.result?.comment || 'Перемещние не было отменено')
          } else {
            $notifications.showSuccessMessage('Перемещние отменено')
          }
        })
    }

    onMounted(() => {
      store.dispatch('movements/loadTheMovement', {
        filter: { ID: props.id }
      })
    })

    // оспорить поставку
    const disputedMovementEmail = ref()

    const validationRules = computed(() => ({
      disputedMovementEmail: {
        required: helpers.withMessage('Обязательное поле', required),
        email: helpers.withMessage('Неверный формат', email)
      }
    }))
    const v$ = useVuelidate(validationRules, { disputedMovementEmail })

    const onDisputeMovement = async () => {
      const result = await v$.value.$validate()
      if (result) {
        await store.dispatch('movements/disputeMovement', { email: disputedMovementEmail.value })
      }
    }

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitle: 'معلومات الحركة',
          movementComment: 'تعليق'
        },
        en: {
          pageTitle: 'Movement info',
          movementComment: 'Comment'
        },
        ru: {
          pageTitle: 'Информация о перемещении',
          movementComment: 'Комментарий'
        }
      }
    })

    return {
      currentMovement,
      onApplyMovement,
      onCancelMovement,
      disputedMovementEmail,
      onDisputeMovement,
      v$,
      text: t
    }
  }
})
</script>
<style lang="scss" scoped>
  .collapsed {
    .accordion-chevron {
      @apply rotate-180
    }
  }
</style>
