import { createI18n } from 'vue-i18n'
import faker from './faker'
import helper from './helper'
import lodash from './lodash'
import exportTable from './exportTable'
import printTable from './printTable'
import notification from './showNotification'
import VueExcelEditor from 'vue3-excel-editor'

const i18n = createI18n({
  legacy: false, // you must set `false`
  mode: 'composition',
  locale: localStorage.getItem('language') || 'ru',
  fallbackLocale: 'en',
  messages: {}
})

export default app => {
  app.use(i18n)
  app.use(faker)
  app.use(helper)
  app.use(lodash)
  app.use(exportTable)
  app.use(printTable)
  app.use(notification)
  app.use(VueExcelEditor)
}
