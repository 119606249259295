const state = () => {
  return {
    orders: null,
    ordersPagination: null,
    currentOrder: null,
    orderLog: null,
    orderSettings: localStorage.getItem('orderSettings') || null
  }
}

// getters
const getters = {
  getOrders: state => state.orders,
  getOrdersPagination: state => state.ordersPagination,
  getCurrentOrder: state => state.currentOrder,
  getOrderLog: state => state.orderLog,
  getOrderSettings: state => {
    try {
      return JSON.parse(state.orderSettings)
    } catch (e) {
      return state.orderSettings
    }
  }
}

// actions
const actions = {
  async loadOrders({ dispatch, commit }, payload) {
    return await dispatch('query/sendRequest', {
      url: 'orders/get',
      data: {},
      filter: payload.filter,
      nav: payload.nav,
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setOrders', res.data.result)
        commit('setOrdersPagination', res.data.nav)
      })
  },
  async loadOrderByID({ dispatch, commit }, payload) {
    return await dispatch('query/sendRequest', {
      url: 'orders/get-by-id/' + payload.id,
      method: 'POST'
    }, { root: true })
      .then(res => commit('setCurrentOrder', res.data))
  },
  async loadOrderLogById({ dispatch, commit }, payload) {
    return await dispatch('query/sendRequest', {
      url: 'orders/get-log/',
      data: payload,
      method: 'POST'
    }, { root: true })
      .then(res => commit('setOrderLog', res.data))
  },
  async loadOrdersSettings({ dispatch, commit }) {
    return await dispatch('query/sendRequest', {
      url: 'orders/get-settings/',
      method: 'POST'
    }, { root: true })
      .then(res => commit('setOrderSettings', res.data))
  },
  async saveOrdersSettings({ dispatch, commit }, payload) {
    return await dispatch('query/sendRequest', {
      url: 'orders/set-settings/',
      data: payload,
      method: 'POST'
    }, { root: true })
      .then(res => {
        if (res.data) commit('setOrderSettings', payload)
      })
  }
}

// mutations
const mutations = {
  setOrders(state, data) {
    const ordersArr = Object.values(data)
    ordersArr.forEach(item => { item.PRODUCTS = JSON.parse(item.PRODUCTS) })

    state.orders = ordersArr
  },
  setOrdersPagination(state, data) {
    state.ordersPagination = data
  },
  setCurrentOrder(state, data) {
    if (typeof data.DELIVERY_DATA === 'string' && data.DELIVERY_DATA) data.DELIVERY_DATA = JSON.parse(data.DELIVERY_DATA)
    if (typeof data.CUSTOMER === 'string' && data.CUSTOMER) data.CUSTOMER = JSON.parse(data.CUSTOMER)

    const productSystemObject = {}
    data.PRODUCTS_SYSTEM.forEach(item => {
      if (typeof item.ATTRIBUTES === 'string' && item.ATTRIBUTES) item.ATTRIBUTES = JSON.parse(item.ATTRIBUTES)
      if (typeof item.PICTURES === 'string' && item.PICTURES) item.PICTURES = JSON.parse(item.PICTURES)
      if (typeof item.PRICE === 'string' && item.PRICE) item.PRICE = JSON.parse(item.PRICE)
      productSystemObject[item.ARTICLE] = item
    })

    data.PRODUCTS_SYSTEM = productSystemObject
    state.currentOrder = data
  },
  setOrderLog(state, data) {
    state.orderLog = data
  },
  setOrderSettings(state, data) {
    const jsonData = (data === false) ? data : JSON.stringify(data)
    localStorage.setItem('orderSettings', jsonData)
    state.orderSettings = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
