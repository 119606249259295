<template>
  <!-- BEGIN: Super Large Slide Over Content -->
  <div id="calculation_feedback_modal" class="modal modal-slide-over" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header p-5">
          <h2 class="font-medium text-base mr-auto">
            Напишите нам об ошибках в работе калькулятора
          </h2>
        </div>
        <div class="modal-body">
          <div class="mb-4 text-base italic"> Мы автоматический соберем данные о произведенном вами расчете, а вы опишите вашу проблему в поле ниже</div>
          <form @submit.prevent="onSubmit">
            <div class="input-form relative">
              <label for="calculation_feedback_comment" class="form-label mb-1 w-full flex flex-col sm:flex-row"> Комментарий </label>
              <textarea v-model="formData.comment"
                        id="calculation_feedback_comment"
                        class="form-control block"
                        placeholder="Опишите свою проблему"></textarea>
              <BaseErrorMessage :validationState="v$" validatedProperty="comment"/>
            </div>
            <div class="md:flex items-center mt-6">
              <button class="btn btn-primary shadow-md md:mr-4"
                      :disabled="sendingData">
                Отправить
                <LoadingIcon v-show="sendingData" icon="ball-triangle" color="white" class="ml-2 w-4 h-4" />
              </button>
              <div>
                <BaseErrorMessage :validationState="v$" validatedProperty="requestData" position="static"/>
                <BaseErrorMessage :validationState="v$" validatedProperty="responseData" position="static"/>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Super Large Slide Over Content -->
  <!-- BEGIN: Modal Content -->
  <SuccessModal id="logistics_feedback_success_modal" title="Успех"
                message="Данные отправлены. Мы обязательно разберемся с вашей проблемой"/>
  <!-- END: Modal Content -->
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import BaseErrorMessage from '@/components/base-error-message/Main'
import SuccessModal from '@/components/success-modal/Main'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'LogisticsFeedbackForm',
  components: { SuccessModal, BaseErrorMessage },
  props: {
    requestData: [Array, Object],
    responseData: [Array, Object]
  },
  setup(props) {
    const route = useRoute()
    const formData = reactive({
      requestData: props.requestData,
      responseData: props.responseData,
      orderID: route.params?.id || ''
    })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          titleText: 'بيانات المسار',
          deliveryAddressText: 'عنوان التسليم',
          sendingMethodText: 'طريقة الإرسال',
          courierOptionText: 'ساع',
          deliveryPointOptionText: 'نقطة التسليم',
          wayToGetText: 'طريقة للحصول على',
          requiredFieldText: 'حقل مطلوب'
        },
        en: {
          titleText: 'Route data',
          deliveryAddressText: 'Delivery address',
          sendingMethodText: 'Sending method',
          courierOptionText: 'Courier',
          deliveryPointOptionText: 'Delivery Point',
          wayToGetText: 'Way to get',
          requiredFieldText: 'Required field'
        },
        ru: {
          titleText: 'Данные маршрута',
          deliveryAddressText: 'Адрес отправки',
          sendingMethodText: 'Способ отправки',
          courierOptionText: 'Курьер',
          deliveryPointOptionText: 'ПВЗ',
          wayToGetText: ' Способ получения',
          requiredFieldText: 'Обязательное поле'
        }
      }
    })

    // валидация
    const validationRules = computed(() => ({
      comment: { required: helpers.withMessage('Поле обязательно для заполнения', required) },
      requestData: { required: helpers.withMessage('Нет входных данных расчета стоимости доставки', required) },
      responseData: { required: helpers.withMessage('Сначала произведите расчет)', required) }
    }))
    const v$ = useVuelidate(validationRules, formData, { $stopPropagation: true })

    // отправка данных
    const store = useStore()
    const sendingData = ref(false)
    const onSubmit = async () => {
      const result = await v$.value.$validate()
      if (result) {
        sendingData.value = true
        await store.dispatch('logistics/sendFeedback', formData)
          .then(res => {
            if (res.data?.success) {
              formData.comment = ''
              cash('#calculation_feedback_modal').modal('hide')
              cash('#logistics_feedback_success_modal').modal('show')
            }
          })
        sendingData.value = false
      }
    }

    return {
      sendingData,
      formData,
      onSubmit,
      v$,
      t
    }
  }
})
</script>

<style scoped>

</style>
