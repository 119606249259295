<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">{{ t('pageTitle') }}</h2>
    <dataList :settings="{
              dataGetterPath: 'stages/getStages',
              loadDataPath: 'stages/loadStages',
              deleteDataItemPath: 'stages/deleteStage',
              createItemRoute: 'stages-add',
              updateItemRoute: 'stages-edit',
              addButtonName: t('addStageBtn'),
              firstNameField: 'NAME',
              secondNameField: 'TYPE',
              deleteConfirmationContent: t('deleteConfirmContent')
            }"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'StagesList',
  setup() {
    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitle: 'مرحلة',
          addStageBtn: 'حرر',
          deleteConfirmContent: 'حذف'
        },
        en: {
          pageTitle: 'Stages',
          addStageBtn: 'Add stage',
          deleteConfirmContent: 'Delete'
        },
        ru: {
          pageTitle: 'Этапы',
          addStageBtn: 'Добавить этап',
          deleteConfirmContent: 'этим этапом'
        }
      }
    })

    return { t }
  }
})
</script>
