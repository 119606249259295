<template>
  <ul v-if="!$_.isEmpty(pagination)" class="pagination">
    <li>
      <router-link :to="{ name: $route.name, params: { page: 1 }, query: $route.query }"
                   @click="$event.target.blur()"
                   class="pagination__link"
                   :disabled="+pagination.currentPage - 1 <= 0">
          <ChevronsLeftIcon class="w-4 h-4"/>
      </router-link>
    </li>
    <li class="hidden sm:inline-flex">
      <router-link :to="{ name: $route.name, params: { page: (+pagination.currentPage - 1) }, query: $route.query }"
                   @click="$event.target.blur()"
                   class="pagination__link"
                   :disabled="+pagination.currentPage - 1 <= 0">
        <ChevronLeftIcon class="w-4 h-4"/>
      </router-link>
    </li>
    <li v-if="+pagination.currentPage - 2 > 0">
      <router-link :to="{ name: $route.name, params: { page: (+pagination.currentPage - 2) }, query: $route.query }"
                   @click="$event.target.blur()"
                   class="pagination__link">
        {{ +pagination.currentPage - 2 }}
      </router-link>
    </li>
    <li v-if="+pagination.currentPage - 1 > 0">
      <router-link :to="{ name: $route.name, params: { page: (+pagination.currentPage - 1) }, query: $route.query }"
                   @click="$event.target.blur()"
                   class="pagination__link">
        {{ +pagination.currentPage - 1 }}
      </router-link>
    </li>
    <li>
      <button class="pagination__link pagination__link--active pagination__link--border"
              @click="$event.target.blur()">{{ +pagination.currentPage }}</button>
    </li>
    <li v-if="+pagination.currentPage + 1 <= pagination.totalPageCount">
      <router-link :to="{ name: $route.name, params: { page: (+pagination.currentPage + 1) }, query: $route.query }"
                   @click="$event.target.blur()"
                   class="pagination__link">
        {{ +pagination.currentPage + 1 }}
      </router-link>
    </li>
    <li v-if="+pagination.currentPage + 2 <= pagination.totalPageCount">
      <router-link :to="{ name: $route.name, params: { page: (+pagination.currentPage + 2) }, query: $route.query }"
                   @click="$event.target.blur()"
                   class="pagination__link">
        {{ +pagination.currentPage + 2 }}
      </router-link>
    </li>
    <li class="hidden sm:inline-flex">
      <router-link :to="{ name: $route.name, params: { page: (+pagination.currentPage + 1) }, query: $route.query }"
                   @click="$event.target.blur()"
                   class="pagination__link"
                   :disabled="+pagination.currentPage + 1 >= pagination.totalPageCount">
        <ChevronRightIcon class="w-4 h-4"/>
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: $route.name, params: { page: pagination.totalPageCount }, query: $route.query }"
                   @click="$event.target.blur()"
                   class="pagination__link mr-0"
                   :disabled="+pagination.currentPage + 1 >= pagination.totalPageCount">
        <ChevronsRightIcon class="w-4 h-4"/>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'BasePagination',
  props: {
    paginationGetterPath: String
  },
  setup(props) {
    // инициализация
    const store = useStore()
    const pagination = computed(() => store.getters[props.paginationGetterPath])

    return {
      pagination
    }
  }
})
</script>
