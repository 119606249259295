import Toastify from 'toastify-js'

const createToastNode = (nodeId, content) => {
  const toast = cash(nodeId)
    .clone()
    .removeClass('hidden')[0]

  cash(toast)
    .find('.toast-text-content')
    .text(content)

  return toast
}

const notifications = {
  showError(text) {
    Toastify({
      node: createToastNode('#error-notification-content', text),
      duration: 10000,
      newWindow: true,
      close: true,
      gravity: 'bottom',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  },
  showSuccessMessage(text) {
    Toastify({
      node: createToastNode('#success-notification-content', text),
      duration: 10000,
      newWindow: true,
      close: true,
      gravity: 'bottom',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }
}

const install = app => {
  app.config.globalProperties.$notification = notifications
}

export { install as default, notifications }
