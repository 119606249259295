const state = () => {
  return {
    loyaltySettings: null
  }
}

// getters
const getters = {
  getLoyaltySettings: state => state.loyaltySettings
}

// actions
const actions = {
  async loadLoyaltySettings({ dispatch, commit }) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-settings/get',
      data: {},
      method: 'POST'
    }, { root: true })
      .then(res => {
        const data = Object.values(res.data.result)
        commit('setLoyaltySettings', data)
      })
  },
  async addLoyaltySettings({ getters, dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-settings/add',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async updateLoyaltySettings({ getters, dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-settings/update',
      data: payload,
      method: 'POST'
    }, { root: true })
  }
}

// mutations
const mutations = {
  setLoyaltySettings(state, data) {
    state.loyaltySettings = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
