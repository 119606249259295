<template>
  <div :id="id" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <CheckCircleIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
            <div class="text-3xl mt-5">{{ title }}</div>
            <div class="text-gray-600 mt-2">
              {{ message }}
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button type="button" data-dismiss="modal" class="btn btn-primary w-24">
              {{ btnContent }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SuccessModal',
  props: {
    id: String,
    title: String,
    message: String,
    btnContent: {
      type: [String, Object],
      default: 'OK'
    }
  }
})
</script>

<style scoped>

</style>
