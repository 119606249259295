const mockLogs = [{ ID: '84390', DATE_END: '1296568800', STATUS: 'success' }, { ID: '84392', DATE_END: '1306933800', STATUS: 'fail' }, { ID: '84393', DATE_END: '1293891600', STATUS: 'success' }]
const mockLog = mockLogs.map(item => JSON.stringify(item))
const mockLogPagination = {
  currentPage: 1,
  totalElementsCount: 3,
  totalPageCount: 1
}
const state = () => {
  return {
    automations: null,
    currentAutomation: null,
    automationLogs: null,
    automationLogsPagination: null,
    currentAutomationLog: null
  }
}

// getters
const getters = {
  getAutomations: state => state.automations,
  getCurrentAutomation: state => state.currentAutomation,
  getAutomationLogs: state => state.automationLogs,
  getAutomationLogsPagination: state => state.automationLogsPagination,
  getCurrentAutomationLog: state => state.currentAutomationLog
}

// actions
const actions = {
  async loadAutomations({ dispatch, commit }) {
    await dispatch('query/sendRequest', {
      url: 'automation/get',
      data: {},
      method: 'POST'
    }, { root: true })
      .then(res => {
        const data = Object.values(res.data.result)
        data.forEach(item => {
          item.SETTINGS = JSON.parse(item.SETTINGS)
        })
        commit('setAutomations', data)
      })
  },
  async loadAutomationById({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'automation/get-by-id/' + payload.id,
      method: 'POST'
    }, { root: true })
      .then(res => {
        res.data.SETTINGS = JSON.parse(res.data.SETTINGS)
        commit('setCurrentAutomation', res.data)
      })
  },
  async addAutomation({ dispatch, commit }, payload) {
    payload.INTEGRATION = payload.INTEGRATION.ID
    await dispatch('query/sendRequest', {
      url: 'automation/add',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async updateAutomation({ dispatch, commit }, payload) {
    payload.INTEGRATION = payload.INTEGRATION.ID
    await dispatch('query/sendRequest', {
      url: 'automation/update',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async deleteAutomation({ dispatch, commit }, data) {
    await dispatch('query/sendRequest', {
      url: 'automation/delete',
      data: data.items,
      method: 'POST'
    }, { root: true }).then(async () => {
      commit('deleteAutomationItem', data.items[0])
    })
  },
  async loadAutomationLogs({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'automation-log/get/',
      data: payload.filter,
      nav: payload.nav,
      method: 'POST'
    }, { root: true }).then(res => {
      if (res.data.error) {
        commit('setAutomationLogs', mockLogs)
        commit('setAutomationLogsPagination', mockLogPagination)
        return
      }
      commit('setAutomationLogs', Object.values(res.data.result))
      commit('setAutomationLogsPagination', res.data.nav)
    })
  },
  async loadAutomationLogById({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'automation-log/get-by-id/' + payload.id,
      method: 'POST'
    }, { root: true })
      .then(res => {
        res.data.DATA = JSON.parse(res.data.DATA)
        commit('setCurrentAutomationLog', Object.values(res.data))
      })
      .catch(() => {
        commit('setCurrentAutomationLog', mockLog)
      })
  }
}

// mutations
const mutations = {
  setAutomations(state, data) {
    state.automations = data
  },
  deleteAutomationItem(state, id) {
    state.automations = state.automations.filter(item => item.ID !== id)
  },
  setCurrentAutomation(state, data) {
    state.currentAutomation = data
  },
  setAutomationLogs(state, data) {
    state.automationLogs = data
  },
  setAutomationLogsPagination(state, data) {
    state.automationLogsPagination = data
  },
  setCurrentAutomationLog(state, data) {
    state.currentAutomationLog = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
