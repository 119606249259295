<template>
  <div v-if="!stagesLoading">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ t('pageTitle') }}</h2>
      <ItemsAmountShown v-if="!tableLoading" paginationGetterPath="stock/getStockPagination"/>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2">{{ t('RefreshStockListBtn') }}</button>
      </div>
    </div>
    <div class="intro-y box px-2 py-3 mt-5">
      <BaseFiltration
        v-if="!tableLoading"
        mainFiltersName="STAGE"
        :tabsTitle="t('tableTabsTitle')"
        :mainFiltersParams="stages"
        :filtersParams="[
          { prop: 'PRODUCT.%NAME', name: t('filterPropProductName') },
          { prop: 'PRODUCT.%ARTICLE', name: t('filterPropProductArticle') },
        ]"
      >
      </BaseFiltration>

      <DataTable
        v-if="$route.query.STAGE"
        requiredFilter="STAGE"
        dataGetterPath="stock/getStock"
        actionDataPath="stock/loadStock"
        @loading="tableLoading = $event"
      >
        <template v-slot:tableHead>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('tableCellProducts') }}
          </th>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('tableCellStockAmount') }}
          </th>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('tableCellMinStock') }}
          </th>
        </template>
        <template v-slot:tableBody="tableBody">
          <td class="border-b whitespace-nowrap">
            <router-link
              tag="a"
              :to="{ name: 'product-detail', params: { id: tableBody.index } }"
              class="inline-block"
            >
              {{ tableBody.item.PRODUCT?.NAME }}
            </router-link>
          </td>
          <td class="border-b whitespace-nowrap">{{ tableBody.item.COUNT }}</td>
          <td class="border-b whitespace-nowrap">
            <input
              class="form-control"
              type="number"
              placeholder="Введите число"
              :value="tableBody.item.MIN_COUNT"
              @blur="onUpdateMinStock($event, tableBody.item)"
            />
          </td>
        </template>
      </DataTable>

      <!-- BEGIN: Pagination -->
      <div
        v-if="$route.query.STAGE && !tableLoading"
        class="col-span-12 flex justify-between sm:flex-row sm:flex-nowrap items-center px-1 mt-6"
      >
        <BasePagination paginationGetterPath="stock/getStockPagination"/>
        <div class="mt-5 sm:mt-0 mr-auto lg:flex hidden" id="forOutputTableTeleport"/>
        <SelectItemsPerPage/>
      </div>
      <!-- END: Pagination -->
      <div v-if="$route.query.STAGE && !tableLoading" class="accordion accordion-boxed mt-4 px-1">
        <div class="accordion-item rounded-md">
          <div class="accordion-header">
            <button class="accordion-button collapsed" aria-expanded="false">Действия для всех товаров</button>
          </div>
          <div class="accordion-collapse collapse">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="mb-3 text-theme-11">
                Данные действия будут применены для всех выбранных по фильтрам товаров на этапе
                <span class="text-primary-3">'{{ currentStage }}'</span>,
                в том числе и на других страницах
              </div>
              <div class="flex items-center bg-gray-200 p-2 rounded-md">
                <span class="mr-5">
                  Установить единый минимальный остаток в количестве
                </span>
                <input
                  class="form-control mr-3 w-36 h-10"
                  type="number"
                  placeholder="Введите число"
                  v-model="commonMinStock"
                />
                <span class="mr-5"> шт.</span>
                <button class="btn btn-primary ml-auto" @click="onMultiUpdateMinStock">Сохранить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import BaseFiltration from '@/components/base-filtration/Main'
import BasePagination from '@/global-components/base-pagination/Main'
import DataTable from '@/components/data-table/Main'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'StockList',
  components: { BasePagination, BaseFiltration, DataTable },
  setup() {
    const route = useRoute()
    const store = useStore()
    const tableLoading = ref()

    // Данные о стадиях
    const stages = useStoreDataGetting(
      'stages/getStages',
      'stages/loadStages')
    const currentStage = computed(() => {
      return stages.data.value?.find(item => item.ID === route.query.STAGE).NAME
    })

    // обновление минимальных остатков
    const onUpdateMinStock = (event, stockItem) => {
      if (event.target.value !== stockItem.MIN_COUNT) {
        store.dispatch('stock/updateMinStock', {
          ...stockItem,
          MIN_COUNT: event.target.value,
          PRODUCT: stockItem.PRODUCT.ID,
          STAGE: stockItem.STAGE.ID
        })
      }
    }

    const commonMinStock = ref()
    const onMultiUpdateMinStock = event => {
      store.dispatch('stock/multiUpdateMinStock', {
        MIN_COUNT: event.target.value,
        filter: route.query
      })
    }

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitle: 'سهم',
          RefreshStockListBtn: 'تحديث قائمة الأسهم',
          tableTabsTitle: 'حدد مرحلة',
          filterPropProductName: 'اسم المنتج',
          filterPropProductArticle: 'مقالة المنتج',
          tableCellProducts: 'منتجات',
          tableCellStage: 'مرحلة',
          tableCellStockAmount: 'مبلغ',
          tableCellMinStock: 'الحد الأدنى للمبلغ',
          stockQuotasListLinkText: 'الحد الأدنى لحصة المنتج'
        },
        en: {
          pageTitle: 'Stock',
          RefreshStockListBtn: 'Refresh stock list',
          tableTabsTitle: 'Select a stage',
          filterPropProductName: 'Product name',
          filterPropProductArticle: 'Product article',
          tableCellProducts: 'Products',
          tableCellStage: 'Stage',
          tableCellStockAmount: 'Amount',
          tableCellMinStock: 'Min amount',
          stockQuotasListLinkText: 'Minimum product quota list'
        },
        ru: {
          pageTitle: 'Остатки',
          RefreshStockListBtn: 'Обновить список остатков',
          tableTabsTitle: 'Выберите этап',
          filterPropProductName: 'Название продукта',
          filterPropProductArticle: 'Артикул продукта',
          tableCellProducts: 'Товары',
          tableCellStage: 'Этап',
          tableCellStockAmount: 'Количество',
          tableCellMinStock: 'Минимальное количество',
          stockQuotasListLinkText: 'Список минимальных запасов товаров'
        }
      }
    })

    return {
      stages: stages.data,
      stagesLoading: stages.loading,
      currentStage,
      tableLoading,
      onUpdateMinStock,
      onMultiUpdateMinStock,
      commonMinStock,
      t
    }
  }
})
</script>
