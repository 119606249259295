const state = () => {
  return {
    stock: null,
    stockPagination: null
  }
}

// getters
const getters = {
  getStock: state => state.stock,
  getStockPagination: state => state.stockPagination
}

// actions
const actions = {
  async loadStock({ getters, dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'stock/get',
      data: {},
      filter: payload.filter,
      nav: payload.nav,
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setStock', Object.values(res.data.result))
        commit('setStockPagination', res.data.nav)
      })
  },
  async updateMinStock({ getters, dispatch, commit }, payload) {
    console.log(payload)
    // await dispatch('query/sendRequest', {
    //   url: 'stock/update',
    //   data: payload,
    //   method: 'POST'
    // }, { root: true })
  },
  async multiUpdateMinStock({ getters, dispatch, commit }, payload) {
    console.log(payload)
    // await dispatch('query/sendRequest', {
    //   url: 'stock/update-multi',
    //   data: payload,
    //   method: 'POST'
    // }, { root: true })
  }
}

// mutations
const mutations = {
  setStock(state, data) {
    state.stock = data
  },
  setStockPagination(state, data) {
    state.stockPagination = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
