<template>
  <div v-if="!$_.isEmpty(pagination)" class="hidden md:block mx-auto text-gray-600">
        <span v-if="+pagination.currentPage === 1">
          {{ t('shown') }} {{ t('from') }} 1 {{ t('to') }} {{ $route.params.pageSize }} {{ t('of') }} {{ pagination.totalElementsCount }} {{ t('elements') }}
        </span>
    <span v-else>
          {{ t('shown') }} {{ t('from') }}
          {{ currentFirstElement }} {{ t('to') }}
          {{ currentLastElement }} {{ t('of') }}
          {{ pagination.totalElementsCount }} {{ t('elements') }}
        </span>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ItemsAmountShown',
  props: {
    paginationGetterPath: String,
    pageInfo: Object
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const pagination = computed(() => store.getters[props.paginationGetterPath])
    const currentFirstElement = computed(() => ((+pagination.value.currentPage - 1) * +route.params.pageSize) + 1)
    const currentLastElement = computed(() => ((+pagination.value.currentPage - 1) * +route.params.pageSize) + +route.params.pageSize)

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          shown: 'مبين',
          from: 'من',
          to: 'ل',
          of: 'من',
          elements: 'عناصر'
        },
        en: {
          shown: 'Shown',
          from: 'from',
          to: 'to',
          of: 'of',
          elements: 'elements'
        },
        ru: {
          shown: 'Показано',
          from: 'c',
          to: 'по',
          of: 'из',
          elements: 'элементов'
        }
      }
    })

    return {
      pagination,
      currentFirstElement,
      currentLastElement,
      t
    }
  }
})
</script>

<style scoped>

</style>
