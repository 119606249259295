<template>
  <div class="flex relative mt-4">
    <button
      v-if="!phoneErrorMessage"
      class="btn btn-primary mr-4"
      :disabled="state.phoneCheckingBtnDisabled"
      @click="onCheckPhone"
    >
      <template v-if="phoneChecked && state.showTimer">
        Повторить через {{ state.time }} с.
      </template>
      <template v-else> Выслать код </template>
      <LoadingIcon
        v-if="state.phoneChecking"
        icon="ball-triangle"
        color="white"
        class="'w-4 h-4 ml-1"
      />
    </button>
    <div v-show="phoneChecked" class="input-group relative items-center theme">
      <label class="input-group-text whitespace-nowrap"> код </label>
      <CheckIcon
        v-if="String(state.code)?.length === 4 && state.codeIsCorrect"
        stroke="green"
        stroke-width="4"
        class="absolute right-1 z-20"
      />
      <XIcon
        v-if="state.codeIsCorrect !== null && !state.codeIsCorrect"
        stroke="red"
        stroke-width="4"
        class="absolute right-1 z-20"
      />
      <LoadingIcon
        v-if="state.codeChecking"
        icon="oval"
        color="#1C3FAA"
        class="'w-4 h-4 block absolute right-1 z-20"
      />
      <input
        id="verification_code"
        :value="state.code"
        :disabled="verified"
        type="text"
        class="form-control pr-4 without-arrows"
        placeholder="4 цифры"
        aria-describedby="verification_code"
        @input="onInputCode"
      />
    </div>
    <BaseErrorMessage :validationState="v$" validatedProperty="verified" />
  </div>
</template>

<script>
import { computed, defineComponent, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import BaseErrorMessage from '@/components/base-error-message/Main'

export default defineComponent({
  name: 'PhoneVerification',
  components: { BaseErrorMessage },
  props: {
    phoneNumber: [Number, String]
  },
  setup(props) {
    let onTimer
    const store = useStore()
    const phoneChecked = computed(() => store.getters['clients/getPhoneStatus'])
    const phoneErrorMessage = computed(
      () => store.getters['clients/getPhoneErrorMessage']
    )
    const verified = computed(
      () => store.getters['clients/getVerificationStatus']
    )
    const state = reactive({
      code: '',
      codeIsCorrect: null,
      phoneCheckingBtnDisabled: false,
      showTimer: false,
      phoneChecking: false,
      codeChecking: false,
      time: 60
    })
    const validationRules = {
      verified: {
        required: helpers.withMessage(
          'Верификация не пройдена',
          () => verified.value
        )
      }
    }
    const v$ = useVuelidate(validationRules, { verified })

    const tick = () => {
      state.time--
      if (state.time === 0) {
        clearTimer(onTimer)
        state.phoneCheckingBtnDisabled = false
        state.showTimer = false
      }
    }

    const clearTimer = timer => {
      clearInterval(timer)
      state.time = 4
    }

    const showTimer = () => {
      state.showTimer = true
      state.phoneCheckingBtnDisabled = true
      onTimer = setInterval(tick, 1000)
    }

    const onInputCode = e => {
      e.target.value = e.target.value.replace(/\D+/g, '')
      state.code = e.target.value
    }

    const onCheckPhone = () => {
      state.phoneChecking = true
      state.codeIsCorrect = null
      state.code = ''
      store.commit('clients/setVerificationStatus', false)

      store
        .dispatch('clients/checkPhone', {
          phone: '+7' + props.phoneNumber
        })
        .finally(() => {
          state.phoneChecking = false
        })
    }

    const onSendCode = val => {
      state.codeChecking = true
      store
        .dispatch('clients/checkCode', {
          phone: '+7' + props.phoneNumber,
          code: val
        })
        .finally(() => {
          state.codeIsCorrect = verified.value
          state.codeChecking = false
        })
    }

    watch(
      () => phoneChecked.value,
      () => {
        if (phoneChecked.value) showTimer()
      }
    )

    watch(
      () => state.code,
      val => {
        if (String(val)?.length === 4) onSendCode(val)
      }
    )

    return {
      state,
      phoneChecked,
      phoneErrorMessage,
      verified,
      onCheckPhone,
      onInputCode,
      v$
    }
  }
})
</script>

<style scoped>
.feather {
  stroke-width: 3;
}
.without-arrows::-webkit-outer-spin-button,
.without-arrows::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
</style>
