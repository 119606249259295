<template>
  <div class="input-group sm:mt-0 relative">
    <label class="input-group-text md:w-40 flex-shrink-0" :for="id"> {{ labelText }}</label>
    <div class="location-dropdown absolute top-full left-0 flex w-full z-10">
      <Transition name="fade">
        <div class="w-full bg-white border rounded-b-sm"
             v-show="dropdownIsOpened && !$_.isEmpty(addressSuggestions)">
          <div class="hover:bg-theme-2 cursor-pointer px-3 py-1 dark:border-dark-5 dark:bg-dark-5 dark:hover:bg-dark-6"
               v-for="address in addressSuggestions"
               :key="address.value"
               @click="setCurrentAddress(address)"
               tabindex="1">
            {{ address.value }}
          </div>
        </div>
      </Transition>
    </div>
    <BaseErrorMessage :validationState="v$"/>
    <input class="form-control rounded-r-sm"
           type="text"
           :id="id"
           :name="name"
           :placeholder="placeholder"
           :disabled="disabled"
           @blur="dropdownIsOpened = false"
           @focus="dropdownIsOpened = true"
           @input="updateSuggestions"
           v-model="addressValue">
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { helpers } from '@vuelidate/validators'
import { helper } from '@/utils/helper'
import _ from 'lodash'
import useVuelidate from '@vuelidate/core'
import BaseErrorMessage from '@/components/base-error-message/Main.vue'

export default {
  name: 'AddressInput',
  components: { BaseErrorMessage },
  props: {
    modelValue: Object,
    preferredAddress: Array,
    name: String,
    id: String,
    disabled: String,
    placeholder: String,
    labelText: String
  },
  setup(props, { emit }) {
    const addressValue = ref('')
    const dropdownIsOpened = ref(false)
    const addressIsSelected = ref(false)
    const addressSuggestions = ref([])
    const setCurrentAddress = (addressItem) => {
      if (_.isEmpty(addressItem)) return
      addressIsSelected.value = true
      addressValue.value = addressItem.value
      emit('update:modelValue', addressItem)
    }

    const updateSuggestions = (event) => {
      addressIsSelected.value = false
      helper.loadAddressSuggestions({ query: event.target.value })
        .then(res => { addressSuggestions.value = res.data.suggestions })
    }

    watch(() => props.preferredAddress, (val, oldVal) => {
      if (_.isEqual(val, oldVal)) return
      if (_.isEmpty(val)) return

      val.forEach((addressItem, index) => {
        addressSuggestions.value.push(addressItem)
        if (!index) setCurrentAddress(addressItem)
      })
    }, { immediate: true })

    // валидация
    const validationRules = computed(() => ({
      exactAddress: helpers.withMessage(
        'Выберите из выпадающего списка адрес с точностью до дома',
        () => props.modelValue?.data?.house && addressIsSelected.value)
    }))
    const v$ = useVuelidate(validationRules, addressValue)

    return {
      addressValue,
      addressSuggestions,
      dropdownIsOpened,
      addressIsSelected,
      updateSuggestions,
      setCurrentAddress,
      v$
    }
  }
}
// const addressArray = val.filter(item => !_.isEmpty(item))
// if (addressArray?.length) {
//   Promise.all(addressArray.map(address => {
//     return loadAddressSuggestions({ query: address }).then(res => {
//       if (_.isEmpty(res.data.suggestions)) {
//         return {
//           addressError: true,
//           addressValue: address
//         }
//       }
//       return res.data.suggestions[0]
//     })
//   })).then(res => {
//     res.forEach(item => {
//       if (item?.addressError) return $notifications.showError(`Адрес '${item.addressValue}' не найден`)
//       addressSuggestions.value.push(item)
//     })
//     setCurrentAddress(addressSuggestions.value[0])
//   })
</script>
