<template>
  <div
    :class="[
      'relative',
      { 'input-group mt-2': inputGroup },
      { 'input-form  mt-4': !inputGroup }
    ]"
  >
    <template v-if="inputGroup">
      <label v-if="label" :for="id" class="input-group-text whitespace-nowrap">
        {{ label }}
      </label>
      <input
        :id="id"
        v-model="value"
        :type="type"
        class="form-control"
        :placeholder="placeholder"
        :aria-describedby="id"
        :dir="direction"
      />
    </template>

    <template v-if="!inputGroup">
      <label
        :for="id"
        class="form-label mb-1 w-full flex flex-col sm:flex-row"
        >{{ label }}</label
      >
      <input
        :id="id"
        v-model="value"
        :type="type"
        class="form-control"
        :placeholder="placeholder"
        :aria-describedby="id"
        :dir="direction"
        @input="$emit($event)"
      />
    </template>
    <BaseErrorMessage v-if="required" :validationState="v$" />
    <slot></slot>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import BaseErrorMessage from '@/components/base-error-message/Main.vue'
export default defineComponent({
  name: 'BaseInput',
  components: { BaseErrorMessage },
  props: {
    modelValue: [Number, String],
    type: {
      type: String,
      default: 'Text'
    },
    id: String,
    label: String,
    placeholder: String,
    autocomplete: {
      type: String,
      default: 'off'
    },
    inputGroup: {
      type: Boolean,
      default: true
    },
    direction: String,
    required: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: val => {
        emit('update:modelValue', val)
      }
    })
    let v$

    if (props.required) {
      const validationRules = computed(() => ({
        required: helpers.withMessage('Обязательное поле', required)
      }))
      v$ = useVuelidate(validationRules, value)
    }

    return {
      value,
      v$
    }
  }
})
</script>

<style scoped lang="scss">
.input-group,
.input-form {
  &:first-child {
    @apply mt-0;
  }
}
</style>
