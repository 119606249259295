<template>
  <td class="text-left border-b dark:border-dark-5 w-32 font-medium"></td>
  <td class="text-right border-b dark:border-dark-5 w-32 font-medium">
    <div class="flex justify-end" v-if="item?.is_successful">
      <div class="text-gray-600 whitespace-nowrap mr-2 ml-2" >
        Дней: 1
      </div>|
      <div class="ml-0.5 w-20">
        {{ Math.round(item?.order.payment_amount) }}&nbsp;руб.
      </div>
    </div>
    <CalculationDataError v-else/>
  </td>
</template>

<script>
import { defineComponent } from 'vue'
import CalculationDataError
from '@/components/logistics-price-calculation-form/calculation-result-items/calculation-data-error/Main'

export default defineComponent({
  name: 'DostavistaCalculation',
  components: { CalculationDataError },
  props: {
    item: Object
  }
})
</script>

<style scoped>

</style>
