<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">{{ t('pageTitle') }}</h2>
    <dataList :settings="{
              dataGetterPath:'integrations/getIntegrations',
              loadDataPath: 'integrations/loadIntegrations',
              deleteDataItemPath: 'integrations/deleteIntegration',
              createItemRoute: 'integrations-add',
              updateItemRoute: 'integrations-edit',
              addButtonName: t('addIntegrationBtn'),
              firstNameField: 'NAME',
              secondNameField: 'TYPE',
              deleteConfirmationContent: t('deleteConfirmContent')
            }"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'IntegrationsList',
  setup() {
    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitle: 'عمليات التكامل المتصلة',
          addIntegrationBtn: 'أضف التكامل',
          deleteConfirmContent: 'هذا التكامل'
        },
        en: {
          pageTitle: 'Connected integrations',
          addIntegrationBtn: 'Add integration',
          deleteConfirmContent: 'this integration'
        },
        ru: {
          pageTitle: 'Подключенные интеграции',
          addIntegrationBtn: 'Добавить интеграцию',
          deleteConfirmContent: 'этой интеграцией'
        }
      }
    })

    return { t }
  }
})
</script>
