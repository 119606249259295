<template>
  <div class="flex flex-col items-center">
    <h2 class="font-medium text-center text-lg mb-4">
      {{ essenceName }} {{ t('message') }}
    </h2>
    <button class="btn btn-primary"
            @click="$router.back()"> {{ t('linkText') }}</button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'IdNotFound',
  props: {
    essenceName: String
  },
  setup() {
    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          message: 'التي تحمل بطاقة الهوية هذه غير موجودة',
          linkText: 'سنوات'
        },
        en: {
          message: 'with this ID do not exist',
          linkText: 'Back'
        },
        ru: {
          message: 'с таким ID не существует',
          linkText: 'Вернуться'
        }
      }
    })

    return { t }
  }
})
</script>

<style scoped>

</style>
