<template>
  <div class="intro-y flex flex-col sm:flex-row items-center my-8">
    <h2 class="text-lg font-medium mr-auto">Перемещение остатка</h2>
  </div>

  <form class="intro-y box" @submit.prevent="submitData">
    <div v-if="!stagesLoading" class="intro-y p-3 rounded-md mb-4">
      <div class="grid grid-cols-2 gap-x-4 gap-y-6 mb-4">
        <div class="select-block input-group relative">
          <div class="input-group-text w-48 flex-shrink-0">
            Этап (откуда отправить)
          </div>
          <select :class="['form-select w-full border-l-0 rounded-l-none cursor-pointer', { loading: stockLoading }]"
            v-model="formData.STAGE_FROM">
            <option
              v-for="stage in stages"
              :key="stage.ID"
              :value="stage"
            >
              {{ stage.NAME }}
            </option>
          </select>
          <LoadingIcon icon="oval"
                       v-if="stockLoading"
                       class="absolute right-2.5 top-2 w-5 h-5"/>
        </div>
        <div v-if="!$_.isEmpty(formData.STAGE_FROM) && !$_.isEmpty(stock) || formData.STAGE_FROM.TYPE === 'PROV'" class="select-block input-group relative">
          <div class="input-group-text w-48 flex-shrink-0">
            Этап (Куда отправить)
          </div>
          <select class="form-select w-full border-l-0 rounded-l-none cursor-pointer"
                  v-model="formData.STAGE_TO">
            <option
              v-for="stage in stages"
              :key="stage.ID"
              :value="stage"
            >
              {{ stage.NAME }}
            </option>
          </select>
        </div>
        <div v-if="!$_.isEmpty(formData.STAGE_TO) && !$_.isEmpty(stock) || formData.STAGE_FROM.TYPE === 'PROV'" class="select-block input-group relative col-span-2">
          <div class="z-30 rounded-l w-24 flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4 -mr-1">
            Продукт
          </div>
          <TomSelect class="w-full"
                     v-model="selectedProduct"
                     :options="{
                       placeholder: 'Введите название товара',
                       maxItems: 1,
                       valueField: 'ID',
                       labelField: 'productName',
                       searchField: 'productName',
                       options: stock,
                     }">
          </TomSelect>
        </div>
        <div class="col-span-2" v-if="!$_.isEmpty(formData.STAGE_FROM)">
          <label class="form-label" for="new_movement_comment">Комментарий</label>
          <textarea
            id="new_movement_comment"
            v-model="formData.comment"
            class="form-control"
            placeholder="Введите текст"
        ></textarea>
        </div>
        <div  v-if="!$_.isEmpty(formData.STAGE_FROM)">
          <label class="form-label" for="new_movement_id">Внешний ID (необязательно)</label>
          <input
            id="new_movement_id"
            v-model="formData.id"
            class="form-control"
            placeholder="Введите текст"
          >
        </div>
        <div v-if="!$_.isEmpty(formData.STAGE_FROM)">
          <label
            for="crud-form-1"
            class="form-label"
          >
            Планируемая дата поставки
          </label>
          <Litepicker v-model="formData.DATE"
                      :options=" {
                          autoApply: true,
                          format: 'DD.MM.YYYY HH:mm',
                          lang: 'ru',
                          singleMode: true,
                          setInitValue: false,
                          dropdowns: {
                            minYear: 2023,
                            months: true,
                            years: true,
                          }
                        }"
                      id="crud-form-1"
                      class="form-control w-full"/>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden" v-if="!$_.isEmpty(formData.PRODUCTS)" >
        <table class="table p-1">
          <thead>
          <tr class="bg-gray-200 dark:bg-dark-1">
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Товар
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Этап
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              ID
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Остаток (шт.)
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Переместить (шт.)
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              Действия
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in formData.PRODUCTS"
              :key="item.ID"
              :class="{ 'bg-gray-200 dark:bg-dark-1': index % 2 === 1 }">
            <td class="border-b whitespace-nowrap">{{ item.PRODUCT.NAME }}</td>
            <td class="border-b whitespace-nowrap">{{ item.STAGE.NAME }}</td>
            <td class="border-b whitespace-nowrap">{{ item.ID }}</td>
            <td class="border-b whitespace-nowrap">{{ item.COUNT }}</td>
            <td class="border-b whitespace-nowrap movement-count">
              <MovementProductsCount v-model="item.MOVEMENT_COUNT" :balance="item.COUNT"/>
            </td>
            <td class="border-b whitespace-nowrap">
              <button class="flex items-center text-theme-6"
                @click="onDeleteProduct(item.ID)">
                <Trash2Icon class="w-4 h-4 mr-1" /> Удалить
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="w-full sm:w-auto flex mt-6 sm:mt-6 items-center">
        <button class="btn btn-primary shadow-md mr-4">
          Подтвердить перемещение
          <LoadingIcon v-if="movementSending"
                       icon="ball-triangle"
                       color="white"
                       class="'w-4 h-4 ml-1.5"/>
        </button>
        <BaseErrorMessage :validationState="v$" validatedProperty="stagesAreSame" position="relative"/>
      </div>
    </div>
    <IntroPreloader v-else/>
  </form>
</template>

<script>
import { defineComponent, ref, reactive, watch, computed } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import { useStore } from 'vuex'
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import BaseErrorMessage from '@/components/base-error-message/Main'
import MovementProductsCount from '@/components/movement/movement-products-count/Main'

export default defineComponent({
  name: 'MovementAdding',
  components: { MovementProductsCount, BaseErrorMessage },
  setup() {
    const stages = useStoreDataGetting(
      'stages/getStages',
      'stages/loadStages')
    const store = useStore()
    const stockLoading = ref(false)
    const movementSending = ref(false)
    const stock = computed(() => {
      const pureStoke = store.getters['stock/getStock']
      if (pureStoke === null) return
      return pureStoke.map(item => {
        item.productName = item?.PRODUCT?.NAME
        return item
      })
    })

    const selectedProduct = ref('')
    const formData = reactive({
      STAGE_FROM: {},
      STAGE_TO: {},
      PRODUCTS: []
    })

    const onDeleteProduct = (id) => {
      formData.PRODUCTS = formData.PRODUCTS.filter(item => item.ID !== id)
    }

    watch(selectedProduct, (val) => {
      const selectedItem = stock.value.filter(item => item.ID === val)[0]
      formData.PRODUCTS.push(selectedItem)
    })

    watch(() => formData.STAGE_FROM, (val) => {
      const stockSource = val.TYPE === 'PROV' ? 'products/loadProducts' : 'stock/loadStock'

      formData.PRODUCTS = []
      stockLoading.value = true
      store.dispatch(stockSource,
        {
          nav: { page: '1', pageSize: '1000' },
          filter: { STAGE: val }
        })
        .then(() => {
          stockLoading.value = false
        })
    })

    // валидация
    const validationRules = computed(() => ({
      PRODUCTS: {
        required: helpers.withMessage('Выберете хотя бы один продукт', () => !!formData.PRODUCTS.length)
      },
      STAGE_FROM: {
        required: helpers.withMessage('Необходимо выбрать этап', required)
      },
      STAGE_TO: {
        required: helpers.withMessage('Необходимо выбрать этап', required)
      },
      stagesAreSame: {
        required: helpers.withMessage('Этапы не должны совпадать', () => formData.STAGE_FROM !== formData.STAGE_TO)
      },
      comment: {
        required: helpers.withMessage('Обязательное поле', required)
      }
    }))

    const v$ = useVuelidate(validationRules, formData)

    const submitData = async () => {
      const result = await v$.value.$validate()
      if (result) {
        movementSending.value = true
        store.dispatch('movements/moveStock', {
          stageFrom: formData.STAGE_FROM.ID,
          stageTo: formData.STAGE_TO.ID,
          items: formData.PRODUCTS,
          comment: formData.comment,
          id: formData.id
        }).then(() => {
          movementSending.value = false
        })
      }
    }

    return {
      selectedProduct,
      stockLoading,
      movementSending,
      stages: stages.data,
      stagesLoading: stages.loading,
      stock,
      formData,
      onDeleteProduct,
      submitData,
      v$
    }
  }
})
</script>

<style scoped lang="scss">
  .form-select.loading {
    background-image: none;
  }

  .table td {
    &.movement-count {
      @apply pb-5
    }
  }
</style>
