<template>
  <div v-bind="$attrs" class="intro-y box p-4 mt-4">
    <BaseInput id="profile_name"
               v-model="formData.name"
               :label="t('profileInputLabel')"
               :placeholder="t('profileInputPlaceholder')"
               :inputGroup="false">
      <BaseErrorMessage :validationState="v$" validatedProperty="name"/>
    </BaseInput>
    <BaseInput id="profile_phone"
               v-model="formData.phone"
               :label="t('phoneInputLabel')"
               :placeholder="t('phoneInputPlaceholder')"
               :inputGroup="false">
      <BaseErrorMessage :validationState="v$" validatedProperty="phone"/>
    </BaseInput>
    <BaseInput id="profile_password"
               v-model="formData.password"
               :type="state.passwordInputType"
               :label="t('passwordLabel')"
               :placeholder="t('passwordPlaceholder')"
               :inputGroup="false">
      <Tippy :key="state.passwordInputType + 'TippyMessage'" tag="button"
             href="javascript:;"
             class="tooltip absolute top-8 right-0"
             :content="state.passwordInputType === 'text' ? 'Скрыть пароль' : 'Показать пароль'"
             @click="onShowPassword('passwordInputType')">
        <EyeIcon v-if="state.passwordInputType === 'password'"
                 class="w-4 h-4 mr-2"/>
        <EyeOffIcon v-if="state.passwordInputType === 'text'"
                    class="w-4 h-4 mr-2"/>
      </Tippy>
      <BaseErrorMessage :validationState="v$" validatedProperty="confirmPassword"/>
    </BaseInput>
    <BaseInput id="profile_confirm_password"
               v-model="formData.confirmPassword"
               :type="state.confirmPasswordInputType"
               :label="t('confirmPasswordLabel')"
               :placeholder="t('confirmPasswordPlaceholder')"
               :inputGroup="false">
      <Tippy :key="state.confirmPasswordInputType + 'TippyMessage'" tag="button"
             href="javascript:;"
             class="tooltip absolute top-8 right-0"
             :content="state.confirmPasswordInputType === 'text' ? 'Скрыть пароль' : 'Показать пароль'"
             @click="onShowPassword('confirmPasswordInputType')">
        <EyeIcon v-if="state.confirmPasswordInputType === 'password'"
                 class="w-4 h-4 mr-2"/>
        <EyeOffIcon v-if="state.confirmPasswordInputType === 'text'"
                    class="w-4 h-4 mr-2"/>
      </Tippy>
      <BaseErrorMessage :validationState="v$" validatedProperty="confirmPassword"/>
    </BaseInput>
    <button
      class="btn btn-primary mt-6"
      @click="onValidateForm"
    >
      {{ t('saveChanges') }}
    </button>
  </div>

  <div
    id="save_profile_settings_modal"
    class="modal rtl:right-auto rtl:left-0"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <AlertCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">Данные вашего профиля будут изменены!</div>
            <div class="text-gray-600 mt-2">
              Вы уверены, что хотите сохранить изменения?
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              class="btn btn-primary w-24 mr-2"
              data-dismiss="modal"
              @click="submitData"
            >
              <LoadingIcon v-show="state.dataSending" icon="ball-triangle" class="ml-4 w-8 h-8"/>
              {{ t('confirmBtn') }}
            </button>
            <a
              type="button"
              class="btn w-24"
              data-dismiss="modal"
              href="javascript:;"
              data-target="#superlarge-slide-over-size-preview"
            >
              {{ t('cancelBtn') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Delete Confirmation Modal -->
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { helpers, minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import BaseInput from '@/components/base-input/Main'
import BaseErrorMessage from '@/components/base-error-message/Main'

export default defineComponent({
  name: 'EditProfile',
  components: { BaseErrorMessage, BaseInput },
  props: {
    user: Object
  },
  setup(props) {
    const store = useStore()
    const state = reactive({
      passwordInputType: 'password',
      confirmPasswordInputType: 'password',
      dataSending: false
    })

    const formData = reactive({
      name: props.user.name,
      phone: props.user.phone,
      password: '',
      confirmPassword: ''
    })

    // валидация
    const validationRules = {
      name: { required: helpers.withMessage('Поле не должно быть пустым', required) },
      confirmPassword: {
        isEqual: helpers.withMessage('Значения не совпадают', () => formData.password === formData.confirmPassword)
      },
      phone: {
        required: helpers.withMessage('Обязательное поле', required),
        minlength: helpers.withMessage('Минимальная длинна номера 11 символов', minLength(11))
      }
    }
    const v$ = useVuelidate(validationRules, formData)
    const onValidateForm = async () => {
      const result = await v$.value.$validate()

      console.log('here')
      if (result) {
        cash('#save_profile_settings_modal').modal('show')
      }
    }

    const onShowPassword = (input) => {
      state[input] === 'password'
        ? state[input] = 'text'
        : state[input] = 'password'
    }

    // Сохранение данных
    const submitData = async () => {
      const payload = {
        ID: props.user.id,
        NAME: formData.name,
        PHONE: formData.phone,
        PASSWORD: formData.password ? formData.password : '',
        LOGIN: props.user.login
      }

      state.dataSending = true
      await store.dispatch('user/updateProfile', payload)
      state.dataSending = false
      cash('#save_profile_settings_modal').modal('hide')
    }

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          profileInputLabel: 'اسم ملفك الشخصي',
          profileInputPlaceholder: 'أدخل اسم الملف الشخصي',
          phoneInputLabel: 'رقم هاتف',
          phoneInputPlaceholder: 'أدخل رقم',
          passwordLabel: 'كلمة مرور',
          passwordPlaceholder: 'أدخل كلمة المرور',
          confirmPasswordLabel: 'تأكيد كلمة السر',
          confirmPasswordPlaceholder: 'أدخل كلمة المرور',
          saveChanges: 'نقذ',
          cancelBtn: 'إلغاء',
          confirmBtn: 'نقذ'
        },
        en: {
          profileInputLabel: 'Profile name',
          profileInputPlaceholder: 'Enter profile name',
          phoneInputLabel: 'Phone number',
          phoneInputPlaceholder: 'Enter number',
          passwordLabel: 'Password',
          passwordPlaceholder: 'Enter Password',
          confirmPasswordLabel: 'Confirm password',
          confirmPasswordPlaceholder: 'Enter Password',
          saveChanges: 'Save',
          cancelBtn: 'Cancel',
          confirmBtn: 'Save'
        },
        ru: {
          profileInputLabel: 'Имя профиля',
          profileInputPlaceholder: 'Введите имя профиля',
          phoneInputLabel: 'Номер телефона',
          phoneInputPlaceholder: 'Введите номер',
          passwordLabel: 'Пароль',
          passwordPlaceholder: 'Введите пароль',
          confirmPasswordLabel: 'Подтвердите пароль',
          confirmPasswordPlaceholder: 'Введите пароль',
          saveChanges: 'Сохранить',
          cancelBtn: 'Отменить',
          confirmBtn: 'Сохранить'
        }
      }
    })

    return {
      state,
      formData,
      onValidateForm,
      submitData,
      onShowPassword,
      v$,
      t
    }
  }
})
</script>
