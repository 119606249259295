export default {
  ar: {
    products: 'منتجات',
    orders: 'أوامر',
    stock: 'سهم',
    stages: 'مراحل',
    integrations: 'التكامل',
    automation: 'الالية',
    clients: 'عملاء',
    loyalty: 'ولاء',
    logistic: 'لوجستية',
    movements: 'حركات'
  },
  en: {
    products: 'Products',
    orders: 'Orders',
    stock: 'Stock',
    stages: 'Stages',
    integrations: 'Integrations',
    automation: 'Automation',
    clients: 'Clients',
    loyalty: 'Loyalty',
    logistic: 'Logistic',
    movements: 'Movements'
  },
  ru: {
    products: 'Продукты',
    orders: 'Заказы',
    stock: 'Остатки',
    stages: 'Этапы',
    integrations: 'Интеграции',
    automation: 'Автоматизация',
    clients: 'Клиенты',
    loyalty: 'Лояльность',
    logistic: 'Логистика',
    movements: 'Перемещения'
  }
}
