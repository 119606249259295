<template>
  <div v-if="!loading">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <router-link
          :to="{ name: settings.createItemRoute }"
          tag="a"
          class="btn btn-primary shadow-md"
        >
          {{ settings.addButtonName }}
        </router-link>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <tbody>
            <tr
              v-for="(item, itemKey) in list"
              :key="itemKey"
              class="intro-x"
            >
              <td>
                <router-link
                  :to="{ name: settings.updateItemRoute, params: { id: item.ID } }"
                  tag="a"
                  class="font-medium whitespace-nowrap"
                >
                  {{ item[settings.firstNameField] }}
                </router-link>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  <span v-if="settings.secondNameFieldBefore">{{ settings.secondNameFieldBefore }}&nbsp;</span>
                  <span v-if="settings.secondNameField">{{ item[settings.secondNameField] }}</span>
                  <span v-if="settings.secondNameFieldAfter">&nbsp;{{ settings.secondNameFieldAfter }}&nbsp;</span>
                  <span v-if="settings.secondNameFieldLast">{{ item[settings.secondNameFieldLast] }}</span>
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link
                    :to="{ name: settings.updateItemRoute, params: { id: item.ID } }"
                    tag="a"
                    class="font-medium whitespace-nowrap mr-2"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    {{ t('editBtn') }}
                  </router-link>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    :data-target="'#' + settings.deleteModalId"
                    @click="idSelect = item.ID"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> {{ t('deleteDtn') }}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
    <DeleteConfirmationModal
      :id="settings.deleteModalId"
      :description="settings.deleteConfirmationContent"
      @deleteItem="deleteElement"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import { useListItemRemoving } from '@/use/useListItemRemoving'
import DeleteConfirmationModal from '@/components/delete-confirmation-modal/Main'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { DeleteConfirmationModal },
  name: 'DataList',
  props: {
    settings: Object
  },
  setup(props) {
    const { loading, data } = useStoreDataGetting(
      props.settings.dataGetterPath,
      props.settings.loadDataPath)

    const { itemId, deleteItem } = useListItemRemoving(props.settings.deleteDataItemPath)

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          editBtn: 'حرر',
          deleteDtn: 'حذف'
        },
        en: {
          editBtn: 'Edit',
          deleteDtn: 'Delete'
        },
        ru: {
          editBtn: 'Редактировать',
          deleteDtn: 'Удалить'
        }
      }
    })

    return {
      idSelect: itemId,
      deleteElement: deleteItem,
      loading,
      list: data,
      t
    }
  }
})
</script>
