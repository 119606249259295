const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'products-list',
        title: 'Товары'
      },
      {
        icon: 'BoxIcon',
        pageName: 'orders-list',
        title: 'Заказы'
      },
      {
        icon: 'ActivityIcon',
        pageName: 'stocks',
        title: 'Остатки'
      },
      {
        icon: 'FastForwardIcon',
        pageName: 'stages',
        title: 'Этапы'
      },
      {
        icon: 'LayoutIcon',
        pageName: 'integrations',
        title: 'Интеграции'
      },
      {
        icon: 'RepeatIcon',
        pageName: 'automation',
        title: 'Автоматизация'
      },
      {
        icon: 'UsersIcon',
        pageName: 'clients',
        title: 'Клиенты'
      },
      {
        icon: 'SmileIcon',
        pageName: 'loyalty',
        title: 'Лояльность'
      },
      {
        icon: 'TruckIcon',
        pageName: 'logistics-price-calculation',
        title: 'Логистика'
      },
      {
        icon: 'MoveIcon',
        pageName: 'move-list',
        title: 'Перемещения'
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
