<template>
  <teleport to="#forOutputTableTeleport">
    <div>
      <button
        id="tabulator-print"
        class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
        @click="outputTable.onPrint"
      >
        <PrinterIcon class="w-4 h-4 mr-2"/>
        {{ t('print') }}
      </button>
    </div>
    <div class="dropdown w-1/2 sm:w-auto">
      <button
        class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
        aria-expanded="false"
      >
        <FileTextIcon class="w-4 h-4 mr-2"/>
        {{ t('export') }}
        <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2"/>
      </button>
      <div class="dropdown-menu w-40">
        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
          <a
            id="tabulator-export-csv"
            href="javascript:;"
            class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="outputTable.onExportCsv"
          >
            <FileTextIcon class="w-4 h-4 mr-2"/>
            CSV
          </a>
          <a
            id="tabulator-export-json"
            href="javascript:;"
            class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="outputTable.onExportJson"
          >
            <FileTextIcon class="w-4 h-4 mr-2"/>
            JSON
          </a>
          <a
            id="tabulator-export-xlsx"
            href="javascript:;"
            class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="outputTable.onExportXlsx"
          >
            <FileTextIcon class="w-4 h-4 mr-2"/>
            XLSX
          </a>
          <a
            id="tabulator-export-html"
            href="javascript:;"
            class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
            @click="outputTable.onExportHtml"
          >
            <FileTextIcon class="w-4 h-4 mr-2"/>
            HTML
          </a>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { saveAs } from 'file-saver'
import XLSX from 'xlsx'

export default defineComponent({
  name: 'OutputTable',
  props: {
    table: Object
  },
  setup(props) {
    const initExport = (type) => {
      const wb = XLSX.utils.table_to_book(props.table, { sheet: 'Лист 1' })
      return XLSX.writeFile(wb, 'SimpleFillment таблица.' + type)
    }
    const outputTable = {
      onExportCsv: () => {
        initExport('csv')
      },
      onExportJson: () => {
        const worksheet = XLSX.utils.table_to_sheet(props.table, { sheet: 'Лист 1' })
        const sheetArr = XLSX.utils.sheet_to_json(worksheet)
        const jsonStr = JSON.stringify(sheetArr, null, 2)
        const fileToSave = new Blob([jsonStr], {
          type: 'application/json'
        })
        saveAs(fileToSave, 'SimpleFillment таблица.json')
      },
      onExportXlsx: () => {
        initExport('xlsx')
      },
      onExportHtml: () => {
        initExport('html')
      },
      onPrint: () => {
        const newWin = window.open('')
        newWin.document.write(props.table.outerHTML)

        let style = '<style>'
        style = style + 'table {width: 100%; font: 15px Arial;}'
        style = style + 'table, th, td {border: solid 1px #DDD; border-collapse: collapse;'
        style = style + 'padding: 2px 3px;}'
        style = style + '</style>'

        newWin.document.write(style)
        newWin.print()
        newWin.close()
      }
    }

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          print: 'مطبوعات',
          export: 'تصدير'
        },
        en: {
          print: 'Print',
          export: 'Export'
        },
        ru: {
          print: 'Печать',
          export: 'Экспорт'
        }
      }
    })

    return { outputTable, t }
  }
})
</script>

<style scoped>

</style>
