const state = () => {
  return {
    logisticsCompanies: null,
    currentLogisticsCompany: null,
    logisticSettings: localStorage.getItem('logisticSettings') || null
  }
}

// getters
const getters = {
  getLogisticsCompanies: state => state.logisticsCompanies,
  getCurrentLogisticsCompany: state => state.currentLogisticsCompany,
  getLogisticSettings: state => {
    try {
      return JSON.parse(state.logisticSettings)
    } catch (e) {
      return state.logisticSettings
    }
  }
}

// actions
const actions = {
  async loadLogisticsCompanies({ dispatch, commit }, data) {
    await dispatch('query/sendRequest', {
      url: 'transport-companies/get',
      data,
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setLogisticsCompanies', Object.values(res.data.result))
      })
  },
  async addLogisticCompany({ dispatch, commit }, payload) {
    payload.ACCESS = JSON.stringify(payload.ACCESS)
    await dispatch('query/sendRequest', {
      url: 'transport-companies/add',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async updateLogisticCompany({ dispatch, commit }, payload) {
    payload.ACCESS = JSON.stringify(payload.ACCESS)
    await dispatch('query/sendRequest', {
      url: 'transport-companies/update',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async loadLogisticCompanyByID({ dispatch, commit }, data) {
    await dispatch('query/sendRequest', {
      url: 'transport-companies/get-by-id/' + data.id,
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setCurrentLogisticsCompany', res.data)
      })
  },
  async deleteLogisticCompany({ dispatch, commit }, data) {
    await dispatch('query/sendRequest', {
      url: 'transport-companies/delete',
      data: data.items,
      method: 'POST'
    }, { root: true })
      .then(() => {
        commit('deleteLogisticsCompanyItem', data.items[0])
      })
  },
  async loadLogisticSettings({ dispatch, commit }) {
    await dispatch('query/sendRequest', {
      url: 'transport-companies/get-settings/',
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setLogisticSettings', res.data)
      })
  },
  async setLogisticSettings({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'transport-companies/set-settings/',
      method: 'POST',
      data: payload
    }, { root: true })
      .then(res => {
        if (res.data) commit('setLogisticSettings', payload)
      })
  },
  async sendFeedback({ dispatch, commit }, payload) {
    return await dispatch('query/sendRequest', {
      url: 'transport-companies/feed-back',
      method: 'POST',
      data: payload
    }, { root: true })
  }
}

// mutations
const mutations = {
  setLogisticsCompanies(state, data) {
    data.forEach(item => {
      item.ACCESS = JSON.parse(item.ACCESS)
    })
    state.logisticsCompanies = data
  },
  deleteLogisticsCompanyItem(state, id) {
    state.logisticsCompanies = state.logisticsCompanies.filter(item => item.ID !== id)
  },
  setCurrentLogisticsCompany(state, data) {
    data.ACCESS = JSON.parse(data.ACCESS)
    state.currentLogisticsCompany = data
  },
  setLogisticSettings(state, data) {
    const jsonData = (data === false) ? data : JSON.stringify(data)
    localStorage.setItem('logisticSettings', jsonData)
    state.logisticSettings = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
