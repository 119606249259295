<template>
  <div v-if="!stagesLoading">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ t('pageTitle') }}</h2>
      <ItemsAmountShown v-if="!tableLoading" paginationGetterPath="orders/getOrdersPagination"/>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2">{{ t('ReloadOrdersListBtn') }}</button>
      </div>
    </div>
    <div class="intro-y box px-2 py-3  mt-5">
      <BaseFiltration v-if="!tableLoading"
                      mainFiltersName="STAGE"
                      :tabsTitle="t('tableTabsTitle')"
                      :mainFiltersParams="stages"
                      :filtersParams="[
                        { prop: 'STATUS', name: t('filterPropStatus') },
                        { prop: 'LAST_CHANGE_DATE', name: t('filterPropChanged'), type: 'date', litepickerOptions: {
                            autoApply: true,
                            format: 'DD.MM.YYYY',
                            lang: 'ru',
                            singleMode: false,
                            setInitValue: false,
                            dropdowns: {
                              minYear: 2018,
                              months: true,
                              years: true
                            }
                          }
                        },
                        { prop: 'ID_ON_INTEGRATION', name: t('filterPropExternalID') }
                      ]">
      </BaseFiltration>

      <DataTable v-if="$route.query.STAGE"
                 requiredFilter="STAGE"
                 dataGetterPath="orders/getOrders"
                 actionDataPath="orders/loadOrders"
                 @loading="tableLoading = $event">
        <template v-slot:tableHead>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            ID
          </th>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('filterPropExternalID') }}
          </th>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('tableCellProducts') }}
          </th>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('tableCellOrderAmount') }}
          </th>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('tableCellChanged') }}
          </th>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('filterPropStatus') }}
          </th>
        </template>
        <template v-slot:tableBody="tableBody">
          <td class="border-b whitespace-nowrap">
            <router-link :to="{ name: 'order-detail', params: { id: tableBody.item.ID } }">
              {{ tableBody.item.ID}}
            </router-link>
          </td>
          <td class="border-b whitespace-nowrap">{{ tableBody.item.ID_ON_INTEGRATION }}</td>
          <td class="border-b whitespace-nowrap">
            <template  v-for="(product, index) in tableBody.item.PRODUCTS" :key="product.ID">
              <br v-if="index !== 0">
              <router-link tag="a"
                           :to="{ name: 'product-detail', params: { id: product.ID || '1' } }"
                           :class="{ 'mt-2': index !== 0 }"
                           class="inline-block">
                {{ product?.NAME }}</router-link>
            </template>
          </td>
          <td class="border-b whitespace-nowrap">{{ tableBody.item.TOTAL_PRICE }} р.</td>
          <td class="border-b whitespace-nowrap">
            {{ $h.formatUnixDate(tableBody.item.LAST_CHANGE_DATE, 'DD.MM.YY hh:mm') }}
          </td>
          <td class="border-b whitespace-nowrap">
            {{ tableBody.item.STATUS }}
          </td>
        </template>
      </DataTable>

      <!-- BEGIN: Pagination -->
      <div v-if="$route.query.STAGE && !tableLoading"
           class="col-span-12 flex justify-between sm:flex-row sm:flex-nowrap items-center px-1 mt-6">
        <BasePagination paginationGetterPath="orders/getOrdersPagination"/>
        <div class="mt-5 sm:mt-0 mr-auto lg:flex hidden" id="forOutputTableTeleport"/>
        <SelectItemsPerPage/>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import BaseFiltration from '@/components/base-filtration/Main'
import BasePagination from '@/global-components/base-pagination/Main'
import DataTable from '@/components/data-table/Main'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'OrdersList',
  components: { BasePagination, BaseFiltration, DataTable },
  setup() {
    const tableLoading = ref()

    // Загрузка данных о стадиях
    const stages = useStoreDataGetting(
      'stages/getStages',
      'stages/loadStages')

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitle: 'أوامر',
          ReloadOrdersListBtn: 'حدث',
          tableTabsTitle: 'حدد مرحلة',
          filterPropStatus: 'وضع',
          filterPropChanged: 'تغير',
          filterPropExternalID: 'خارج ID',
          tableCellExternalID: 'خارج ID',
          tableCellProducts: 'منتجات',
          tableCellOrderAmount: 'مبلغ الطلب',
          tableCellChanged: 'تغير',
          tableCellStatus: 'وضع'
        },
        en: {
          pageTitle: 'Orders',
          ReloadOrdersListBtn: 'Reload orders list',
          tableTabsTitle: 'Select a stage',
          filterPropStatus: 'Status',
          filterPropChanged: 'Changed',
          filterPropExternalID: 'External ID',
          tableCellExternalID: 'External ID',
          tableCellProducts: 'Products',
          tableCellOrderAmount: 'Order amount',
          tableCellChanged: 'Changed',
          tableCellStatus: 'Status'
        },
        ru: {
          pageTitle: 'Заказы',
          ReloadOrdersListBtn: 'Обновить список заказов',
          tableTabsTitle: 'Выберите этап',
          filterPropStatus: 'Статус',
          filterPropChanged: 'Изменен',
          filterPropExternalID: 'Внешний ID',
          tableCellExternalID: 'Внешний ID',
          tableCellProducts: 'Товары',
          tableCellOrderAmount: 'Сумма',
          tableCellChanged: 'Изменен',
          tableCellStatus: 'Статус'
        }
      }
    })

    return {
      stages: stages.data,
      stagesLoading: stages.loading,
      tableLoading,
      t
    }
  }
})
</script>
