import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

const useStoreDataGetting = (
  dataGetterPath,
  actionDataPath,
  payload,
  mutationMethodPath
) => {
  const store = useStore()
  const data = computed({
    get: () => store.getters[dataGetterPath],
    set: val => {
      mutationMethodPath ? store.commit(mutationMethodPath, val) : null
    }
  })
  const loading = ref(true)

  onMounted(() => {
    store
      .dispatch(actionDataPath, payload || null, { root: true })
      .finally(() => {
        loading.value = false
      })
  })

  return {
    data,
    loading
  }
}

export { useStoreDataGetting }
