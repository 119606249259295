import { reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import _ from 'lodash'

const useDataSending = (
  updateMethodPath,
  addMethodPath,
  gettingData) => {
  const store = useStore()
  const router = useRouter()
  const active = ref(false)
  const formData = reactive({})
  const sendingData = ref(false)

  const sendData = (id, backRoute) => {
    const url = id ? updateMethodPath : addMethodPath
    sendingData.value = true
    store.dispatch(url, { ...formData }).then(() => {
      if (backRoute) router.push(backRoute)
      sendingData.value = false
    })
  }

  if (!_.isEmpty(gettingData)) {
    watch(() => gettingData.value, () => {
      if (_.isEmpty(gettingData.value)) return
      Object.entries(gettingData.value)
        .forEach(([key, value]) => {
          formData[key] = value
        })
    })
  }

  return {
    active,
    formData,
    sendData,
    sendingData
  }
}

export { useDataSending }
