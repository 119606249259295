<template>
  <div class="bg-white dark:bg-dark-3 p-3 rounded-md">
    <div class="mb-4 font-medium text-base">{{ t('titleText') }}</div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
      <div class="flex items-center justify-between bg-theme-2 dark:bg-dark-1 rounded-md px-2 py-1">
        <label class="cursor-pointer select-none w-full mr-2" for="additional_data_1">
          {{ t('cashOnDeliveryText') }}
        </label>
        <input id="additional_data_1" type="checkbox" class="form-check-input border bg-white" v-model="additionalData" value="collectionOnDelivery"/>
      </div>
      <div class="flex items-center justify-between bg-theme-2 dark:bg-dark-1 rounded-md px-2 py-1">
        <label class="cursor-pointer select-none w-full mr-2" for="additional_data_2">
          {{ t('protectivePackagingText') }}
        </label>
        <input id="additional_data_2" type="checkbox" class="form-check-input border bg-white" v-model="additionalData" value="protectivePackage"/>
      </div>
      <div class="flex items-center justify-between bg-theme-2 dark:bg-dark-1 rounded-md px-2 py-1">
        <label class="cursor-pointer select-none w-full mr-2" for="additional_data_3">
          {{ t('cargoFillingText') }}
        </label>
        <input id="additional_data_3" type="checkbox" class="form-check-input border bg-white" v-model="additionalData" value="cargoSealing"/>
      </div>
      <div class="flex items-center justify-between bg-theme-2 dark:bg-dark-1 rounded-md px-2 py-1">
        <label class="cursor-pointer select-none w-full mr-2" for="additional_data_4">
          {{ t('TransportOfAccompanyingDocuments') }}
        </label>
        <input id="additional_data_4" type="checkbox" class="form-check-input border bg-white" v-model="additionalData" value="accompanyingDocuments"/>
      </div>
      <div class="flex items-center justify-between bg-theme-2 dark:bg-dark-1 rounded-md px-2 py-1">
        <label class="cursor-pointer select-none w-full mr-2" for="additional_data_5">
          {{ t('documentsReturningText') }}
        </label>
        <input id="additional_data_5" type="checkbox" class="form-check-input border bg-white" v-model="additionalData" value="documentReturn"/>
      </div>
      <div class="flex items-center justify-between bg-theme-2 dark:bg-dark-1 rounded-md px-2 py-1">
        <label class="cursor-pointer select-none w-full mr-2" for="additional_data_6">
          {{ t('cargoHandlingText') }}
        </label>
        <input id="additional_data_6" type="checkbox" class="form-check-input border bg-white" v-model="additionalData" value="loadingUnloading"/>
      </div>
      <div class="flex items-center justify-between bg-theme-2 dark:bg-dark-1 rounded-md px-2 py-1">
        <label class="cursor-pointer select-none w-full mr-2" for="additional_data_7">
          {{ t('paymentByCard') }}
        </label>
        <input id="additional_data_7" type="checkbox" class="form-check-input border bg-white" v-model="additionalData" value="payCard"/>
      </div>
    </div>

    <div class="flex items-center justify-between border dark:bg-dark-1 rounded-md p-2 mb-2">
      <label class="font-medium text-base w-full cursor-pointer" for="agreeForMissingParams"> {{ t('showResultsWithMissingParamsText') }}</label>
      <input type="checkbox" v-model="additionalData" value="showDataWithMissingParams" class="form-check-switch" id="agreeForMissingParams"/>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AdditionalData',
  props: {
    modelValue: Object
  },
  setup(props, { emit }) {
    const additionalData = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit('update:modelValue', val)
      }
    })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          titleText: 'بارامات إضافية',
          cashOnDeliveryText: 'الدفع نقدا عند التسليم',
          protectivePackagingText: 'عبوات واقية',
          cargoFillingText: 'تعبئة البضائع',
          TransportOfAccompanyingDocuments: 'نقل المستندات المصاحبة',
          documentsReturningText: 'إعادة الوثيقة',
          cargoHandlingText: 'مناولة البضائع',
          paymentByCard: 'الدفع بالبطاقة',
          showResultsWithMissingParamsText: 'نتائج المخرجات مع المعلمات المفقودة ؟'
        },
        en: {
          titleText: 'Additional params',
          cashOnDeliveryText: 'Cash on delivery',
          protectivePackagingText: 'Protective packaging',
          cargoFillingText: 'Cargo filling',
          TransportOfAccompanyingDocuments: 'Transport of accompanying documents',
          documentsReturningText: 'Return of document',
          cargoHandlingText: 'Cargo handling',
          paymentByCard: 'Payment by card',
          showResultsWithMissingParamsText: 'Output results with missing parameters?'
        },
        ru: {
          titleText: 'Дополнительные параметры',
          cashOnDeliveryText: 'Наложенный платеж',
          protectivePackagingText: 'Защитная упаковка',
          cargoFillingText: 'Пломбировка груза',
          TransportOfAccompanyingDocuments: 'Перевозка сопроводительных документов',
          documentsReturningText: 'Возврат документов',
          cargoHandlingText: 'Погрузка-разгрузка',
          paymentByCard: 'Оплата картой',
          showResultsWithMissingParamsText: 'Выводить результаты с недостающими параметрами?'
        }
      }
    })

    return {
      additionalData,
      t
    }
  }
})
</script>

<style scoped>

</style>
