<template>
  <IntroPreloader v-if="loading" />
  <IdNotFound v-else-if="!loading && client === false" essenceName="Клиент" />
  <div
    v-else
    class="flex flex-col lg:flex-row  border-gray-200 dark:border-dark-5"
  >
    <div
      class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
    >
      <h2
        class="font-medium text-center lg:text-left lg:mt-3 mr-5 inline-block"
      >
        {{ t('tabTitleText') }}
      </h2>

      <button
        v-if="!dataIsEditing"
        class="cursor-pointer ml-auto inline-block"
        @click="dataIsEditing = true"
      >
        <Edit2Icon class="w-4 h-4" />
      </button>
      <template v-else>
        <button class="cursor-pointer" :disabled="dataIsSaving">
          <SaveIcon class="w-4 h-4" @click="saveData" />
        </button>
        <button class="cursor-pointer" :disabled="dataIsSaving">
          <XCircleIcon class="w-4 h-4 ml-2" @click="unEdit" />
        </button>
      </template>
      <div
        class="flex flex-col justify-center items-center lg:items-start mt-4 relative z-1"
      >
        <div class="sm:whitespace-normal flex items-center h-9">
          <PhoneIcon class="w-4 h-4 mr-2 align-middle" />
          <VPhoneInput
            v-if="dataIsEditing"
            v-model="clientEditableData.phone"
          />
          <span v-else class="align-middle">{{ client.PHONE }}</span>
        </div>
        <div class="flex items-center mt-5 h-9">
          <UserIcon class="w-4 h-4 mr-2 align-middle flex-shrink-0" />
          <template v-if="dataIsEditing">
            <BaseInput
              v-model="clientEditableData.firstName"
              class="mr-1 mt-0"
              placeholder="Имя"
              :required="true"
              :inputGroup="false"
            />
            <BaseInput
              v-model="clientEditableData.secondName"
              placeholder="Отчество"
              class="mr-1 mt-0"
              :inputGroup="false"
            />
            <BaseInput
              v-model="clientEditableData.lastName"
              class="mt-0"
              placeholder="Фамилия"
              :inputGroup="false"
            />
          </template>
          <span v-else class="align-middle">
            <span class="mr-1">{{ client.FIRSTNAME }}</span>
            <span v-if="client.SECONDNAME" class="mr-1">
              {{ client.SECONDNAME }}
            </span>
            <span>{{ client.LASTNAME }}</span>
          </span>
        </div>
        <div class="flex items-center mt-5 h-9">
          <InfoIcon class="w-4 h-4 mr-2 align-middle" />
          <span class="align-middle">ID:&nbsp;</span>
          <span class="align-middle">{{ client.ID }}</span>
        </div>
        <div v-if="client?.DOB" class="flex items-center mt-5 h-9">
          <CalendarIcon class="w-4 h-4 mr-2 align-middle" />
          <span class="align-middle">
            {{ t('clientDateOfBirthText') }}
            {{
              $h.isUnixTimestamp(client.DOB)
                ? $h.formatUnixDate(client.DOB, 'DD.MM.YYYY')
                : client.DOB
            }}
          </span>
        </div>
        <div v-if="client?.TYPE" class="flex items-center mt-5 h-9">
          <TypeIcon class="w-4 h-4 mr-2 align-middle" />
          <span class="align-middle">
            {{ t('clientTypeText') }} {{ client.TYPE }}
          </span>
        </div>

        <div
          v-if="dataIsSaving"
          class="absolute w-full h-full z-10 bg-white flex justify-center items-center"
        >
          <LoaderIcon class="spinning-anim" />
        </div>
      </div>
    </div>
    <div
      class="mt-6 lg:mt-0 flex flex-1 lg:flex-row flex-col items-center justify-center flex-nowrap px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"
    >
      <div
        v-if="client.LOYALTY?.NAME"
        class="text-center rounded-md py-3 sm:mr-6"
      >
        <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
          {{ client.LOYALTY?.NAME }}
        </div>
        <div class="text-gray-600 whitespace-nowrap">
          {{ t('loyaltyStatusText') }}
        </div>
      </div>
      <div v-if="client?.TOTALSPEND" class="text-center rounded-md py-3">
        <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
          {{ client.TOTALSPEND }}
        </div>
        <div class="text-gray-600 whitespace-nowrap">
          {{ t('totalSpendText') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import IdNotFound from '@/components/id-not-found/Main'
import VPhoneInput from '@/global-components/v-phone-input/Main.vue'
import BaseInput from '@/components/base-input/Main.vue'

export default defineComponent({
  name: 'ClientInfo',
  components: { BaseInput, VPhoneInput, IdNotFound },
  props: {
    id: String
  },
  setup(props) {
    const store = useStore()
    const v$ = useVuelidate()

    // Загрузка данных о текущем клиенте
    const client = useStoreDataGetting(
      'clients/getCurrentClient',
      'clients/loadClientByID',
      { id: props.id },
      'clients/setCurrentClient'
    )

    const dataIsSaving = ref(false)
    const dataIsEditing = ref(false)
    const clientEditableData = reactive({
      phone: '',
      firstName: '',
      secondName: '',
      lastName: ''
    })

    const setClientEditableData = () =>
      Object.keys(clientEditableData).forEach(key => {
        clientEditableData[key] = client.data.value[key.toUpperCase()]
      })

    const unEdit = () => {
      dataIsEditing.value = false
      setClientEditableData()
    }

    const saveData = async () => {
      const result = await v$.value.$validate()

      console.log(result)
      if (result) {
        dataIsSaving.value = true
        Object.keys(clientEditableData).forEach(key => {
          client.data.value[key.toUpperCase()] = clientEditableData[key]
        })

        await store.dispatch('clients/updateClient', client.data.value)
        dataIsEditing.value = false
      }

      dataIsSaving.value = false
    }

    watch(
      () => client.data.value,
      () => setClientEditableData()
    )

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          tabTitleText: 'بيانات العميل',
          clientDateOfBirthText: 'تاريخ الميلاد: ',
          clientTypeText: 'نوع العميل: ',
          loyaltyStatusText: 'حالة الولاء',
          totalSpendText: 'إجمالي الإنفاق'
        },
        en: {
          tabTitleText: 'Client data',
          clientDateOfBirthText: 'Date of birth: ',
          clientTypeText: 'Type of client: ',
          loyaltyStatusText: 'Loyalty status',
          totalSpendText: 'Total spend'
        },
        ru: {
          tabTitleText: 'Данные клиента',
          clientDateOfBirthText: 'Дата рождения: ',
          clientTypeText: 'Тип клиента: ',
          loyaltyStatusText: 'Статус лояльности',
          totalSpendText: 'Общая сумма покупок'
        }
      }
    })

    return {
      loading: client.loading,
      client: client.data,
      dataIsSaving,
      dataIsEditing,
      clientEditableData,
      unEdit,
      saveData,
      t,
      v$
    }
  }
})
</script>

<style scoped>
.spinning-anim {
  animation: 1s linear 0s normal none infinite running rot;
  width: 100px;
}
@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
