import xlsx from 'xlsx'

const helpers = {
  // Export
  onExportCsv(table) {
    table.download('csv', 'data.csv')
  },
  onExportJson(table) {
    table.download('json', 'data.json')
  },
  onExportXlsx(table) {
    const win = window
    win.XLSX = xlsx
    table.download('xlsx', 'data.xlsx', {
      sheetName: 'Products'
    })
  },
  onExportHtml(table) {
    table.download('html', 'data.html', {
      style: true
    })
  }
}

const install = app => {
  app.config.globalProperties.$et = helpers
}

export { install as default, helpers as exportTable }
