<template>
  <div :class="[ 'relative', { 'input-group mt-2': inputGroup }, { 'input-form  mt-4': !inputGroup } ]">
    <template v-if="inputGroup" >
      <label :for="id" class="input-group-text whitespace-nowrap">{{ label }}</label>
      <select :id="id"
              v-model="selectedItem"
              class="form-select w-full border-l-0 rounded-l-none"
              :aria-describedby="id">
        <option v-if="showEmptyOption">Выбрать...</option>
        <option v-for="(item, index) in items"
                :key="index + id">
          <template v-if="item[itemValue]">{{ item[itemName] }}</template>
          <template v-else>{{ item }}</template>
        </option>
      </select>
    </template>

    <template v-if="!inputGroup" >
      <label :for="id" class="form-label mb-1 w-full flex flex-col sm:flex-row">{{ label }}</label>
      <select :id="id"
              v-model="selectedItem"
              class="form-select whitespace-nowrap"
              :aria-describedby="id">
        <option v-if="showEmptyOption">Выбрать...</option>
        <option v-for="(item, index) in items"
                :key="index + id"
                :value="$_.isEmpty(item[itemValue]) ? item : item[itemValue]">
          <template v-if="!$_.isEmpty(item[itemName])">{{ item[itemName] }}</template>
          <template v-else>{{ item }}</template>
        </option>
      </select>
    </template>

    <slot></slot>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseSelect',
  props: {
    modelValue: [Number, String, Object],
    type: {
      type: String,
      default: 'Text'
    },
    id: String,
    label: String,
    showEmptyOption: {
      type: Boolean,
      default: false
    },
    inputGroup: {
      type: Boolean,
      default: true
    },
    items: [Object, Array],
    itemValue: String,
    itemName: String
  },
  setup(props, { emit }) {
    const selectedItem = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit('update:modelValue', val)
      }
    })

    return {
      selectedItem
    }
  }
})
</script>
