<template>
  <div>
    <DarkModeSwitcher class="hidden md:flex fixed bottom-0 left-0 z-50 mb-4 ml-4"/>
    <MobileMenu />
    <!-- BEGIN: Top Bar -->
    <div
      class="border-b border-theme-29 -mt-10 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 pt-3 md:pt-0 mb-10 hidden md:block"
    >
      <div class="top-bar-boxed flex items-center">
        <!-- BEGIN: Logo -->
        <router-link :to="{ name: 'products-list', params: { page: '1', pageSize: '10'} }" tag="a" class="intro-x flex items-center pl-5 py-2">
          <img alt="Simple Fillment logo" class="w-6" src="@/assets/images/logo.png" />
        </router-link>
        <!-- END: Logo -->
        <!-- BEGIN: Breadcrumb -->
        <div class="-intro-x breadcrumb breadcrumb--light mr-auto hidden">
          <a href="" class="">SimpleFillment</a>
          <!--          <ChevronRightIcon class="breadcrumb__icon" />-->
          <!--          <a href="" class="breadcrumb&#45;&#45;active">Dashboard</a>-->
        </div>
        <!-- END: Breadcrumb -->

        <LanguageSwitcher class="mr-2 w-auto"/>
        <!-- BEGIN: Account Menu -->
        <div class="intro-x dropdown w-8 h-8">
          <div
            class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110"
            role="button"
            aria-expanded="false"
          >
            <img alt="" :src="require(`@/assets/images/profile-1.jpg`)"
            />
          </div>
          <div class="dropdown-menu w-56">
            <div
              class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white"
            >
              <div class="p-4 border-b border-theme-27 dark:border-dark-3">
                <div class="font-medium">{{ profile.name }}</div>
                <div class="text-xs text-theme-41 mt-0.5 dark:text-gray-600">
                  {{ profile.phone }}
                </div>
              </div>
              <div class="p-2">
                <router-link :to="{ name: 'profile-page' }"
                             tag="a"
                             class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
                  <UserIcon class="w-4 h-4 mr-2" /> {{ t('profile') }}
                </router-link>
              </div>
              <div class="p-2 border-t border-theme-27 dark:border-dark-3">
                <button @click='logout' class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
                  <ToggleRightIcon class="w-4 h-4 mr-2" />
                  {{ t('logOut') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Account Menu -->
      </div>
      <!-- BEGIN: Notification Content -->
      <div
        id="error-notification-content"
        class="toastify-content hidden flex items-center md:w-96 w-80"
      >
        <XCircleIcon class="text-theme-6 h-4 w-4"/>
        <div class="ml-4 mr-3">
          <div class="font-medium">Ошибка!</div>
          <div class="text-gray-600 mt-1 toast-text-content">
            Тут будет ее текст
          </div>
        </div>
      </div>
      <div
        id="success-notification-content"
        class="toastify-content hidden flex items-center md:w-96 w-80"
      >
        <CheckCircleIcon class="text-theme-9 h-4 w-4"/>
        <div class="ml-4 mr-3">
          <div class="font-medium">Успешно!</div>
          <div class="text-gray-600 mt-1 toast-text-content">
            Тут будет сообщение
          </div>
        </div>
      </div>
      <!-- END: Notification Content -->
    </div>
    <!-- END: Top Bar -->
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav pt-14">
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else :key="menu + menuKey">
              <SideMenuTooltip
                tag="a"
                :content="t(menu.title)"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName, params: menu.params || {} }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title">
                  {{ t(menu.title) }}
                </div>
              </SideMenuTooltip>
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <router-view/>
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { linkTo, nestedMenu, enter, leave } from './index'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import menuItemsTranslation from '@/data/menuTranslation'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import LanguageSwitcher from '@/components/language-switcher/Main'

export default defineComponent({
  name: 'SideMenu',
  components: {
    LanguageSwitcher,
    TopBar,
    MobileMenu,
    DarkModeSwitcher,
    SideMenuTooltip
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const profile = computed(() => store.getters['user/getProfile'])
    const requestErrorStatus = computed(() => store.getters['query/getRequestErrorStatus'])
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    const logout = () => store.dispatch('auth/authLogout').then(() => {
      localStorage.removeItem('lastRouterPath')
    })

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          ...menuItemsTranslation.ar,
          profile: 'ملف تعريف',
          logOut: 'سجل الخروج'
        },
        en: {
          ...menuItemsTranslation.en,
          profile: 'Profile',
          logOut: 'Log out'
        },
        ru: {
          ...menuItemsTranslation.ru,
          profile: 'Профиль',
          logOut: 'Выйти'
        }
      }
    })

    return {
      requestErrorStatus,
      profile,
      logout,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      t
    }
  }
})
</script>
