import axios from 'axios'
import router from '@/router'

const state = {
  token: localStorage.getItem('user-token') || '',
  refreshToken: localStorage.getItem('refresh-token') || '',
  status: ''
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  getToken: state => state.token,
  getRefreshToken: state => state.refreshToken
}

const actions = {
  authRequest: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit('authRequest')
      axios({
        url: process.env.VUE_APP_API_URL + 'companies/login',
        data: { data: user },
        method: 'POST'
      })
        .then(resp => {
          const data = resp.data
          if (data.token) {
            commit('authSuccess', data)
            dispatch('user/userRequest', data.userData, { root: true }).then(
              () => {
                dispatch('orders/loadOrdersSettings', {}, { root: true })
                dispatch('logistics/loadLogisticSettings', {}, { root: true })
                if (localStorage.getItem('lastRouterPath')) {
                  router
                    .push(localStorage.getItem('lastRouterPath'))
                    .then(() => {})
                }
              }
            )
            resolve(data)
          } else {
            reject(new Error('Неверные телефон и пароль'))
          }
        })
        .catch(err => {
          commit('authError', err)
          localStorage.removeItem('user-token')
          reject(err)
        })
    })
  },
  authLogout: ({ commit }) => {
    return new Promise(resolve => {
      commit('authLogout')
      commit('user/authLogout', {}, { root: true })
      router.push('/login').then(() => resolve())
    })
  }
}

const mutations = {
  authRequest: state => {
    state.status = 'loading'
  },
  authSuccess: (state, resp) => {
    state.status = 'success'
    state.token = resp.token
    state.refreshToken = resp.refreshToken
    localStorage.setItem('user-token', resp.token)
    localStorage.setItem('refresh-token', resp.refreshToken)
  },
  authError: state => {
    state.status = 'error'
  },
  authLogout: state => {
    state.token = ''
    state.refreshToken = ''
    localStorage.removeItem('user-token')
    localStorage.removeItem('refresh-token')
    localStorage.removeItem('logisticSettings')
    localStorage.removeItem('orderSettings')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
