<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ id ? t('pageTitleEditingText') : t('pageTitleAdditionText') }} {{ t('pageTitleStaticText') }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-12 mt-12">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('dateLabelText') }}</label>
            <Litepicker v-model="formData.DATE"
                        :options=" {
                          autoApply: true,
                          format: 'DD.MM.YYYY',
                          lang: 'ru',
                          singleMode: true,
                          setInitValue: false,
                          dropdowns: {
                            minYear: 2018,
                            months: true,
                            years: true
                          }
                        }"
                        id="crud-form-1"
                        class="form-control w-full"/>
          </div>
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('daysBeforeLabelText') }}</label>
            <input
              id="crud-form-1"
              v-model="formData.DAYS_TO"
              type="number"
              class="form-control w-full"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('daysAfterLabelText') }}</label>
            <input
              id="crud-form-1"
              v-model="formData.DAYS_AFTER"
              type="number"
              class="form-control w-full"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('bonusAmountLabelText') }}</label>
            <input
              id="crud-form-1"
              v-model="formData.POINTS"
              type="number"
              class="form-control w-full"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('daysBeforeCombustionLabelText') }}</label>
            <input
              id="crud-form-1"
              v-model="formData.DAYS_LOST"
              type="number"
              class="form-control w-full"
              required
            />
          </div>
          <div class="mt-3">
            <label>{{ t('isActiveText') }}</label>
            <div class="mt-2">
              <input type="checkbox" v-model="active" class="form-check-switch" />
            </div>
          </div>
          <div class="text-right mt-5">
            <router-link :to="{ name: 'loyalty' }" tag="button" class="btn btn-outline-secondary w-24 mr-1">
              {{ t('cancelBtnText') }}
            </router-link>
            <button class="btn btn-primary max-w-max"
                    type="button"
                    @click="saveDate(id, '/loyalty')">
              <LoadingIcon icon="ball-triangle" v-if="sendingData" class="'w-4 h-4 ml-1"/>
              {{ t('saveBtnText') }}
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import { useDataSending } from '@/use/useDataSending'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'LoyaltySpecialDates',
  props: {
    id: String
  },
  setup(props) {
    // загрузка текущей акции
    let currentLoyaltyDate
    if (props.id) {
      currentLoyaltyDate = useStoreDataGetting(
        'loyaltyDates/getCurrentLoyaltyDate',
        'loyaltyDates/loadLoyaltyDateById',
        { id: props.id || '' })
    }

    // сохранение данных акции
    const { active, formData, sendData, sendingData } = useDataSending(
      'loyaltyDates/updateLoyaltyDate',
      'loyaltyDates/addLoyaltyDate',
      currentLoyaltyDate?.data)

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitleEditingText: 'تحرير',
          pageTitleAdditionText: 'إضافة',
          pageTitleStaticText: 'موعد خاص',
          dateLabelText: 'تاريخ',
          daysBeforeLabelText: 'قبل أيام',
          daysAfterLabelText: 'بعد أيام',
          bonusAmountLabelText: 'مبلغ المكافأة',
          daysBeforeCombustionLabelText: 'قبل أيام من الاحتراق',
          isActiveText: 'نشط',
          saveBtnText: 'نقذ',
          cancelBtnText: 'إلغاء'
        },
        en: {
          pageTitleEditingText: 'Editing',
          pageTitleAdditionText: 'Addition',
          pageTitleStaticText: 'special date',
          dateLabelText: 'Date',
          daysBeforeLabelText: 'Days before',
          daysAfterLabelText: 'Days after',
          bonusAmountLabelText: 'Bonus amount',
          daysBeforeCombustionLabelText: 'Days before combustion',
          isActiveText: 'Active',
          saveBtnText: 'Save',
          cancelBtnText: 'Cancel'
        },
        ru: {
          pageTitleEditingText: 'Редактирование',
          pageTitleAdditionText: 'Добавление',
          pageTitleStaticText: 'специальной даты',
          dateLabelText: 'Дата',
          daysBeforeLabelText: 'Дней до',
          daysAfterLabelText: 'Дней после',
          bonusAmountLabelText: 'Количество баллов',
          daysBeforeCombustionLabelText: 'Дней до сгорания',
          isActiveText: 'Активен',
          saveBtnText: 'Сохранить',
          cancelBtnText: 'Отмена'
        }
      }
    })

    return {
      formData,
      saveDate: sendData,
      sendingData,
      active,
      t
    }
  }
})
</script>
