<template>
  <h2 class="text-lg font-medium my-4">
    Результаты выполнения заданий
  </h2>
  <div  class="intro-y box p-1">
    <DataTable dataGetterPath="automations/getAutomationLogs"
               actionDataPath="automations/loadAutomationLogs">
      <template v-slot:tableHead>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
          ID
        </th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
          Дата
        </th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
          Статус
        </th>
      </template>
      <template v-slot:tableBody="tableBody">
        <td class="border-b whitespace-nowrap">
          <router-link :to="{ name: 'task-log', params: { id: tableBody.item.ID } }">
            {{ tableBody.item.ID }}
          </router-link>
        </td>
        <td class="border-b whitespace-nowrap">
          {{ $h.formatUnixDate(tableBody.item.DATE_END, 'DD.MM.YY hh:mm') }}
        </td>
        <td class="border-b whitespace-nowrap">
          {{ tableBody.item.STATUS }}
        </td>
      </template>
    </DataTable>
    <!-- BEGIN: Pagination -->
    <div class="col-span-12 flex justify-between sm:flex-row sm:flex-nowrap items-center px-1 mt-6">
      <BasePagination paginationGetterPath="automations/getAutomationLogsPagination"/>
      <div class="mt-5 sm:mt-0 mr-auto lg:flex hidden" id="forOutputTableTeleport"/>
      <SelectItemsPerPage/>
    </div>
    <!-- END: Pagination -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import BasePagination from '@/global-components/base-pagination/Main'
import DataTable from '@/components/data-table/Main'

export default defineComponent({
  name: 'TaskLogbook',
  components: { DataTable, BasePagination }
})
</script>
