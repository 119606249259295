<template>
  <div class="grid grid-cols-12 gap-x-5 mb-2">
    <input v-model="attrItem.name" class="form-control col-span-5" type="text"/>
    <input v-model="attrItem.value" class="form-control col-span-6" type="text"/>
    <button class="btn btn-danger col-span-1" @click="$emit('deleteItem')">
      <TrashIcon class="w-5 h-5" />
    </button>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'MultiInput',
  props: {
    attributeItem: Object
  },
  setup(props, { emit }) {
    const attrItem = computed({
      get: () => props.attributeItem,
      set: value => emit('update:modelValue', value)
    })

    return { attrItem }
  }
}
</script>
