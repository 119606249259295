import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import simpleMenu from './simple-menu'
import topMenu from './top-menu'
import user from './modules/user'
import auth from './modules/auth'
import query from './modules/query'
import products from '@/store/modules/products'
import orders from '@/store/modules/orders'
import stages from '@/store/modules/stages'
import stock from '@/store/modules/stock'
import movements from '@/store/modules/movements'
import logistics from '@/store/modules/logistics'
import clients from '@/store/modules/clients'
import integrations from '@/store/modules/integrations'
import automations from '@/store/modules/automations'
import loyaltySettings from '@/store/modules/loyaltySettings'
import loyaltyDates from '@/store/modules/loyaltyDates'
import loyaltyStatuses from '@/store/modules/loyaltyStatuses'

// const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  modules: {
    main,
    sideMenu,
    simpleMenu,
    topMenu,
    user,
    auth,
    query,
    products,
    orders,
    stages,
    stock,
    movements,
    logistics,
    clients,
    integrations,
    automations,
    loyaltySettings,
    loyaltyDates,
    loyaltyStatuses
  }
  // strict: debug
})

export function useStore() {
  return store
}

export default store
