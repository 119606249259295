import { createRouter, createWebHashHistory } from 'vue-router'

// import TopMenu from '../layouts/top-menu/Main.vue'
import SideMenu from '../layouts/side-menu/Main.vue'
import CrudDataList from '../views/integration-data-list/Main.vue'
import CrudForm from '../views/integration-form/Main.vue'
import AutomationDataList from '../views/automation-data-list/Main.vue'
import AutomationForm from '../views/automation-form/Main.vue'
import StagesDataList from '../views/stages-data-list/Main.vue'
import StagesForm from '../views/stages-form/Main.vue'
import LoyaltyStatusForm from '../views/loyalty-status-form/Main.vue'
import LoyaltyDatesForm from '../views/loyalty-dates-form/Main.vue'
import Login from '../views/login/Main.vue'
import Register from '../views/register/Main.vue'
import ErrorPage from '../views/error-page/Main.vue'
import EditProducts from '../views/edit-product/Main.vue'
import LoyaltyMain from '../views/loyalty-main/Main.vue'
import OrderDetail from '../views/order-detail/Main.vue'
import LogisticsPriceCalculation from '../views/logistics-price-calculation/Main.vue'
import LogisticsCompaniesDataList from '../views/logistics-companies-data-list/Main.vue'
import LogisticsCompaniesForm from '../views/logistics-companies-form/Main.vue'
import TaskLogbook from '../views/task-logbook/Main.vue'
import TaskLog from '../views/task-log/Main.vue'
import ProfilePage from '../views/profile-page/Main.vue'
import OrdersList from '../views/orders-list/Main.vue'
import ProductsList from '../views/products-list/Main.vue'
import StockList from '../views/stock-list/Main.vue'
import ClientsList from '../views/clients-list/Main.vue'
import MovementList from '../views/movement-list/Main.vue'
import MovementAdding from '../views/movement-adding/Main.vue'
import MovementDetails from '../views/movement-details/Main.vue'
import ClientDetails from '../views/client-details/Main.vue'
import ExcelEditor from '../views/excel-editor/Main.vue'
import Chat from '../views/chat-page/Main.vue'
import WidgetDoc from '../views/widget-doc/Main.vue'

// import FileManager from '../views/file-manager/Main.vue'

const routes = [
  {
    path: '/',
    component: SideMenu,
    meta: { authNeed: true },
    children: [
      {
        path: '/profile',
        name: 'profile-page',
        component: ProfilePage
      },
      {
        path: '/product/edit/:id',
        name: 'product-edit',
        component: EditProducts
      },
      {
        path: '/product/:id',
        name: 'product-detail',
        component: EditProducts,
        props: true
      },
      {
        path: '/product/add',
        name: 'product-add',
        component: EditProducts
      },
      {
        path: '/products-list/:page/:pageSize',
        name: 'products-list',
        component: ProductsList
      },
      {
        path: '/order/:id',
        name: 'order-detail',
        component: OrderDetail,
        props: true
      },
      {
        path: '/orders-list/:page/:pageSize',
        name: 'orders-list',
        component: OrdersList
      },
      {
        path: '/stock/:page/:pageSize',
        name: 'stocks',
        component: StockList
      },
      {
        path: '/integrations',
        name: 'integrations',
        component: CrudDataList
      },
      {
        path: '/integrations/add',
        name: 'integrations-add',
        component: CrudForm
      },
      {
        path: '/integrations/edit/:id',
        name: 'integrations-edit',
        component: CrudForm,
        props: true
      },
      {
        path: '/automation',
        name: 'automation',
        component: AutomationDataList
      },
      {
        path: '/automation/add',
        name: 'automation-add',
        component: AutomationForm
      },
      {
        path: '/automation/edit/:id',
        name: 'automation-edit',
        component: AutomationForm,
        props: true
      },
      {
        path: '/stages',
        name: 'stages',
        component: StagesDataList
      },
      {
        path: '/stages/add',
        name: 'stages-add',
        component: StagesForm
      },
      {
        path: '/stages/edit/:id',
        name: 'stages-edit',
        component: StagesForm,
        props: true
      },
      {
        path: '/clients/:page/:pageSize/:add?',
        name: 'clients',
        component: ClientsList
      },
      {
        path: '/client-details/:id/:tab',
        name: 'client-details',
        component: ClientDetails,
        props: true
      },
      {
        path: '/loyalty',
        name: 'loyalty',
        component: LoyaltyMain
      },
      {
        path: '/loyalty-statuses/edit/:id',
        name: 'loyalty-status-edit',
        component: LoyaltyStatusForm,
        props: true
      },
      {
        path: '/loyalty-statuses/add',
        name: 'loyalty-status-add',
        component: LoyaltyStatusForm
      },
      {
        path: '/loyalty-dates/edit/:id',
        name: 'loyalty-dates-edit',
        component: LoyaltyDatesForm,
        props: true
      },
      {
        path: '/loyalty-dates/add',
        name: 'loyalty-dates-add',
        component: LoyaltyDatesForm
      },
      {
        path: '',
        redirect: 'profile'
      },
      {
        path: '/logistics-price-calculation/:id',
        name: 'order-logistics-price-calculation',
        component: LogisticsPriceCalculation,
        props: true
      },
      {
        path: '/logistics-price-calculation',
        name: 'logistics-price-calculation',
        component: LogisticsPriceCalculation
      },
      {
        path: '/logistics-companies',
        name: 'logistics-companies',
        component: LogisticsCompaniesDataList
      },
      {
        path: '/logistics-companies/add',
        name: 'logistics-companies-add',
        component: LogisticsCompaniesForm
      },
      {
        path: '/logistics-companies/edit/:id',
        name: 'logistics-companies-edit',
        component: LogisticsCompaniesForm,
        props: true
      },
      {
        path: '/task-logbook/:page/:pageSize',
        name: 'task-logbook',
        component: TaskLogbook
      },
      {
        path: '/task-log/:id',
        name: 'task-log',
        component: TaskLog,
        props: true
      },
      {
        path: '/movement-adding/',
        name: 'movement-adding',
        component: MovementAdding
      },
      {
        path: '/movement/:id',
        name: 'movement',
        component: MovementDetails,
        props: true
      },
      {
        path: '/movement-list/:page/:pageSize',
        name: 'movement-list',
        component: MovementList
      },
      {
        path: 'excel-editor',
        name: 'excel-editor',
        component: ExcelEditor
      },
      {
        path: 'chat',
        component: Chat
      },
      {
        path: 'widget-explaining',
        component: WidgetDoc
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: { authNeed: false },
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    meta: { authNeed: false },
    component: Register
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

export default router
