<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
    <div
      v-for="(task, index) in tasks"
      :key="task.id + '-helpdesk-task'"
      :class="[
        'bg-white rounded-lg p-2 border',
        { 'mb-2': index !== task.length - 1 }]"
    >
      <div class="mb-2">
        <span class="font-medium">Задача:</span>
        {{ task?.title }}
      </div>
      <div class="mb-2">
        <span class="font-medium">ID:</span>
        {{ task?.id }}
      </div>
      <div class="mb-2">
        <span class="font-medium">Статус:</span>
        {{ task?.status }}
      </div>
      <div class="mb-2">
        <span class="font-medium">Описание:</span>
        {{ task?.description ? task?.description : 'отсутствует' }}
      </div>
      <div>
        <div class="font-medium mb-1">Вложения</div>
        <div
          v-for="attachment in task?.attachments"
          :key="attachment.path"
        >
          <a class="overflow-ellipsis overflow-hidden block"
             :href="'https://apisf.nikit.me/' + attachment.path"
             target="_blank"
          >
            {{ getAttachmentFileName(attachment.path) }}
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HelpdeskTasks',
  props: {
    tasks: Array
  },
  setup () {
    const getAttachmentFileName = path => {
      return path.lastIndexOf('/') !== -1
        ? path.slice(path.lastIndexOf('/') + 1)
        : 'Открыть файл'
    }
    return {
      getAttachmentFileName
    }
  }
})
</script>

<style scoped>

</style>
