<template>
  <div class="flex justify-center items-center min-w-full h-96">
    <LoadingIcon icon="oval" class="w-8 h-8" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'IntroPreloader'
})
</script>
