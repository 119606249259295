<template>
  <div class="py-4" v-if="!loading">
    <vue-json-pretty :data="taskData"
                     :deep="3"
                     :showDoubleQuotes="false"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'

export default defineComponent({
  name: 'TaskLog',
  components: {
    VueJsonPretty
  },
  props: {
    id: String
  },
  setup(props) {
    // Загрузка данных о текущем этапе
    const { data, loading } = useStoreDataGetting(
      'automations/getCurrentAutomationLog',
      'automations/loadAutomationLogById',
      { id: props.id || '' })

    return {
      taskData: data,
      loading
    }
  }
})
</script>

<style scoped>
</style>
