<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ t('pageTitle') }}</h2>
      <ItemsAmountShown v-if="!loading" paginationGetterPath="clients/getClientsPagination"/>
      <a href="javascript:;"
         class="btn btn-primary shadow-md"
         data-toggle="modal" data-target="#medium-slide-over-size-preview">
        {{ t('addClientBtn') }}
      </a>
    </div>
    <div class="intro-y box px-2 py-3  mt-5">
      <BaseFiltration v-if="!loading"
                      :filtersParams="[
                        { prop: 'PHONE', name: t('phoneFilterLabel') },
                        { prop: 'FIRSTNAME', name: t('nameFilterLabel') },
                        { prop: 'ID', name: t('idFilterLabel') },
                        {
                          prop: 'TYPE',
                          name: t('typeFilterLabel'),
                          type: 'select',
                          selectData: [
                            { ID: 'B2C', NAME: 'B2C' },
                            { ID: 'B2B', NAME: 'B2B' }
                          ]
                        },
                      ]">
      </BaseFiltration>

      <DataTable dataGetterPath="clients/getClients"
                 actionDataPath="clients/loadClients"
                 @loading="loading = $event">
        <template v-slot:tableHead>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('clientIdCol') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('clientTypeCol') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('clientPhoneCol') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('clientNameCol') }}
          </th>
          <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
            {{ t('bonusAmountCol') }}
          </th>
        </template>
        <template v-slot:tableBody="tableBody">
          <td class="border-b whitespace-nowrap">
            <router-link :to="{ name: 'client-details', params: { id: tableBody.item.ID, tab: 'client-info' } }" target="_blank">
              {{ tableBody.item.ID }}
            </router-link>
          </td>
          <td class="border-b whitespace-nowrap">
            {{ tableBody.item.TYPE }}
          </td>
          <td class="border-b whitespace-nowrap">
            <router-link :to="{ name: 'client-details', params: { id: tableBody.item.ID, tab: 'client-info' } }" target="_blank">
              {{ tableBody.item.PHONE }}
            </router-link>
          </td>
          <td class="border-b whitespace-nowrap">
            <router-link :to="{ name: 'client-details', params: { id: tableBody.item.ID, tab: 'client-info' } }" target="_blank">
              {{ tableBody.item.FIRSTNAME }}
            </router-link>
          </td>
          <td class="border-b whitespace-nowrap">
            <router-link :to="{ name: 'client-details', params: { id: tableBody.item.ID, tab: 'client-info' } }" target="_blank">
              {{ tableBody.item.POINTSQTY }}
            </router-link>
          </td>
        </template>
      </DataTable>

      <!-- BEGIN: Pagination -->
      <div v-if="!loading" class="col-span-12 flex justify-between sm:flex-row sm:flex-nowrap items-center px-1 mt-6">
        <BasePagination paginationGetterPath="clients/getClientsPagination"/>
        <div class="mt-5 sm:mt-0 mr-auto lg:flex hidden" id="forOutputTableTeleport"/>
        <SelectItemsPerPage/>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>
  <addClient/>
</template>

<script>
import { defineComponent, ref } from 'vue'
import BaseFiltration from '@/components/base-filtration/Main'
import BasePagination from '@/global-components/base-pagination/Main'
import DataTable from '@/components/data-table/Main'
import AddClient from '@/components/client/add-client/Main'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ClientsList',
  components: {
    AddClient,
    BasePagination,
    BaseFiltration,
    DataTable
  },
  setup() {
    const loading = ref()

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitle: 'عميل',
          addClientBtn: 'أضف العميل',
          nameFilterLabel: 'اسم',
          phoneFilterLabel: 'هاتف',
          idFilterLabel: 'ID',
          typeFilterLabel: 'نوع',
          clientIdCol: 'ID',
          clientTypeCol: 'نوع',
          clientPhoneCol: 'هاتف',
          clientNameCol: 'اسم',
          bonusAmountCol: 'عدد نقاط المكافأة'
        },
        en: {
          pageTitle: 'Client',
          addClientBtn: 'Add client',
          nameFilterLabel: 'اسم',
          phoneFilterLabel: 'Phone',
          idFilterLabel: 'ID',
          typeFilterLabel: 'Type',
          clientIdCol: 'ID',
          clientTypeCol: 'Type',
          clientPhoneCol: 'Phone',
          clientNameCol: 'Name',
          bonusAmountCol: 'Bonus point count'
        },
        ru: {
          pageTitle: 'Клиенты',
          addClientBtn: 'Добавить клиента',
          nameFilterLabel: 'Имя',
          phoneFilterLabel: 'Телефон',
          idFilterLabel: 'ID',
          typeFilterLabel: 'Тип',
          clientIdCol: 'ID',
          clientTypeCol: 'Тип',
          clientPhoneCol: 'Телефон',
          clientNameCol: 'Имя',
          bonusAmountCol: 'Количество баллов'
        }
      }
    })

    return {
      loading,
      t
    }
  }
})
</script>
