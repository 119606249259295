<template>
  <h2 class="text-md font-medium mb-2">{{ t('addressPointsTitle') }}</h2>
  <div class="mb-4">
    <div v-for="(address, index) in formData.preferredSendingAddresses"
         :key="'preferredAddress' + index"
         :class="'mb-4 flex relative z-' + String(maxZIndex - index)">
      <AddressInput
        :id="'preferred_address_' + index"
        v-model="formData.preferredSendingAddresses[index]"
        class="w-full"
        :label-text="String(index + 1) + t('addressText')"
        :preferredAddress="$_.isEmpty(address) ? [] : [address]"
        :placeholder="t('enterAddressText')"/>
      <button class="btn btn-danger w-12 ml-2 h-12" @click="onRemoveAddress(index)">
        <TrashIcon/>
      </button>
    </div>

    <button class="btn btn-success max-w-max" @click="onAddAddress">{{ t('addAddressText') }}<PlusIcon class="w-5 h-5 ml-2"/></button>
  </div>

  <button class="btn btn-primary" @click="onSaveSettings">{{ t('saveChangesText') }}</button>
</template>

<script>
import { computed, defineComponent, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import AddressInput from '@/components/address-input/Main'

export default defineComponent({
  name: 'LogisticSettings',
  components: { AddressInput },
  setup() {
    const store = useStore()
    const maxZIndex = 50
    const logisticSettings = computed(() => store.getters['logistics/getLogisticSettings'])
    const formData = reactive({
      preferredSendingAddresses: [{}]
    })

    const onSaveSettings = () => store.dispatch('logistics/setLogisticSettings', formData)
    const onAddAddress = () => formData.preferredSendingAddresses.push({})
    const onRemoveAddress = (index) => formData.preferredSendingAddresses.splice(index, 1)

    watch(() => logisticSettings.value, val => {
      if (!val) return store.dispatch('logistics/loadLogisticSettings')

      if (Array.isArray(val.preferredSendingAddresses)) {
        formData.preferredSendingAddresses = []
        val.preferredSendingAddresses.forEach(address => {
          formData.preferredSendingAddresses.push(address)
        })
      }
    }, { immediate: true })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          addressText: 'معالجة',
          enterAddressText: 'أدخل العنوان',
          addressPointsTitle: 'إرسال العنوان',
          addAddressText: 'أضف العنوان',
          saveChangesText: 'نقذ'
        },
        en: {
          addressText: 'address',
          enterAddressText: 'Enter address',
          addressPointsTitle: 'Sending address',
          addAddressText: 'Add address',
          saveChangesText: 'Save Changes'
        },
        ru: {
          addressText: 'адрес',
          enterAddressText: 'Введите адрес',
          addressPointsTitle: 'Адреса отправки',
          addAddressText: 'Добавить адрес',
          saveChangesText: 'Сохранить изменения'
        }
      }
    })

    return {
      maxZIndex,
      formData,
      onSaveSettings,
      onAddAddress,
      onRemoveAddress,
      t
    }
  }
})
</script>
