const helpers = {
  // Print
  onPrint(table) {
    table.print()
  }
}

const install = app => {
  app.config.globalProperties.$pt = helpers
}

export { install as default, helpers as printTable }
