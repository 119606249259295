<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ t('pageTitle') }}</h2>
      <ItemsAmountShown v-if="!tableLoading" paginationGetterPath="products/getProductsPagination"/>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <!-- BEGIN: Large Slide Over Toggle -->
        <a href="javascript:;" data-toggle="modal" data-target="#large-slide-over-size-preview"  class="btn btn-primary shadow-md mr-2">{{ t('importBtn') }}</a>
        <!-- END: Large Slide Over Toggle -->
        <router-link class="btn btn-primary shadow-md"
                     :to="{ name: 'product-add' }"
                     tag="a">
          {{ t('addProductBtn') }}</router-link>
      </div>
    </div>
    <div class="intro-y box px-2 py-3  mt-5">
      <BaseFiltration v-if="!tableLoading"
                      :filtersParams="[
                        { prop: '%NAME', name: t('nameFilterLabel') },
                        { prop: '%ARTICLE', name: t('articleFilterLabel') }
                      ]">
      </BaseFiltration>

      <DataTable dataGetterPath="products/getProducts"
                 actionDataPath="products/loadProducts"
                 @loading="tableLoading = $event">
        <template v-slot:tableHead>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('productNameCol') }}
          </th>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('priceCol') }}
          </th>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('articleCol') }}
          </th>
          <th class="border-b-2 border-t dark:border-dark-5 whitespace-nowrap">
            {{ t('barcodeCol') }}
          </th>
        </template>
        <template v-slot:tableBody="tableBody">
          <td class="border-b whitespace-nowrap">
            <router-link :to="{ name: 'product-detail', params: { id: tableBody.item.ID } }">
              {{ tableBody.item.NAME }}
            </router-link>
          </td>
          <td class="border-b whitespace-nowrap">
            <div v-if="tableBody.item?.PRICE?.RETAIL_PRICE" >
              <span class="mr-2">{{ t('withoutDiscount') }}: {{ $h.cutFraction(tableBody.item?.PRICE.PRICE) }}</span>
              <span>{{ t('discounted') }}:  {{ $h.cutFraction(tableBody.item.PRICE?.RETAIL_PRICE) }}</span>
            </div>
            <div v-else>{{ $h.cutFraction(tableBody.item.PRICE) }}</div>
          </td>
          <td class="border-b whitespace-nowrap">
            {{ tableBody.item.ARTICLE }}
          </td>
          <td class="border-b whitespace-nowrap">
            {{ tableBody.item.BARCODE }}
          </td>
        </template>
      </DataTable>

      <!-- BEGIN: Pagination -->
      <div v-if="!tableLoading" class="col-span-12 flex justify-between sm:flex-row sm:flex-nowrap items-center px-1 mt-6">
        <BasePagination paginationGetterPath="products/getProductsPagination"/>
        <div class="mt-5 sm:mt-0 mr-auto lg:flex hidden" id="forOutputTableTeleport"/>
        <SelectItemsPerPage/>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>

  <!-- BEGIN: Large Slide Over Content -->
  <div  v-if="!integrationsLoading"
        id="large-slide-over-size-preview"
        class="modal modal-slide-over"
        tabindex="-1"
        aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header p-5">
          <h2 class="font-medium text-base mr-auto">
            {{ t('importDataTitle') }}
          </h2>
        </div>
        <div class="modal-body">
          <div>
            <label for="import-products-select" class="form-label">{{ t('selectImportedData') }}</label>
            <select id="import-products-select"
                    class="form-select mt-2 sm:mr-2"
                    aria-label="Default select example"
                    v-model="importFrom">
              <option v-for="item in integrations"
                      :key="item.ID"
                      :value="item">{{ item.NAME }}</option>
            </select>
          </div>
          <!-- BEGIN: Modal Toggle -->
          <div class="text-left">
            <a href="javascript:;"
               data-toggle="modal"
               data-dismiss="modal"
               data-target="#import-started-modal"
               @click="importProducts"
               class="btn btn-primary mt-3">{{ t('startImportBtn') }}</a>
          </div>
          <!-- END: Modal Toggle -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Large Slide Over Content -->
  <!-- BEGIN: Modal Content -->
  <SuccessModal id="import-started-modal"
                :title="t('importProcessingTitle')"
                :message="t('importProcessingMsg')"/>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import BaseFiltration from '@/components/base-filtration/Main'
import BasePagination from '@/global-components/base-pagination/Main'
import DataTable from '@/components/data-table/Main'
import SuccessModal from '@/components/success-modal/Main'

export default defineComponent({
  name: 'ProductsList',
  components: { SuccessModal, BasePagination, BaseFiltration, DataTable },
  setup() {
    const tableLoading = ref()

    // инициализация загрузки данных об интеграциях
    const integrations = useStoreDataGetting(
      'integrations/getIntegrations',
      'integrations/loadIntegrations'
    )

    // импорт продуктов
    const store = useStore()
    const importFrom = ref()
    const importProducts = () => store.dispatch('products/importProducts', importFrom.value, { root: true })

    watch(() => integrations.value, () => {
      importFrom.value = integrations.value.filter(item => item.NAME.includes('Интернет-магазин'))[0]
    })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitle: 'منتجات',
          importBtn: 'استيراد منتجات',
          addProductBtn: 'لإضافة منتج',
          nameFilterLabel: 'اسم',
          articleFilterLabel: 'مادة',
          productNameCol: 'اسم',
          articleCol: 'مادة',
          priceCol: 'سعر',
          barcodeCol: 'الباركود',
          withoutDiscount: 'دون خصم',
          discounted: 'مخفضة',
          importDataTitle: 'استيراد منتجات',
          selectImportedData: 'حدد مصدر البيانات',
          startImportBtn: 'تحميل',
          importProcessingTitle: 'في عملية',
          importProcessingMsg: 'يتم استيراد المنتجات'
        },
        en: {
          pageTitle: 'Products',
          importBtn: 'Import products',
          addProductBtn: 'Add product',
          nameFilterLabel: 'Name',
          articleFilterLabel: 'Article',
          productNameCol: 'Name',
          articleCol: 'Article',
          priceCol: 'Price',
          barcodeCol: 'Barcode',
          withoutDiscount: 'Without discount',
          discounted: 'Discounted',
          importDataTitle: 'Import products',
          selectImportedData: 'Select the data source',
          startImportBtn: 'Upload',
          importProcessingTitle: 'In process',
          importProcessingMsg: 'Products are being imported'
        },
        ru: {
          pageTitle: 'Товары',
          importBtn: 'Импортировать товары',
          addProductBtn: 'Добавить товар',
          nameFilterLabel: 'Название',
          articleFilterLabel: 'Артикул',
          productNameCol: 'Название',
          articleCol: 'Артикул',
          priceCol: 'Цена',
          barcodeCol: 'Штрихкод',
          withoutDiscount: 'Без скидки',
          discounted: 'Со скидкой',
          importDataTitle: 'Импорт товаров',
          selectImportedData: 'Выберите источник данных',
          startImportBtn: 'Выгрузить',
          importProcessingTitle: 'Выполняется',
          importProcessingMsg: 'Импортирование товаров запущено'
        }
      }
    })

    return {
      integrations: integrations.data,
      integrationsLoading: integrations.loading,
      importFrom,
      importProducts,
      tableLoading,
      t
    }
  }
})
</script>
