<template>
  <div class="relative">
    <label v-if="label" class="form-label" :for="id">{{ label }}</label>
    <input
      :id="id"
      ref="maskedPhoneRef"
      v-model="proxyValue"
      class="form-control"
      type="text"
      :placeholder="placeholder"
      :aria-describedby="id"
      :disabled="disabled"
    />
    <VErrorMessage :validationState="v$" />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, toRef } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, minLength } from '@vuelidate/validators'
import Inputmask from 'inputmask'
import VErrorMessage from '@/global-components/v-error-message/Main.vue'

export default defineComponent({
  name: 'VPhoneInput',
  components: { VErrorMessage },
  props: {
    id: String,
    modelValue: String,
    placeholder: String,
    label: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const maskedPhoneRef = ref()
    const phoneLength = ref(11)

    const proxyValue = computed({
      get: () => props.modelValue || '',
      set: () =>
        emit(
          'update:modelValue',
          maskedPhoneRef.value.inputmask.unmaskedvalue()
        )
    })

    onMounted(() => Inputmask('+9 (999) 999-99-99').mask(maskedPhoneRef.value))

    // валидация
    const phoneValue = toRef(props, 'modelValue')

    const validationRules = computed(() => ({
      required: helpers.withMessage('Обязательный параметр', required),
      minLength: helpers.withMessage(
        'Номер слишком короткий',
        minLength(phoneLength.value)
      ),
      countryCode: helpers.withMessage('Код страны должен быть +7', () => {
        return phoneValue.value[0] === '7'
      })
    }))
    const v$ = useVuelidate(validationRules, phoneValue)

    return {
      phoneLength,
      maskedPhoneRef,
      proxyValue,
      v$
    }
  }
})
</script>

<style scoped></style>
