<template>
  <div>
    <div class="mb-3">
      <button
          class="btn mr-2"
          :disabled="!defectiveProducts?.length"
          @click="displayedProducts = defectiveProducts"
      >
        {{ defectiveProducts?.length ? 'Показать брак' : 'Брака нет' }}
      </button>
      <button
          class="btn mr-2"
          :disabled="!productsWithDiscrepancy?.length"
          @click="displayedProducts = productsWithDiscrepancy"
      >
        {{ productsWithDiscrepancy?.length ? 'Показать расхождения' : 'Расхождений нет' }}

      </button>
      <button
          class="btn"
          @click="displayedProducts = waybills"
      >
        Сбросить
      </button>
    </div>
    <LocalTable
        v-if="!$_.isEmpty(displayedProducts)"
        :data="displayedProducts"
        itemKey="waybill-difference-item-"
    >
      <template v-slot:tableHead>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Название</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Артикул</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Стоимость товаров</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Прибыло</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Заявленно</th>
        <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Оприходовано</th>
      </template>
      <template v-slot:tableBody="tableBody">

        <td>{{ tableBody.item.NAME }}</td>
        <td>{{ tableBody.item.ARTICLE }}</td>
        <td>{{ tableBody.item.TOTAL_PRICE }}</td>
        <td>{{ tableBody.item.qty }}</td>
        <td>{{ tableBody.item.qty_orig }}</td>
        <td>{{ tableBody.item.qty_defect }}</td>
      </template>
    </LocalTable>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import LocalTable from '@/components/local-table/Main'

export default defineComponent({
  name: 'MovementProducts',
  components: { LocalTable },
  props: {
    waybills: [Object, Array]
  },
  setup (props) {
    const displayedProducts = ref([...props.waybills])

    const defectiveProducts = computed(() => {
      return props.waybills.filter(item => {
        return item.qty_defect
      })
    })

    const productsWithDiscrepancy = computed(() => {
      return props.waybills.filter(item => {
        return item.qty !== item.qty_orig
      })
    })

    return {
      displayedProducts,
      defectiveProducts,
      productsWithDiscrepancy
    }
  }
})
</script>

<style scoped>

</style>
