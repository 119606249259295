<template>
  <!-- BEGIN: Super Large Slide Over Content -->
  <div id="medium-slide-over-size-preview" class="modal modal-slide-over rtl:right-auto rtl:left-0" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header p-5">
          <h2 class="font-medium text-base mr-auto">
            {{ t('pageTitleText') }}
          </h2>
        </div>
        <div class="modal-body">
          <BasePhoneInput v-model="formData.PHONE"
                          id="newClientNamePhone"
                          :label="t('phoneNumberLabelText')"/>
          <PhoneVerification v-if="lang !== 'ar' && formData.PHONE?.length === 10 || lang === 'ar' && formData.PHONE?.length === 8"
                             :phoneNumber="formData.PHONE"/>
          <div v-if="phoneErrorMessage"
               class="mt-2 text-theme-6">
            {{ phoneErrorMessage }}{{ t('phoneErrorMessageText') }}
          </div>
          <template v-else>
            <BaseInput id="newClientName"
                       :label="t('clientNameLabelText')"
                       :input-group="false"
                       v-model="formData.FIRSTNAME">
              <BaseErrorMessage :validationState="v$" validatedProperty="FIRSTNAME"/>
            </BaseInput>
            <BaseInput id="newClientMiddleName"
                       :label="t('clientMiddleNameLabelText')"
                       :input-group="false"
                       v-model="formData.SECONDNAME">
              <BaseErrorMessage :validationState="v$" validatedProperty="SECONDNAME"/>
            </BaseInput>
            <BaseInput id="newClientSurname"
                       :label="t('clientSurnameLabelText')"
                       :input-group="false"
                       v-model="formData.LASTNAME">
              <BaseErrorMessage :validationState="v$" validatedProperty="LASTNAME"/>
            </BaseInput>
            <BaseInput id="newClientName"
                       type="number"
                       :label="t('clientPurchaseAmount')"
                       :input-group="false"
                       v-model="formData.TOTALSPEND">
              <BaseErrorMessage :validationState="v$" validatedProperty="TOTALSPEND"/>
            </BaseInput>
            <div class="input-form relative mt-4">
              <label class="form-label mb-1 w-full flex flex-col sm:flex-row"> {{ t('clientDateOfBirthLabelText') }} </label>
              <Litepicker v-model="formData.DOB"
                          :options=" {
                            autoApply: true,
                            format: 'DD.MM.YYYY',
                            lang: 'ru',
                            setInitValue: false,
                            dropdowns: {
                              minYear: 1900,
                              months: true,
                              years: true
                            }
                          }"
                          class="form-control"/>
              <BaseErrorMessage :validationState="v$" validatedProperty="DOB"/>
            </div>
            <!-- BEGIN: Hide Slide Over Toggle -->
            <div class="flex items-center mt-8 relative">
              <button class="btn btn-primary mr-4" @click="onAddClient">
                {{ t('addClientBtnText') }}
                <LoadingIcon v-if="sendingData"
                             icon="ball-triangle"
                             color="white"
                             class="'w-4 h-4 ml-1"/>
              </button>
              <button class="btn btn-secondary mr-4" @click="closeModal">
                {{ t('cancelBtnText') }}
              </button>
            </div>
            <!-- END: Hide Slide Over Toggle -->
          </template>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Super Large Slide Over Content -->
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { helpers, required, minValue } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import BaseInput from '@/components/base-input/Main'
import BaseErrorMessage from '@/components/base-error-message/Main'
import PhoneVerification from '@/components/phone-verification/Main'
import BasePhoneInput from '@/components/base-phone-input/Main'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AddClient',
  components: { BasePhoneInput, PhoneVerification, BaseErrorMessage, BaseInput },
  setup() {
    const store = useStore()
    const lang = computed(() => store.getters['main/getLanguage'])
    const phoneErrorMessage = computed(() => store.getters['clients/getPhoneErrorMessage'])
    const formData = ref({})

    const closeModal = () => {
      formData.value = {}
      cash('#medium-slide-over-size-preview').modal('hide')
    }

    watch(() => formData.value.PHONE, () => {
      store.commit('clients/setPhoneErrorMessage', '')
    })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitleText: 'إضافة عميل جديد',
          phoneNumberLabelText: 'رقم هاتف',
          phoneErrorMessageText: '، أدخل رقماً آخر',
          clientNameLabelText: 'اسم',
          clientMiddleNameLabelText: 'الاسم الثاني',
          clientSurnameLabelText: 'لقب',
          clientPurchaseAmount: 'مبلغ الشراء',
          clientDateOfBirthLabelText: 'تاريخ الميلاد',
          requiredFieldText: 'حقل مطلوب',
          minValuePurchaseErrText: 'لا يمكن أن يكون مبلغ الشراء سلبيًا',
          addClientBtnText: 'أضف العميل',
          cancelBtnText: 'إلغاء'
        },
        en: {
          pageTitleText: 'Adding a new client',
          phoneNumberLabelText: 'Phone number',
          phoneErrorMessageText: ', enter other number',
          clientNameLabelText: 'Name',
          clientMiddleNameLabelText: 'Second name',
          clientSurnameLabelText: 'Surname',
          clientPurchaseAmount: 'Purchase amount',
          clientDateOfBirthLabelText: 'Date of birth',
          requiredFieldText: 'Required field',
          minValuePurchaseErrText: 'Purchase amount cannot be negative',
          addClientBtnText: 'Add client',
          cancelBtnText: 'Cancel'
        },
        ru: {
          pageTitleText: 'Добавление нового клиента',
          phoneNumberLabelText: 'Номер телефона',
          phoneErrorMessageText: ', введите другой номер',
          clientNameLabelText: 'Имя',
          clientMiddleNameLabelText: 'Отчество',
          clientSurnameLabelText: 'Фамилия',
          clientPurchaseAmount: 'Сумма покупки',
          clientDateOfBirthLabelText: 'Дата рождения',
          requiredFieldText: 'Обязательное поле',
          minValuePurchaseErrText: 'Сумма покупки не может быть отрицательной',
          addClientBtnText: 'Добавить клиента',
          cancelBtnText: 'Отмена'
        }
      }
    })

    // валидация данных
    const validationRules = {
      FIRSTNAME: { required: helpers.withMessage(t('requiredFieldText'), required) },
      LASTNAME: { required: helpers.withMessage(t('requiredFieldText'), required) },
      SECONDNAME: { required: helpers.withMessage(t('requiredFieldText'), required) },
      DOB: { required: helpers.withMessage(t('requiredFieldText'), required) },
      TOTALSPEND: {
        minValue: helpers.withMessage(t('minValuePurchaseErrText'), minValue(0))
      }
    }
    const v$ = useVuelidate(validationRules, formData)

    // отправка данных
    const sendingData = ref(false)
    const onAddClient = async () => {
      const result = await v$.value.$validate()
      if (result) {
        sendingData.value = true
        store.dispatch('clients/addClient', {
          ...formData.value,
          PHONE: '7' + formData.value.PHONE
        })
          .finally(() => {
            sendingData.value = false
            closeModal()
          })
      }
    }

    return {
      formData,
      onAddClient,
      closeModal,
      sendingData,
      phoneErrorMessage,
      lang,
      t,
      v$
    }
  }
})
</script>
