<template>
  <div>
    <div class="intro-y flex items-center my-5">
      <h2 class="text-lg font-medium mr-auto">{{ t('pageTitleText') }}</h2>
    </div>
    <div class="grid grid-cols-1 gap-2 md:gap-4 lg:gap-6">
      <div class="pos">
        <div class="intro-y">
          <div class="box p-2">
            <div class="pos__tabs nav nav-tabs justify-center grid grid-cols-1 sm:grid-cols-2 gap-1" role="tablist">
              <a class="flex-1 py-2 rounded-md text-center active"
                id="base-tab"
                data-toggle="tab"
                data-target="#base"
                href="javascript:;"
                role="tab"
                aria-controls="base"
                aria-selected="true"
              >
                <ShoppingBagIcon class="w-4 h-4 mr-2" /> {{ t('mainProductDataTabBtnText') }}
              </a>
              <a class="flex-1 py-2 rounded-md text-center"
                 id="add-tab"
                 data-toggle="tab"
                 data-target="#additional"
                 href="javascript:;"
                 role="tab"
                 aria-controls="additional"
                 aria-selected="false"
              >
                <BoxIcon class="w-4 h-4 mr-2" /> {{ t('dataForIntegrationTabBtnText') }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <!-- BEGIN: Основные данные -->
        <div class="intro-y box tab-pane active"
             id="base"
             role="tabpanel"
             aria-labelledby="base-tab">
          <div class="grid grid-cols-1 2xl:grid-cols-2 gap-4 xl:gap-6 p-4">
            <ProductImages
              :pictures="product?.PICTURES"
              @pushPic="product?.PICTURES.push($event)"
            />
            <div class="flex-1 xl:order-1">
              <div class="grid gap-x-5 w-full">
                <div>
                  <div>
                    <label for="update-profile-form-1" class="form-label">
                      {{ t('productNameText') }}
                    </label>
                    <input
                      id="update-profile-form-1"
                      type="text"
                      class="form-control"
                      v-model="product.NAME"
                    />
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-2" class="form-label">
                      {{ t('productArticleText') }}
                    </label>
                    <input
                      id="update-profile-form-2"
                      type="text"
                      class="form-control"
                      v-model="product.ARTICLE"
                    />
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-3" class="form-label">
                      {{ t('productBarcodeText') }}
                    </label>
                    <input
                      id="update-profile-form-3"
                      type="text"
                      class="form-control"
                      v-model="product.BARCODE"
                    />
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-4" class="form-label">
                      {{ t('productCharacterCodeText') }}
                    </label>
                    <input
                      id="update-profile-form-4"
                      type="text"
                      class="form-control"
                      v-model="product.CODE"
                    />
                  </div>
                  <div class="mt-3" v-if="!product.PRICE?.RETAIL_PRICE">
                    <label for="update-profile-form-5" class="form-label">
                      {{ t('productCostText') }}
                    </label>
                    <input
                      id="update-profile-form-5"
                      type="text"
                      class="form-control"
                      v-model="product.PRICE"
                    />
                  </div>
                  <div class="mt-3" v-if="product.PRICE?.RETAIL_PRICE">
                    <label for="update-profile-form-6" class="form-label">
                      {{ t('productCostWithoutDiscountText') }}
                    </label>
                    <input
                      id="update-profile-form-6"
                      type="text"
                      class="form-control"
                      v-model="product.PRICE.PRICE"
                    />
                  </div>
                  <div class="mt-3" v-if="product.PRICE?.RETAIL_PRICE">
                    <label for="update-profile-form-7" class="form-label">
                      {{ t('productCostDiscountedText') }}
                    </label>
                    <input
                      id="update-profile-form-7"
                      type="text"
                      class="form-control"
                      v-model="product.PRICE.RETAIL_PRICE"
                    />
                  </div>
                  <div class="mt-3">
                    <label for="update-profile-form-8" class="form-label">
                      {{ t('productDescriptionText') }}
                    </label>
                    <textarea
                      id="update-profile-form-8"
                      class="form-control"
                      v-model="product.DESCRIPTION"/>
                  </div>
                  <h2 class="font-medium text-base mr-auto mt-5">{{ t('productCharacteristicsSubtitleText') }}</h2>
                  <div class="accordion py-5 accordion-boxed integrations-accordion">
                    <div v-for="(attrSection, key) in product.ATTRIBUTES"
                         :key="key"
                         class="accordion-item rounded-md">
                      <div class="accordion-header">
                        <button class="accordion-button collapsed p-3">
                          {{ key }}
                        </button>
                      </div>
                      <div class="accordion-collapse collapse"
                           style="display: none;">
                        <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed p-3">
                          <div>
                            <div class="grid grid-cols-12 gap-x-5 mb-2">
                              <div class="col-span-5 font-medium">Название</div>
                              <div class="col-span-6 font-medium">Значение</div>
                            </div>
                            <MultiInput
                              v-for="(attr, index) in attrSection"
                              :key="'attr-' + index"
                              :attributeItem="attr"
                              @deleteItem="attrSection.splice(index, 1)"
                            />
                            <button class="btn btn-success mr-1 mb-2" @click="attrSection.push({})">
                              <PlusIcon class="w-5 h-5" /> Добавить
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-end mt-4">
                <button type="button"
                        class="btn btn-primary mr-auto"
                        @click="save">
                  <LoadingIcon v-if="productSaving"
                               color="white"
                               icon="ball-triangle"/>
                  <CheckIcon v-if="!productSaving"
                             class="w-4 h-4 mr-1"/> {{ t('saveBtnText') }}
                </button>
                <a href="javascript:;" class="text-theme-6 flex items-center">
                  <Trash2Icon class="w-4 h-4 mr-1"/> {{ t('deleteProductBtnText') }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Основные данные -->
        <!-- BEGIN: Данные для интеграции -->
        <div class="intro-y box mt-5 tab-pane"
             id="additional"
             role="tabpanel"
             aria-labelledby="add-tab"
        >
          <div></div>
        </div>
        <!-- END: Данные для интеграции -->
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import _ from 'lodash'
import ProductImages from '@/components/products/product-images/Main'

export default {
  name: 'EditProduct',
  components: { ProductImages },
  props: {
    id: String || Number
  },
  setup(props) {
    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitleText: 'لإضافة منتج',
          mainProductDataTabBtnText: 'البيانات الرئيسية',
          dataForIntegrationTabBtnText: 'بيانات للتكامل',
          productNameText: 'اسم',
          productArticleText: 'مادة',
          productBarcodeText: 'الباركود',
          productCharacterCodeText: 'رمز الحرف',
          productCostText: 'تكلفة',
          productCostWithoutDiscountText: 'التكلفة بدون خصم',
          productCostDiscountedText: 'التكلفة المخفضة',
          productDescriptionText: 'وصف المنتج',
          productCharacteristicsSubtitleText: 'خصائص المنتج',
          saveBtnText: 'نقذ',
          deleteProductBtnText: 'حذف المنتج'
        },
        en: {
          pageTitleText: 'Add product',
          mainProductDataTabBtnText: 'Main data',
          dataForIntegrationTabBtnText: 'Data for integration',
          productNameText: 'Name',
          productArticleText: 'Article',
          productBarcodeText: 'Barcode',
          productCharacterCodeText: 'Character code',
          productCostText: 'Cost',
          productCostWithoutDiscountText: 'Cost without discount',
          productCostDiscountedText: 'Discounted cost',
          productDescriptionText: 'Product description',
          productCharacteristicsSubtitleText: 'Product Characteristic',
          saveBtnText: 'Save',
          deleteProductBtnText: 'Delete product'
        },
        ru: {
          pageTitleText: 'Добавить товар',
          mainProductDataTabBtnText: 'Основные данные',
          dataForIntegrationTabBtnText: 'Data for integration',
          productNameText: 'Название',
          productArticleText: 'Артикул',
          productBarcodeText: 'Штрих-код',
          productCharacterCodeText: 'Символьный код',
          productCostText: 'Цена',
          productCostWithoutDiscountText: 'Цена без скидки',
          productCostDiscountedText: 'Цена со скидкой',
          productDescriptionText: 'Описание товара',
          productCharacteristicsSubtitleText: 'Характеристики',
          saveBtnText: 'Сохранить',
          deleteProductBtnText: 'Удалить товар'
        }
      }
    })
    const store = useStore()
    const router = useRouter()
    const productItem = computed(() => store.getters['products/getProductItem'])
    const product = ref({})
    const productSaving = ref(false)

    const save = () => {
      const methodUrl = props.id ? 'products/updateProduct' : 'products/addProduct'
      productSaving.value = true
      store.dispatch(methodUrl, {
        ...product.value,
        PICTURES: JSON.stringify(product.value.PICTURES),
        PRICE: JSON.stringify(product.value.PRICE),
        ATTRIBUTES: JSON.stringify(product.value.ATTRIBUTES)
      }).then(() => {
        productSaving.value = false
        router.push({ name: 'products-list', params: { page: '1', pageSize: '10' } })
      })
    }

    onMounted(() => {
      if (props.id) {
        store.dispatch('products/loadProductItem', { id: props.id })
          .then(() => (product.value = _.cloneDeep(productItem.value)))
      }
    })

    return {
      product,
      productSaving,
      save,
      t
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion.accordion-boxed.integrations-accordion .accordion-item {
  padding: 0;

  .accordion-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
