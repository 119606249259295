import { ref } from 'vue'

const useReRenderingForComponent = () => {
  const randomNumberMultiplier = 10000
  const newVueKeyForMovementsImport = ref()
  const setNewVueKeyForImport = () => {
    newVueKeyForMovementsImport.value = Math.floor(Math.random() * randomNumberMultiplier) + '-v-key'
  }

  return {
    newVueKeyForMovementsImport,
    setNewVueKeyForImport
  }
}

export { useReRenderingForComponent }
