<template>
  <IdNotFound v-if="!orderLoading && orderData === false"
              class="intro-y box w-full"
              :essenceName="t('orderEssenceNext')"/>
  <template v-else>
    <div v-if="orderLoading" class="intro-y box w-full h-50 mt-5">
      <IntroPreloader/>
    </div>
    <div v-if="!orderLoading"
         class="intro-y box mt-4 p-4">
      <div class="flex justify-between items-start mb-6">
        <h2 class="text-xl font-medium">{{ t('pageTitle') }}</h2>
        <router-link :to="{ name: 'order-logistics-price-calculation', params: { id } }"
                     class="btn btn-primary shadow-md max-w-max"
                     tag="a">
          {{ t('calculateDeliveryCostBtnText') }}
        </router-link>
      </div>
      <div class="overflow-x-auto">
        <table class="table p-1">
          <thead>
          <tr class="bg-gray-200 dark:bg-dark-1">
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ t('productNameColText') }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ t('productArticleColText') }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ t('productQuantityColText') }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ t('productPriceColText') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in orderData?.PRODUCTS"
              :key="item.ARTICLE"
              :class="{ 'bg-gray-200 dark:bg-dark-1': index % 2 === 1 }">
            <td class="border-b whitespace-nowrap">
              {{ item.NAME }}
            </td>
            <td class="border-b whitespace-nowrap">
              {{ item.ARTICLE }}
            </td>
            <td class="border-b whitespace-nowrap">
              {{ item.CNT }}
            </td>
            <td class="border-b whitespace-nowrap">
              {{ $h.cutFraction(item.PRICE) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="pb-2 px-2 mt-6">
        <h3 class="text-lg font-medium mb-2">{{ t('orderInfoSubtitleText') }}</h3>
        <div class="grid grid-cols-2 gap-2">
          <div>
            <span class="text-sm font-medium">{{ t('orderStatusText') }}:</span>
            {{ orderData?.STATUS }}
          </div>
          <div>
            <span class="text-sm font-medium">{{ t('lastModificationDateText') }}:</span>
            <template v-if="$h.formatUnixDate(orderData?.LAST_CHANGE_DATE, 'DD.MM.YYYY') === 'Invalid Date'">
              &nbsp;{{ orderData?.LAST_CHANGE_DATE }}
            </template>
            <template v-else>
              &nbsp;{{ $h.formatUnixDate(orderData?.LAST_CHANGE_DATE, ('DD.MM.YYYY')) }}
            </template>
          </div>
          <div>
            <span class="text-sm font-medium">{{ t('orderTotalText') }}:</span>
            {{ $h.cutFraction(orderData?.TOTAL_PRICE) }}
          </div>
          <div>
            <span class="text-sm font-medium">{{ t('stageText') }}:</span>
<!--            {{ orderData?.STAGE.NAME }}-->
          </div>
          <div>
            <span class="text-sm font-medium">{{ t('stageNumberText') }}:</span>
            {{ orderData?.STAGE.NUMBER }}
          </div>
          <div>
            <span class="text-sm font-medium">{{ t('stageTypeText') }}:</span>
            {{ orderData?.STAGE.TYPE }}
          </div>
          <div>
            <span class="text-sm font-medium">{{ t('orderSourceText') }}:</span>
            {{ orderData?.STAGE.NAME }}
          </div>
          <div>
            <span class="text-sm font-medium">{{ t('externalIDText') }}:</span>
            {{ orderData?.ID_ON_INTEGRATION }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="!orderLoading" class="accordion order-details mt-8">
      <div v-if="!$_.isEmpty(orderData?.ALL_ORDER_DATA?.SDT?.data)"
           class="accordion-item intro-y box p-4 retail-crm-details">
        <div class="accordion-header">
          <button class="accordion-button collapsed text-xl font-medium">
            Заказ в SDT
          </button>
        </div>
        <div class="accordion-collapse collapse">
          <SdtInfo :data="orderData?.ALL_ORDER_DATA.SDT.data"/>
        </div>
      </div>

      <div v-if="!orderLoading && !$_.isEmpty(orderData?.ALL_ORDER_DATA?.RETAIL)"
           class="accordion-item intro-y box mt-4 p-4 retail-crm-details">
        <div class="accordion-header">
          <button class="accordion-button collapsed text-xl font-medium">
            Заказ в RetailCRM
          </button>
        </div>
        <div class="accordion-collapse collapse">
          <a class="link text-theme-10 dark:text-theme-30" target="_blank" :href="orderData?.ALL_ORDER_DATA?.RETAIL">{{ orderData?.ALL_ORDER_DATA?.RETAIL }}</a>
        </div>
      </div>
      <div class="accordion-item intro-y box mt-4 p-4 tech-data">
        <div class="accordion-header">
          <button class="accordion-button collapsed text-xl font-medium"
                  @click="onShowTechData">
            Технические данные
          </button>
        </div>
        <div class="accordion-collapse collapse">
          <vue-json-pretty :data="orderLog"
                           :deep="3"
                           :showDoubleQuotes="false"/>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import SdtInfo from '@/components/order/sdt-info/Main'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import IdNotFound from '@/components/id-not-found/Main'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'OrderDetails',
  components: { IdNotFound, SdtInfo, VueJsonPretty },
  props: {
    id: String
  },
  setup(props) {
    const order = useStoreDataGetting(
      'orders/getCurrentOrder',
      'orders/loadOrderByID',
      { id: props.id })

    //  отображение логов
    const store = useStore()
    const orderLog = computed(() => store.getters['orders/getOrderLog'])
    const onShowTechData = () => store.dispatch('orders/loadOrderLogById', { id: order.data.value.ID_ON_INTEGRATION })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          orderEssenceNext: 'طلب',
          pageTitle: 'قائمة المنتجات المطلوبة',
          calculateDeliveryCostBtnText: 'حساب تكلفة التسليم',
          productNameColText: 'اسم',
          productArticleColText: 'مادة',
          productPriceColText: 'سعر',
          productQuantityColText: 'حساب تكلفة التسليم',
          orderInfoSubtitleText: 'معلومات الطلب',
          orderStatusText: 'وضع',
          orderTotalText: 'إجمالي الطلب',
          stageNumberText: 'المرحلة',
          orderSourceText: 'مصدر الطلب',
          lastModificationDateText: 'تاريخ التعديل الأخير',
          stageText: 'مرحلة',
          stageTypeText: 'تاريخ التعديل الأخير',
          externalIDText: 'هوية خارجية'
        },
        en: {
          orderEssenceNext: 'Order',
          pageTitle: 'Ordered products list',
          calculateDeliveryCostBtnText: 'Calculate delivery cost',
          productNameColText: 'Name',
          productArticleColText: 'Article',
          productPriceColText: 'Price',
          productQuantityColText: 'Quantity',
          orderInfoSubtitleText: 'Order info',
          orderStatusText: 'Status',
          orderTotalText: 'Order total',
          stageNumberText: 'Stage number',
          orderSourceText: 'Order source',
          lastModificationDateText: 'Last modification date',
          stageText: 'Stage',
          stageTypeText: 'Last modification date',
          externalIDText: 'External ID'
        },
        ru: {
          orderEssenceNext: 'Заказ',
          pageTitle: 'Список заказанных товаров',
          calculateDeliveryCostBtnText: 'Рассчитать стоимость доставки',
          productNameColText: 'Название',
          productArticleColText: 'Артикул',
          productPriceColText: 'Цена',
          productQuantityColText: 'Количество',
          orderInfoSubtitleText: 'Информация о заказе',
          orderStatusText: 'Статус',
          orderTotalText: 'Сумма заказа',
          stageNumberText: 'Номер этапа',
          orderSourceText: 'Источник заказа',
          lastModificationDateText: 'Дата последних изменений',
          stageText: 'Этап',
          stageTypeText: 'Тип этапа',
          externalIDText: 'Внешний ID'
        }
      }
    })

    return {
      orderData: order.data,
      orderLoading: order.loading,
      orderLog,
      onShowTechData,
      t
    }
  }
})
</script>

<style lang="scss">
  .tech-data {
    .vjs-value {
      @apply text-theme-10 dark:text-theme-15;
    }
  }
</style>
