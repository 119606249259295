<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ id ? t('pageTitleEditingText') : t('pageTitleAdditionText') }} {{ t('pageTitleStaticText') }}  {{formData.NAME}}</h2>
    </div>
    <div class="grid grid-cols-12 gap-12 mt-12">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('loyaltyStatusNameLabelText') }}</label>
            <input
              id="crud-form-1"
              v-model="formData.NAME"
              type="text"
              class="form-control w-full"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('characterCodeLabelText') }}</label><input
              id="crud-form-1"
              v-model="formData.CODE"
              type="text"
              class="form-control w-full"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('discountLabelText') }}</label>
            <input
              id="crud-form-1"
              v-model="formData.DISCOUNT"
              type="text"
              class="form-control w-full"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('cashbackPercentageLabelText') }}</label>
            <input
              id="crud-form-1"
              v-model="formData.CASHBACK"
              type="text"
              class="form-control w-full"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('fixedCashbackLabelText') }}</label>
            <input
              id="crud-form-1"
              v-model="formData.CASHBACK_FIX"
              type="text"
              class="form-control w-full"
              placeholder="Фиксированный кешбек"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('purchasesAmountFromLabelText') }}</label>
            <input
              id="crud-form-1"
              v-model="formData.SUM_FROM"
              type="text"
              class="form-control w-full"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">{{ t('purchasesAmountUpToLabelText') }}</label>
            <input
              id="crud-form-1"
              v-model="formData.SUM_TO"
              type="text"
              class="form-control w-full"
              required
            />
          </div>
          <div class="mt-3">
            <label>{{ t('isActiveText') }}</label>
            <div class="mt-2">
              <input type="checkbox" v-model="active" class="form-check-switch" />
            </div>
          </div>
          <div class="text-right mt-5">
            <router-link :to="{ name: 'loyalty' }" tag="button" class="btn btn-outline-secondary w-24 mr-1">
              {{ t('cancelBtnText') }}
            </router-link>
            <button class="btn btn-primary max-w-max"
                    type="button"
                    @click.prevent="saveLoyaltyStatus(id, '/loyalty')">
              {{ t('saveBtnText') }}
              <LoadingIcon icon="ball-triangle" v-if="sendingData" class="'w-4 h-4 ml-1"/>
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import { useDataSending } from '@/use/useDataSending'

export default defineComponent({
  name: 'LoyaltyStatus',
  props: {
    id: String
  },
  setup(props) {
    let currentLoyaltyStatus
    if (props.id) {
      currentLoyaltyStatus = useStoreDataGetting(
        'loyaltyStatuses/getCurrentLoyaltyStatus',
        'loyaltyStatuses/loadLoyaltyStatusByID',
        { id: props.id || '' })
    }

    // сохранение данных статуса лояльности
    const { active, formData, sendData, sendingData } = useDataSending(
      'loyaltyStatuses/updateLoyaltyStatuses',
      'loyaltyStatuses/addLoyaltyStatuses',
      currentLoyaltyStatus?.data)

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          pageTitleEditingText: 'تحرير',
          pageTitleAdditionText: 'إضافة',
          pageTitleStaticText: 'تكامل',
          loyaltyStatusNameLabelText: 'حالة الولاء',
          characterCodeLabelText: 'رمز الحرف',
          discountLabelText: 'خصم',
          cashbackPercentageLabelText: 'النسبة المئوية لاسترداد النقود',
          fixedCashbackLabelText: 'استرداد نقدي ثابت',
          purchasesAmountFromLabelText: 'كمية المشتريات من',
          purchasesAmountUpToLabelText: 'كمية المشتريات حتى',
          isActiveText: 'نشط',
          saveBtnText: 'نقذ',
          cancelBtnText: 'إلغاء'
        },
        en: {
          pageTitleEditingText: 'Editing',
          pageTitleAdditionText: 'Addition',
          pageTitleStaticText: 'loyalty status',
          loyaltyStatusNameLabelText: 'Name',
          characterCodeLabelText: 'Character code',
          discountLabelText: 'Discount',
          cashbackPercentageLabelText: 'Cashback percentage',
          fixedCashbackLabelText: 'Fixed cashback',
          purchasesAmountFromLabelText: 'Amount of purchases from',
          purchasesAmountUpToLabelText: 'Amount of purchases up to',
          isActiveText: 'Active',
          saveBtnText: 'Save',
          cancelBtnText: 'Cancel'
        },
        ru: {
          pageTitleEditingText: 'Редактирование',
          pageTitleAdditionText: 'Добавление',
          pageTitleStaticText: 'статуса лояльности',
          loyaltyStatusNameLabelText: 'Название',
          characterCodeLabelText: 'Символьный код',
          discountLabelText: 'Скидка',
          cashbackPercentageLabelText: '% кэшбэка',
          fixedCashbackLabelText: 'Фиксированный кэшбэк',
          purchasesAmountFromLabelText: 'Сумма покупки от',
          purchasesAmountUpToLabelText: 'Сумма покупки до',
          isActiveText: 'Активен',
          saveBtnText: 'Сохранить',
          cancelBtnText: 'Отмена'
        }
      }
    })

    return {
      formData,
      saveLoyaltyStatus: sendData,
      loading: currentLoyaltyStatus?.loading,
      sendingData,
      active,
      t
    }
  }
})
</script>
