<template>
  <td class="text-right border-b dark:border-dark-5 w-32 font-medium"></td>
  <td v-if="item?.return_data?.cost" class="text-right border-b dark:border-dark-5 w-32 font-medium">
    <div class="flex justify-end">
      <div class="text-gray-600 whitespace-nowrap mr-2 ml-2">
        Дней: 1
      </div>|
      <div class="ml-0.5 w-20">
        {{ Math.round(item?.return_data.cost) }}&nbsp;руб.
      </div>
    </div>
  </td>
  <td v-else class="text-right border-b dark:border-dark-5 w-32 font-medium">
    <CalculationDataError/>
  </td>
</template>

<script>
import { defineComponent } from 'vue'
import CalculationDataError
from '@/components/logistics-price-calculation-form/calculation-result-items/calculation-data-error/Main'

export default defineComponent({
  name: 'TakengoCalculation',
  components: { CalculationDataError },
  props: {
    item: Object
  }
})
</script>

<style scoped>

</style>
