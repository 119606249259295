<template>
  <td class="text-left border-b dark:border-dark-5 w-32 font-medium"></td>
  <td class="text-right border-b dark:border-dark-5 w-32 font-medium">
    <div v-if="item.error === undefined || item.length > 0">
      <div v-for="(term, termIndex) in item" :key="termIndex + 'shiptor'" >
        <div class="flex justify-end" v-if="term?.cost">
          <div class="whitespace-nowrap mr-2">
            {{ term?.method?.name }}
          </div>|
          <div class="text-gray-600 whitespace-nowrap mr-2 ml-2">
            Дней: {{ term?.max_days }}
          </div>|
          <div class="ml-0.5 w-20">
            {{ Math.round(term?.cost?.total?.sum) }}&nbsp;руб.
          </div>
        </div>
      </div>
    </div>
    <CalculationDataError :errorMessage="item.error" v-else/>
  </td>
</template>

<script>
import { defineComponent } from 'vue'
import CalculationDataError
from '@/components/logistics-price-calculation-form/calculation-result-items/calculation-data-error/Main'

export default defineComponent({
  name: 'ShiptorCalculation',
  components: { CalculationDataError },
  props: {
    item: Object
  }
})
</script>

<style scoped>

</style>
