<template>
  <div>
    <div class="flex">
      <DarkModeSwitcher class="mr-2"/>
      <LanguageSwitcher height="h-12"/>
    </div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="w-6"
              :src="require(`@/assets/images/logo.png`)"
            />
            <span class="text-white text-lg ml-3">
              Simple <span class="font-medium">Fillment</span>
            </span>
          </a>
          <div class="my-auto">
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              {{ t('signInMsg1') }} <br> {{ t('signInMsg2') }}
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              {{ t('signInMsg3') }}
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              {{ t('auth') }}

            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center"></div>
            <div class="intro-x mt-8">
              <input
                v-model="formData.PHONE"
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                :placeholder="t('phone')"
                dir="ltr"
              />
              <input
                v-model="formData.PASSWORD"
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                :placeholder="t('password')"
              />
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
<!--              <div class="flex items-center mr-auto">-->
<!--                <input-->
<!--                  id="remember-me"-->
<!--                  type="checkbox"-->
<!--                  class="form-check-input border mr-2"-->
<!--                />-->
<!--                <label class="cursor-pointer select-none" for="remember-me">-->
<!--                  Запомнить меня-->
<!--                </label>-->
<!--              </div>-->
              <a href="">{{ t('forgotPass') }}</a>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                @click="login"
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              >
                {{ t('logIn') }}
              </button>
              <button
                @click="goToRegister"
                class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
              >
                {{ t('registration') }}
              </button>
            </div>
            <div
              class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"
            >
              {{ t('confirmWith') }}<br />
              <a class="text-theme-1 dark:text-theme-10" href="">
                {{ t('rulesOfUse') }}
              </a>
              {{ t('and') }}
              <a class="text-theme-1 dark:text-theme-10" href="">
                {{ t('privacyPolicy') }}
              </a>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import LanguageSwitcher from '@/components/language-switcher/Main.vue'
import router from '@/router'
import store from '@/store'

export default defineComponent({
  components: {
    DarkModeSwitcher,
    LanguageSwitcher
  },
  setup() {
    const formData = ref({ PASSWORD: null, PHONE: null })
    const goToHome = () => router.push('/')
    const goToRegister = () => router.push('/register')

    const login = () => {
      store.dispatch('auth/authRequest', formData.value)
        .then(() => goToHome())
        .catch(res => alert(res))
    }

    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    const { t } = useI18n({
      messages: {
        ar: {
          auth: 'إذن',
          phone: 'هاتف',
          password: 'كلمة مرور',
          forgotPass: 'هل نسيت كلمة السر ؟',
          logIn: 'سجل الدخول',
          registration: 'تسجيل',
          confirmWith: 'باستخدام منصتنا التي تتفق معها',
          rulesOfUse: 'قواعد استخدام',
          privacyPolicy: 'سياسة الخصوصية',
          and: 'و',
          signInMsg1: 'فقط بضع نقرات',
          signInMsg2: 'أخرى لتسجيل الدخول.',
          signInMsg3: 'قم بإدارة جميع مراحل ملءك في مكان واحد'
        },
        en: {
          auth: 'Authorization',
          phone: 'Phone number',
          password: 'Password',
          forgotPass: 'Forgot your password?',
          logIn: 'Log in',
          registration: 'Registration',
          confirmWith: 'By using our platform you agree with',
          rulesOfUse: 'Rules of use',
          privacyPolicy: 'Privacy Policy',
          and: 'and',
          signInMsg1: 'A few more clicks to',
          signInMsg2: 'sign in to your account.',
          signInMsg3: 'Manage all stages of your fullfillment in one place'
        },
        ru: {
          auth: 'Авторизация',
          phone: 'Телефон',
          password: 'Пароль',
          forgotPass: 'Забыли пароль,',
          logIn: 'Войти',
          registration: 'Регистрация',
          confirmWith: 'Используя нашу платформу вы соглашаетесь с',
          rulesOfUse: 'Правилами использования',
          privacyPolicy: 'Политикой конфиденциальности',
          and: 'и',
          signInMsg1: 'Всего пара кликов',
          signInMsg2: 'для регистрации аккаунта',
          signInMsg3: 'Управление всеми этапами вашего фулфилмента в одном месте'
        }
      }
    })

    return {
      formData,
      goToHome,
      goToRegister,
      login,
      t
    }
  }
})
</script>
