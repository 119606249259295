export default {
  1: 'Новый',
  10: 'Оформлен',
  11: 'Ожидает товара',
  12: 'Ожидает уточнения',
  13: 'Готов к сбору на складе',
  14: 'Готов к сбору на складе',
  15: 'Собирается',
  16: 'Собран',
  17: 'Отгружен в службу доставки',
  18: 'Отгружен в службу доставки',
  20: 'Выдан курьеру',
  21: 'В службе доставки',
  22: 'Отправлен по почте',
  25: 'Возвращается',
  35: 'Отменен',
  36: 'Возвращен',
  50: 'Выполнен',
  51: 'Потерян'
}
