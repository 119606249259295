<template>
  <div v-if="!loading">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ id ? t('pageTitleEditingText') : t('pageTitleAdditionText') }} {{ t('pageTitleStaticText') }} {{formData.NAME}}</h2>
    </div>
    <div class="grid grid-cols-12 gap-12 mt-12">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div>
            <label for="crud-form-1" class="form-label">{{ t('integrationNameLabelText') }}</label>
            <input
              id="crud-form-1"
              v-model="formData.NAME"
              type="text"
              class="form-control w-full"
              required
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-2" class="form-label">{{ t('integrationTypeLabelText') }}</label>
            <TomSelect id="crud-form-2" v-model="formData.TYPE" class="w-full">
              <option value="IMSite">{{ t('integrationTypeOnlineStoreText') }}</option>
              <option value="Provider">{{ t('integrationTypeProviderText') }}</option>
              <option value="SDT">SDT</option>
              <option value="RetailCRM">RetailCRM</option>
              <option value="BX24">Битрикс 24</option>
              <option value="Ecwid">Ecwid</option>
              <option value="WB">Wildberries</option>
              <option value="Ozon">Ozon</option>
              <option value="Yandex">YandexMarket</option>
              <option value="Sber">SberMarket</option>
            </TomSelect>
          </div>
          <div class="mt-3">
            <label class="form-label">{{ t('accessesForApiText') }}</label>
            <div class="sm:grid grid-cols-2 gap-2">
              <div class="input-group" v-show="formData.TYPE !== 'BX24'">
                <div id="input-group-3" class="input-group-text">{{ t('tokenLabelText') }}</div>
                <input
                  v-model="formData.TOKEN"
                  type="text"
                  class="form-control"
                  aria-describedby="input-group-3"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0" v-show="formData.TYPE === 'SDT' || formData.TYPE === 'RetailCRM' || formData.TYPE === 'IMSite' || formData.TYPE === 'BX24'">
                <div id="input-group-4_3" class="input-group-text">{{ t('endpointLabelText') }}</div>
                <input
                  v-model="formData.ENDPOINT"
                  type="text"
                  class="form-control"
                  :placeholder="t('endpointPlaceholderText')"
                  aria-describedby="input-group-4"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0" v-show="formData.TYPE==='WB'">
                <div id="input-group-4" class="input-group-text">{{ t('statisticalTokenLabelText') }}</div>
                <input
                  v-model="formData.TOKEN_STAT"
                  type="text"
                  class="form-control"
                  aria-describedby="input-group-4"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0" v-show="formData.TYPE==='Ozon' || formData.TYPE==='Ecwid'">
                <div id="input-group-4_2" class="input-group-text">{{ t('clientIdLabelText') }}</div>
                <input
                  v-model="formData.CLIENT_ID"
                  type="text"
                  class="form-control"
                  aria-describedby="input-group-4_2"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0" v-show="formData.TYPE==='Yandex'">
                <div id="input-group-4_2" class="input-group-text">{{ t('companyNumberLabelText') }}</div>
                <input
                  v-model="formData.CLIENT_ID"
                  type="text"
                  class="form-control"
                  aria-describedby="input-group-4_2"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0" v-show="formData.TYPE==='Yandex'">
                <div id="input-group-4" class="input-group-text">{{ t('authTokenLabelText') }}</div>
                <input
                  v-model="formData.TOKEN_STAT"
                  type="text"
                  class="form-control"
                  aria-describedby="input-group-4"
                />
              </div>
              <div class="input-group mt-2 sm:mt-0" v-show="formData.TYPE==='Ecwid'">
                <div id="input-group-4_2" class="input-group-text">{{ t('clientSecretLabelText') }}</div>
                <input
                  v-model="formData.TOKEN_STAT"
                  type="text"
                  class="form-control"
                  :placeholder="t('clientSecretLabelText')"
                  aria-describedby="input-group-4_2"
                />
              </div>
            </div>
          </div>
          <div class="mt-3">
            <label>{{ t('isActiveText') }}</label>
            <div class="mt-2">
              <input type="checkbox" v-model="active" class="form-check-switch" />
            </div>
          </div>
          <div class="text-right mt-5">
            <router-link :to="{ name: 'integrations' }" tag="button" class="btn btn-outline-secondary w-24 mr-1">
              {{ t('cancelBtnText') }}
            </router-link>
            <button class="btn btn-primary max-w-max"
                    type="button"
                    @click.prevent="saveIntegration(id, '/integrations')">
              {{ t('saveBtnText') }}
              <LoadingIcon icon="ball-triangle" v-if="sendingData" class="'w-4 h-4 ml-1"/>
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import { useDataSending } from '@/use/useDataSending'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    id: String
  },
  setup(props) {
    // загрузка текущей интеграции
    let currentIntegration
    if (props.id) {
      currentIntegration = useStoreDataGetting(
        'integrations/getCurrentIntegration',
        'integrations/loadIntegrationById',
        { id: props.id })
    }

    // сохранение данных интеграции
    const { active, formData, sendData, sendingData } = useDataSending(
      'integrations/updateIntegration',
      'integrations/addIntegration',
      currentIntegration?.data)
    formData.TYPE = 'IMSite'

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          integrationTypeOnlineStoreText: 'متجر على الإنترنت',
          integrationTypeProviderText: 'مزود',
          pageTitleEditingText: 'تحرير',
          pageTitleAdditionText: 'إضافة',
          pageTitleStaticText: 'تكامل',
          integrationNameLabelText: 'اسم',
          integrationTypeLabelText: 'تاريخ التعديل الأخير',
          accessesForApiText: 'الوصول إلى تشغيل API',
          tokenLabelText: 'رمز',
          endpointLabelText: 'نقطة نهاية',
          endpointPlaceholderText: 'عنوان واجهة برمجة التطبيقات',
          authTokenLabelText: 'رمز التفويض',
          statisticalTokenLabelText: 'رم إحصائي',
          companyNumberLabelText: 'معرف عميل',
          clientIdLabelText: 'معرف عميل',
          clientSecretLabelText: 'وسر العميل',
          isActiveText: 'نشط',
          saveBtnText: 'نقذ',
          cancelBtnText: 'إلغاء'
        },
        en: {
          pageTitleEditingText: 'Editing',
          pageTitleAdditionText: 'Addition',
          pageTitleStaticText: 'integration',
          integrationNameLabelText: 'Name',
          integrationTypeLabelText: 'Type',
          integrationTypeOnlineStoreText: 'Online store',
          integrationTypeProviderText: 'Provider',
          accessesForApiText: 'Accesses  for API operation',
          tokenLabelText: 'Token',
          endpointLabelText: 'Endpoint',
          endpointPlaceholderText: 'API address',
          authTokenLabelText: 'Authorization token',
          statisticalTokenLabelText: 'Statistical token',
          companyNumberLabelText: 'Company number',
          clientIdLabelText: 'Client ID',
          clientSecretLabelText: 'Client Secret',
          isActiveText: 'Active',
          saveBtnText: 'Save',
          cancelBtnText: 'Cancel'
        },
        ru: {
          pageTitleEditingText: 'Редактирование',
          pageTitleAdditionText: 'Добавление',
          pageTitleStaticText: 'интеграции',
          integrationNameLabelText: 'Название',
          integrationTypeLabelText: 'Тип',
          integrationTypeOnlineStoreText: 'Интернет-магазин',
          integrationTypeProviderText: 'Поставщик',
          accessesForApiText: 'Доступы для работы API',
          tokenLabelText: 'Токен',
          endpointLabelText: 'Endpoint',
          endpointPlaceholderText: 'API адрес',
          authTokenLabelText: 'Авторизационный токен',
          statisticalTokenLabelText: 'Токен статистики',
          companyNumberLabelText: 'Номер кампании',
          clientIdLabelText: 'ID клиента',
          clientSecretLabelText: 'Client Secret',
          isActiveText: 'Активен',
          saveBtnText: 'Сохранить',
          cancelBtnText: 'Отмена'
        }
      }
    })

    return {
      active,
      formData,
      saveIntegration: sendData,
      loading: currentIntegration?.loading,
      sendingData,
      t
    }
  }
})
</script>
