export default {
  messages: {
    ar: {
      dictCancelUploadConfirmationText: 'هل أنت متأكد أنك تريد التوقف عن تحميل الملف ؟',
      uploadFileCancelBtnText: 'توقف',
      deleteUploadedFileBtnText: 'حذف',
      uploadAreaTitleText: 'اسحب هنا صور البضائع لتحميلها',
      uploadAreaDescriptionText: 'الحجم الأقصى لملف 5MB واحد، لا يزيد عن 20 صورة',
      deleteProductPicturesBtnText: 'احذف الصور'
    },
    en: {
      dictCancelUploadConfirmationText: 'Are you sure you want to Cancel downloading the file?',
      uploadFileCancelBtnText: 'Cancel',
      deleteUploadedFileBtnText: 'Delete',
      uploadAreaTitleText: 'Drag here photos of goods to download',
      uploadAreaDescriptionText: 'Maximum size of one 5MB file, not more than 20 photos',
      deleteProductPicturesBtnText: 'Delete pictures'
    },
    ru: {
      dictCancelUploadConfirmationText: 'Вы уверены что хотите прервать загрузку файла?',
      uploadFileCancelBtnText: 'Остановить',
      deleteUploadedFileBtnText: 'Удалить',
      uploadAreaTitleText: 'Перетащите сюда фото товара для загрузки',
      uploadAreaDescriptionText: 'Максимальный размер одного файла 5МБ, не больше 20 фото',
      deleteProductPicturesBtnText: 'Удалить изображения'
    }
  }
}
