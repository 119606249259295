<template>
  <div>
    <DarkModeSwitcher />
    <LanguageSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Register Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="w-6"
              :src="require(`@/assets/images/logo.png`)"
            />
            <span class="text-white text-lg ml-3">
              Simple <span class="font-medium">Fillment</span>
            </span>
          </a>
          <div class="my-auto">
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              A few more clicks to <br />
              sign up to your account.
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              Manage all your e-commerce accounts in one place
            </div>
          </div>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Register Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Регистрация
            </h2>
            <div
              class="intro-x mt-2 text-gray-500 dark:text-gray-500 xl:hidden text-center"
            ></div>
            <div class="intro-x mt-8">
              <input
                type="text"
                v-model="regData.NAME"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                placeholder="Имя"
              />
              <input
                type="text"
                v-model="regData.LOGIN"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                placeholder="Логин"
              />
              <input
                type="text"
                v-model="regData.PHONE"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                placeholder="Телефон"
              />
              <input
                type="password"
                v-model="regData.PASSWORD"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                placeholder="Пароль"
              />
            </div>
            <div
              class="intro-x flex items-center text-gray-700 dark:text-gray-600 mt-4 text-xs sm:text-sm"
            >
              <input
                id="remember-me"
                type="checkbox"
                class="form-check-input border mr-2"
              />
              <label class="cursor-pointer select-none" for="remember-me">
                Согласен с
              </label>
              <a class="text-theme-1 dark:text-theme-10 ml-1" href="">
                Политикой конфиденциальности </a
              >.
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                @click="sendRegisterData"
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              >
                Регистрация
              </button>
              <button
                @click="goToLogin"
                class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
              >
                Авторизация
              </button>
            </div>
          </div>
        </div>
        <!-- END: Register Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import axios from 'axios'
import router from '@/router'

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  setup() {
    const regData = ref({
      NAME: null,
      PHONE: null,
      LOGIN: null,
      PASSWORD: null
    })
    const goToLogin = () => router.push('/login')

    const sendRegisterData = () => {
      if (
        regData.value.NAME !== null &&
        regData.value.PHONE !== null &&
        regData.value.LOGIN !== null &&
        regData.value.PASSWORD !== null
      ) {
        axios
          .post(process.env.VUE_APP_API_URL + 'companies/add/', {
            data: regData.value
          })
          .then(response => {
            const data = response.data
            if (data.success) goToLogin()
            if (data.error) alert(data.error)
          })
      } else {
        alert('Необходимо заполнить все поля')
      }
    }

    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    return {
      regData,
      goToLogin,
      sendRegisterData
    }
  }
})
</script>
