<template>
  <td class="text-left border-b dark:border-dark-5 w-32 font-medium">
    <div v-if="item?.tariffs?.available_tariffs !== undefined" >
      <div v-for="(service, serviceIndex) in item.tariffs.available_tariffs"
           :key="serviceIndex"
           class="flex justify-end">
        <div class="text-gray-600 whitespace-nowrap mr-2" >
          {{ service.title }}
        </div>|
        <div class="ml-2">
          от&nbsp;{{ service.minimal_price }}&nbsp;руб.
        </div>
      </div>
    </div>
  </td>
  <td class="text-right border-b dark:border-dark-5 w-32 font-medium">
    <div class="flex justify-end" v-if="!item?.error">
      <div class="ml-0.5 w-20">
        {{ Math.round(item?.cost) }}&nbsp;руб.
      </div>
    </div>
    <CalculationDataError v-else :error-message="item?.error"/>
  </td>
</template>

<script>
import CalculationDataError
from '@/components/logistics-price-calculation-form/calculation-result-items/calculation-data-error/Main'

export default {
  name: 'YandexDeliveryCalculation',
  components: { CalculationDataError },
  props: {
    item: Object
  }
}
</script>

<style scoped>

</style>
