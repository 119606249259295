import axios from 'axios'
import router from '@/router'
import { notifications as $notifications } from '@/utils/showNotification'

const state = {}

const getters = {
  getTokenAuth: (state, getters, rootState, rootGetters) => {
    return rootGetters['auth/getToken']
  },
  getRefreshTokenAuth: (state, getters, rootState, rootGetters) => {
    return rootGetters['auth/getRefreshToken']
  }
}

const actions = {
  sendRequest: ({ dispatch, getters, commit }, data) => {
    return new Promise((resolve, reject) => {
      let postData = {}
      if (data.data !== undefined) postData.data = data.data
      if (data.filter !== undefined) postData.filter = data.filter
      if (data.nav !== undefined) postData.nav = data.nav
      if (data.formData !== undefined) {
        postData = data.formData
        postData.append('token', getters.getTokenAuth)
      } else {
        postData.token = getters.getTokenAuth
      }
      const axiosParams = {
        url: process.env.VUE_APP_API_URL + data.url,
        data: postData,
        method: data.method ?? 'POST',
        responseType: data.responseType ?? 'json'
      }
      if (data.headers !== undefined) axiosParams.headers = data.headers
      axios(axiosParams)
        .then(resp => {
          if (resp.data?.error) {
            if (resp.data.error === 'Expired token') {
              resolve(dispatch('refreshRequest', data))
            } else if (resp.data.error === 'Необходимо авторизоваться') {
              router.push('/login')
            } else if (!data.ignoreErrors) {
              $notifications.showError(resp.data.error)
              resolve(resp)
            }
          } else {
            resolve(resp)
          }
        })
        .catch(err => {
          $notifications.showError(err)
          reject(err)
        })
    })
  },
  refreshRequest: ({ commit, dispatch, getters }, data) => {
    return new Promise((resolve, reject) => {
      const postData = {}
      postData.refreshToken = getters.getRefreshTokenAuth
      axios({
        url: process.env.VUE_APP_API_URL + 'companies/refresh-login',
        data: postData,
        method: 'POST'
      })
        .then(resp => {
          if (resp.data.error === 'Expired token') {
            if (router.currentRoute.value.path !== '/login') {
              localStorage.setItem(
                'lastRouterPath',
                router.currentRoute.value.path
              )
            }
            dispatch('auth/authLogout', {}, { root: true })
          } else {
            commit('auth/authSuccess', resp.data, { root: true })
            resolve(dispatch('sendRequest', data))
          }
        })
        .catch(err => {
          $notifications.showError(err)
          reject(err)
        })
    })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
