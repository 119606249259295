<template>
  <!-- BEGIN: Profile Info -->
  <div class="intro-y box px-5 pt-5 mt-5">
    <div class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5" >
      <div class="flex flex-1 px-5 items-center justify-center lg:justify-start" >
        <a href="javascript:;"
           class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative"
           data-toggle="modal"
           data-target="#upload_avatar_modal">
          <img alt="Icewall Tailwind HTML Admin Template"
               class="rounded-full"
               :src="user?.photo || require('@/assets/images/profile-1.jpg')"/>
        </a>
<!--        <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative" >-->
<!--          <img alt="Icewall Tailwind HTML Admin Template"-->
<!--               class="rounded-full"-->
<!--               :src="user?.photo || require('@/assets/images/profile-1.jpg')"/>-->
<!--        </div>-->
        <div class="ml-5">
          <div class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg" >
            {{ user.name }}
          </div>
          <div class="text-gray-600">{{ t('customer') }}</div>
        </div>
      </div>
      <div class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0" >
        <div class="font-medium text-center lg:text-left lg:mt-3">
          {{ t('contacts') }}
        </div>
        <div class="flex flex-col justify-center items-center lg:items-start mt-4" >
          <div class="truncate sm:whitespace-normal flex items-center">
            <PhoneIcon class="w-4 h-4 mr-2" />
            {{ user.phone }}
          </div>
          <div class="truncate sm:whitespace-normal flex items-center mt-3">
            <UserIcon class="w-4 h-4 mr-2" /> {{ t('id') }}:
            {{ user.id }}
          </div>
          <div v-if="user.email" class="truncate sm:whitespace-normal flex items-center mt-3">
            <MailIcon class="w-4 h-4 mr-2"/>
            {{ user.email }}
          </div>
        </div>
      </div>
      <div class="mt-6 lg:mt-0 flex-1 flex items-center justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0" >
        <div class="text-center rounded-md w-20 py-3">
          <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
            201
          </div>
          <div class="text-gray-600">{{ t('products') }}</div>
        </div>
        <div class="text-center rounded-md w-20 py-3">
          <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
            1k
          </div>
          <div class="text-gray-600">{{ t('orders') }}</div>
        </div>
        <div class="text-center rounded-md w-20 py-3">
          <div class="font-medium text-theme-1 dark:text-theme-10 text-xl">
            492
          </div>
          <div class="text-gray-600">{{ t('stock') }}</div>
        </div>
      </div>
    </div>
    <div class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
         role="tablist">
      <a id="profile-tab"
         data-toggle="tab"
         data-target="#profile"
         href="javascript:;"
         class="py-4 px-1 sm:mr-8 items-center active"
         role="tab"
         aria-controls="profile"
         aria-selected="true" >
        <UserIcon class="w-4 h-4 mr-2" /> {{ t('editProfile') }}
      </a>
      <a id="settings-tab"
         data-toggle="tab"
         data-target="#settings"
         href="javascript:;"
         class="sm:mr-8 py-4 px-1 flex items-center"
         role="tab"
         aria-selected="false">
        <SettingsIcon class="w-4 h-4 mr-2" /> {{ t('profileSettings') }}
      </a>
      <a id="tokens-tab"
         data-toggle="tab"
         data-target="#tokens"
         href="javascript:;"
         class="py-4 px-1 items-center"
         role="tab"
         aria-selected="false">
        <CodeIcon class="w-4 h-4 mr-2" /> {{ t('tokens') }}
      </a>
    </div>
  </div>
  <!-- END: Profile Info -->
  <div class="tab-content">
    <div id="profile" class="tab-pane active" role="tabpanel" aria-labelledby="profile-tab">
      <EditProfile :user="user"/>
    </div>
    <div id="settings" class="tab-pane" role="tabpanel" aria-labelledby="profile-tab">
      <div class="accordion order-details mt-8">
        <div class="accordion-item intro-y box p-4 mt-4">
          <div class="accordion-header">
            <button class="accordion-button collapsed text-lg font-medium">
              {{ t('productsSettings') }}
            </button>
          </div>
          <div class="accordion-collapse collapse">
            <ProductsSettings/>
          </div>
        </div>
        <div class="accordion-item intro-y box p-4 mt-4">
          <div class="accordion-header">
            <button class="accordion-button collapsed text-lg font-medium">
              {{ t('ordersSettings') }}
            </button>
          </div>
          <div class="accordion-collapse collapse">
            <OrderSettings/>
          </div>
        </div>
        <div class="accordion-item intro-y box p-4 mt-4">
          <div class="accordion-header">
            <button class="accordion-button collapsed text-lg font-medium">
              {{ t('logisticSettings') }}
            </button>
          </div>
          <div class="accordion-collapse collapse">
            <LogisticSettings/>
          </div>
        </div>
      </div>
    </div>
    <div id="tokens" class="tab-pane intro-y box p-5 mt-5" role="tabpanel" aria-labelledby="profile-tab">
      <div class="mb-2 break-words">
        <span class="font-medium mr-1">{{ t('widgetToken') }}:</span>
        {{ widgetToken }}
        <Tippy tag="a"
               href="javascript:;"
               class="tooltip"
               :content="t('tippyMsg')"
               :options="{ trigger: 'click', theme: 'light' }"
               @click="copyToken(widgetToken)">
          <CopyIcon class="ml-1"/>
        </Tippy>
      </div>
      <div class="break-words">
        <span class="font-medium mr-1">{{ t('hooksToken') }}:</span>
        {{ hooksToken }}
        <Tippy tag="a"
               href="javascript:;"
               class="tooltip"
               :content="t('tippyMsg')"
               :options="{ trigger: 'click', theme: 'light' }"
               @click="copyToken(hooksToken)">
          <CopyIcon class="ml-1"/>
        </Tippy>
      </div>
    </div>
  </div>
  <UploadAvatarModal :user="user"/>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import ProductsSettings from '@/components/products/products-settings/Main'
import OrderSettings from '@/components/order/order-settings/Main'
import LogisticSettings from '@/components/logistic-settings/Main'
import EditProfile from '@/components/profile/edit-profile/Main'
import UploadAvatarModal from '@/components/profile/upload-avatar/Main'
import menuItemsTranslation from '@/data/menuTranslation'

export default defineComponent({
  name: 'ProfilePage',
  components: {
    UploadAvatarModal,
    EditProfile,
    LogisticSettings,
    OrderSettings,
    ProductsSettings
  },
  setup() {
    const store = useStore()
    const widgetToken = useStoreDataGetting('user/getWidgetToken', 'user/loadWidgetToken')
    const hooksToken = useStoreDataGetting('user/getHooksToken', 'user/loadHooksToken')

    const isDarkMode = computed(() => store.getters['main/darkMode'])
    const user = computed(() => store.getters['user/getProfile'])

    const copyToken = (text) => {
      navigator.clipboard.writeText(text)
    }

    const { t } = useI18n({
      messages: {
        ar: {
          customer: 'عميل',
          id: 'هوية',
          contacts: 'اتصالات',
          editProfile: 'تعديل الملف الشخصي',
          profileSettings: 'إعدادات الملف الشخصي',
          productsSettings: 'إعدادات المنتجات',
          ordersSettings: 'إعدادات الطلبات',
          logisticSettings: 'الإعدادات اللوجستية',
          tokens: 'رموز',
          widgetToken: 'رمز الودجيت',
          hooksToken: 'رمز الخطافات',
          tippyMsg: 'نسخ!',
          ...menuItemsTranslation.ar
        },
        en: {
          customer: 'Customer',
          id: 'ID',
          contacts: 'Contacts',
          editProfile: 'Edit profile',
          profileSettings: 'Profile Settings',
          productsSettings: 'Products Settings',
          ordersSettings: 'Orders Settings',
          logisticSettings: 'Logistic Settings',
          tokens: 'Tokens',
          widgetToken: 'Widget Token',
          hooksToken: 'Hooks Token',
          tippyMsg: 'Copied!',
          ...menuItemsTranslation.en
        },
        ru: {
          customer: 'Закзачик',
          id: 'ID',
          contacts: 'Контакты',
          editProfile: 'Редактировать профиль',
          profileSettings: 'Настройки профиля',
          productsSettings: 'Настройки продуктов',
          ordersSettings: 'Настройки заказов',
          logisticSettings: 'Настройки логистики',
          tokens: 'Токены',
          widgetToken: 'Widget Token',
          hooksToken: 'Hooks Token',
          tippyMsg: 'Скопировано!',
          ...menuItemsTranslation.ru
        }
      }
    })

    return {
      user,
      widgetToken: widgetToken.data,
      hooksToken: hooksToken.data,
      isDarkMode,
      copyToken,
      t
    }
  }
})
</script>

<style scoped>

</style>
