<template>
  <div class="form-check">
    <Tippy tag="a"
           href="javascript:;"
           class="tooltip flex items-center"
           content="Заказы в СДТ попадать не будут!!"
           :options="{ theme: 'warning' }">
      <input v-model="currentSettings.sdtTestMode"
             class="form-check-switch"
             id="test_mode_checkbox"
             type="checkbox"/>
      <label class="form-check-label font-medium"
             for="test_mode_checkbox">
        {{ t('testModeMsg') }}
      </label>
    </Tippy>
  </div>
  <div class="form-check mt-4">
    <input v-model="currentSettings.autoSendToSdt"
           class="form-check-switch"
           id="auto_send_to_sdt"
           type="checkbox"/>
    <label class="form-check-label font-medium"
           for="auto_send_to_sdt">
      {{ t('autoSendMsg') }}
    </label>
  </div>
  <button class="btn btn-primary mt-4"
          @click="saveSettings">
    {{ t('saveChanges') }}
  </button>
</template>

<script>
import { computed, defineComponent, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'OrderSettings',
  setup() {
    const store = useStore()
    const orderSettings = computed(() => store.getters['orders/getOrderSettings'])
    const currentSettings = reactive({})

    const saveSettings = () => {
      store.dispatch('orders/saveOrdersSettings', currentSettings)
    }

    watch(orderSettings, orderSettings => {
      if (!orderSettings) return store.dispatch('orders/loadOrdersSettings')
      Object.keys(orderSettings).forEach(item => {
        currentSettings[item] = orderSettings[item]
      })
    }, { immediate: true })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          testModeMsg: 'وضع اختبار التكامل',
          autoSendMsg: 'أرسل الطلبات تلقائيًا إلى SDT',
          saveChanges: 'نقذ'
        },
        en: {
          testModeMsg: 'Integration testing mode',
          autoSendMsg: 'Automatically send orders to SDT',
          saveChanges: 'Save Changes'
        },
        ru: {
          testModeMsg: 'Режим тестирования интеграции с СДТ',
          autoSendMsg: 'Автоматически отправлять заказы в СДТ',
          saveChanges: 'Сохранить изменения'
        }
      }
    })

    return {
      currentSettings,
      saveSettings,
      t
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
