<template>
  <div class="input-form relative mt-4">
    <label :for="id" class="form-label mb-1 w-full flex flex-col sm:flex-row">{{
      label
    }}</label>
    <input
      :id="id"
      :value="String(modelValue)?.slice(-phoneLength) || ''"
      ref="maskedPhoneRef"
      type="text"
      class="form-control"
      :placeholder="t('placeholderText')"
      :aria-describedby="id"
      :dir="direction"
      @input="setPhoneValue"
    />
    <BaseErrorMessage :validationState="v$" />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, toRef, watch } from 'vue'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import { helpers, required, minLength } from '@vuelidate/validators'
import Inputmask from 'inputmask'
import BaseErrorMessage from '@/components/base-error-message/Main'

export default defineComponent({
  name: 'BasePhoneInput',
  components: { BaseErrorMessage },
  props: {
    id: String,
    modelValue: [String, Number],
    placeholder: String,
    label: String,
    direction: {
      type: String,
      default: 'ltr'
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const lang = computed(() => store.getters['main/getLanguage'])
    const maskedPhoneRef = ref()
    const phoneLength = ref()
    let maskedPhone

    const setPhoneValue = () => {
      const phoneVal = maskedPhoneRef.value.inputmask.unmaskedvalue()
      emit('update:modelValue', phoneVal)
    }

    const init = () => {
      if (lang.value === 'ar') {
        phoneLength.value = 8
        maskedPhone = new Inputmask('+\\971 9 9999999')
      } else {
        phoneLength.value = 10
        maskedPhone = new Inputmask('+7 (999) 999-99-99')
      }
      maskedPhone.mask(maskedPhoneRef.value)
      setPhoneValue()
    }

    watch(lang, () => {
      init()
    })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          placeholderText: 'أدخل رقم',
          requiredErrorText: 'يجب ملؤها',
          minLengthErrorText: 'الرقم قصير جدا'
        },
        en: {
          placeholderText: 'Enter number',
          requiredErrorText: 'Must be filled in',
          minLengthErrorText: 'Number’s too short'
        },
        ru: {
          placeholderText: 'Введите номер',
          requiredErrorText: 'Поле обязательно к заполнению',
          minLengthErrorText: 'Номер слишком короткий'
        }
      }
    })

    // валидация
    const phoneValue = toRef(props, 'modelValue')
    const validationRules = computed(() => ({
      required: helpers.withMessage(t('requiredErrorText'), required),
      minLength: helpers.withMessage(
        t('minLengthErrorText'),
        minLength(phoneLength.value)
      )
    }))
    const v$ = useVuelidate(validationRules, phoneValue)

    onMounted(() => {
      init()
    })

    return {
      phoneLength,
      maskedPhoneRef,
      setPhoneValue,
      t,
      v$
    }
  }
})
</script>

<style scoped></style>
