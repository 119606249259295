<template>
  <div class="px-1">
    <template v-if="mainFiltersName && $route.query[mainFiltersName] || !mainFiltersName">
      <h3 class="text-md font-medium mb-1">{{ t('title') }}</h3>
      <form class="lg:flex sm:mr-auto mb-6" @submit.prevent="">
      <div class="lg:flex items-center lg:mr-3">
        <div v-for="(item, index) in filtersParams"
             :key="item.prop"
             class="input-group relative mb-2 lg:mb-0"
             :class="{
               'lg:mr-2': index !==  (filtersParams.length - 1)
             }">
          <label
            :for="item.name"
            class="input-group-text whitespace-nowrap w-48 lg:w-auto"
          >
            {{ item.name }}
          </label>
          <input
            v-if="item.type !== 'date' && item.type !== 'select'"
            :id="item.name"
            type="text"
            class="form-control"
            v-model="filters[item.prop]"/>

          <Litepicker v-if="item.type === 'date'"
                      :value="getDatesFromUnix(filters[item.prop])"
                      @update:modelValue="onUpdateDate($event, item.prop)"
                      :options=" {
                        autoApply: true,
                        format: 'DD.MM.YYYY',
                        lang: $store.state.main.language,
                        singleMode: false,
                        setInitValue: false,
                        dropdowns: {
                          minYear: 2018,
                          months: true,
                          years: true
                        }
                      }"
                      class="form-control sm:w-48 2xl:w-full"/>

          <select v-if="item.type === 'select'"
                  class="form-select border-l-0 rounded-l-none cursor-pointer"
                  v-model="filters[item.prop]">
            <option :value="undefined"></option>
            <option v-for="option in item.selectData"
                    :key="option.ID"
                    :value="option.ID">
              {{ option.NAME }}
            </option>
          </select>
        </div>
      </div>
      <div class="mt-2 lg:mt-0 ml-auto flex">
        <router-link :to="{ name: $route.name, params: { page: '1' }, query: filters }"
                     class="btn btn-primary w-full sm:w-20 px-1"
                     tag="a">
          {{ t('searchBtn') }}
        </router-link>
        <router-link :to="{ name: $route.name, params: { page: '1' }, query: { [mainFiltersName]: $route.query[mainFiltersName] } }"
                     class="btn btn-secondary w-full sm:w-20 mt-2 sm:mt-0 sm:ml-1 px-1"
                     tag="a">
          {{ t('clearFilterBtn') }}
        </router-link>
      </div>
    </form>
    </template>
    <h4 v-if="tabsTitle" class="text-md font-medium mb-1">{{ tabsTitle }}</h4>
    <div v-if="mainFiltersName" class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start bg-gray-200 dark:bg-dark-1 rounded-t-md px-2"
         role="tablist">
      <router-link v-for="(item, index) in mainFiltersParams"
                   :key="index + '_tab'"
                   :to="{ name: $route.name, params: { page: '1' }, query: { [mainFiltersName]: item.ID } }"
                   :class="['py-3 sm:mr-8', { active: filters[mainFiltersName] === item.ID }]"
                   tag="a">
        {{ item.NAME }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper } from '@/utils/helper'

export default defineComponent({
  name: 'BaseFiltration',
  props: {
    tabsTitle: String,
    mainFiltersName: String,
    mainFiltersParams: Object,
    filtersParams: Object
  },
  setup() {
    const route = useRoute()
    const filters = ref({})

    const setFiltersByQuery = () => {
      filters.value = {}
      Object.entries(route.query).forEach(([key, val]) => {
        filters.value[key] = val
      })
    }

    const getDatesFromUnix = (unixDate) => {
      return unixDate ? helper.timePeriodFromUnixToString(unixDate) : ''
    }
    const onUpdateDate = (date, key) => {
      filters.value[key] = helper.timePeriodToUnix(date)
    }

    watch(() => route.query, () => {
      setFiltersByQuery()
    }, { immediate: true })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          title: 'مرشحات',
          searchBtn: 'بحث',
          clearFilterBtn: 'واضح'
        },
        en: {
          title: 'Filters',
          searchBtn: 'Search',
          clearFilterBtn: 'Clear'
        },
        ru: {
          title: 'Фильтры',
          searchBtn: 'Поиск',
          clearFilterBtn: 'Сбросить'
        }
      }
    })

    return {
      filters,
      getDatesFromUnix,
      onUpdateDate,
      t
    }
  }
})
</script>
