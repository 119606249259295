<template>
  <!-- BEGIN: Delete Confirmation Modal -->
  <div
    :id="id"
    class="modal rtl:right-auto rtl:left-0"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">{{ t('conformationTitle') }}</div>
            <div class="text-gray-600 mt-2">
              {{ t('conformationMessage') }} {{ description }}
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              {{ t('cancelBtn') }}
            </button>
            <button
              type="button"
              class="btn btn-danger w-24"
              data-dismiss="modal"
              @click="$emit('deleteItem')"
            >
              {{ t('confirmBtn') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Delete Confirmation Modal -->
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'DeleteConfirmationModal',
  props: {
    id: {
      type: String,
      default: 'delete-confirmation-modal'
    },
    description: String
  },
  setup() {
    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          conformationTitle: 'هل أنت متأكد؟',
          conformationMessage: 'بعد فك التثبيت، جميع الوظائف المرتبطة',
          cancelBtn: 'إلغاء',
          confirmBtn: 'حذف'
        },
        en: {
          conformationTitle: 'Are you sure?',
          conformationMessage: 'After uninstalling, all functionality associated with',
          cancelBtn: 'Cancel',
          confirmBtn: 'Delete'
        },
        ru: {
          conformationTitle: 'Вы уверены?',
          conformationMessage: 'После удаления перестанет работать весь функционал связанный с',
          cancelBtn: 'Отменить',
          confirmBtn: 'Удалить'
        }
      }
    })

    return {
      t
    }
  }
})
</script>

<style scoped>

</style>
