const state = () => {
  return {
    integrations: null,
    currentIntegration: null
  }
}

// getters
const getters = {
  getIntegrations: state => state.integrations,
  getCurrentIntegration: state => state.currentIntegration
}

// actions
const actions = {
  async loadIntegrations({ dispatch, commit }) {
    await dispatch('query/sendRequest', {
      url: 'integrations/get',
      data: {},
      method: 'POST'
    }, { root: true })
      .then(res => {
        const data = Object.values(res.data.result)
        commit('setIntegrations', data)
      })
  },
  async loadIntegrationById({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'integrations/get-by-id/' + payload.id,
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setCurrentIntegration', res.data)
      })
  },
  async addIntegration({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'integrations/add',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async updateIntegration({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'integrations/update',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async deleteIntegration({ dispatch, commit }, data) {
    await dispatch('query/sendRequest', {
      url: 'integrations/delete',
      data: data.items,
      method: 'POST'
    }, { root: true })
      .then(() => {
        commit('deleteIntegrationItem', data.items[0])
      })
  }
}

// mutations
const mutations = {
  setIntegrations(state, data) {
    state.integrations = data
  },
  deleteIntegrationItem(state, id) {
    state.integrations = state.integrations.filter(item => item.ID !== id)
  },
  setCurrentIntegration(state, data) {
    state.currentIntegration = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
