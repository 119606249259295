import _ from 'lodash'

const state = () => {
  return {
    movements: null,
    movementsPagination: null,
    currentMovement: null
  }
}

// getters
const getters = {
  getMovements: state => state.movements,
  getMovementsPagination: state => state.movementsPagination,
  getCurrentMovement: state => state.currentMovement
}

// actions
const actions = {
  async loadMovements({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'movements/get',
      filter: payload.filter,
      nav: payload.nav,
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setMovements', res.data.result)
        commit('setMovementsPagination', res.data.nav)
      })
  },
  async loadTheMovement({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'movements/get',
      filter: payload.filter,
      nav: payload.nav,
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setCurrentMovement', res.data.result)
      })
  },
  async moveStock({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'movements/add',
      data: payload,
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setMovements', res.data.result)
        commit('setMovementsPagination', res.data.nav)
      })
  },
  async updateMovement({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'movements/update',
      data: payload,
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setMovements', res.data.result)
        commit('setMovementsPagination', res.data.nav)
      })
  },
  async importDeliveriesToSDT ({ dispatch, commit }, payload) {
    return await dispatch('query/sendRequest', {
      url: 'movements/sdt-waybill-send',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async applyMovement ({ dispatch, commit }, payload) {
    return await dispatch('query/sendRequest', {
      url: 'movements/apply',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async cancelMovement ({ dispatch, commit }, payload) {
    return await dispatch('query/sendRequest', {
      url: 'movements/cancel',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async disputeMovement ({ dispatch, commit }, payload) {
    return await dispatch('query/sendRequest', {
      url: 'movements/dispute',
      data: payload,
      method: 'POST'
    }, { root: true })
  }
}

// mutations
const mutations = {
  setMovements(state, data) {
    data = Object.values(data)
    state.movements = data
  },
  setCurrentMovement(state, data) {
    const movement = Object.values(data)[0]

    Object.keys(movement).forEach(key => {
      try {
        const parsedProp = JSON.parse(movement[key])

        if (_.isObject(parsedProp)) movement[key] = parsedProp
      } catch {}
    })

    if (!_.isEmpty(movement.FF_WAYBILL_ITEMS)) {
      movement.FF_WAYBILL_ITEMS = movement.DATA.items.map(item => ({
        NAME: item?.NAME,
        ARTICLE: item?.ARTICLE,
        TOTAL_PRICE: item?.TOTAL_PRICE,
        qty: +movement.FF_WAYBILL_ITEMS[item.ARTICLE]?.qty || 0,
        qty_orig: +movement.FF_WAYBILL_ITEMS[item.ARTICLE]?.qty_orig || 0,
        qty_defect: +movement.FF_WAYBILL_ITEMS[item.ARTICLE]?.qty_defect || 0
      }))
    }

    state.currentMovement = movement
  },
  setMovementsPagination(state, data) {
    state.movementsPagination = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
