<template>
  <div class="bg-white dark:bg-dark-2 rounded-t-md mt-4 mb-9">
    <div>
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
          <tr>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ t('transportCompanyText') }}
            </th>
            <th class="border-b-2 dark:border-dark-5 text-left whitespace-nowrap">
              {{ t('servicesText') }}
            </th>
            <th class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap">
              {{ t('priceAndDeliveryTimeText') }}
            </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(calculationItem, index) in calculationData"
              :key="'deliveryCalculation' + index">
            <td class="border-b dark:border-dark-5">
              <div class="font-medium whitespace-nowrap">
                {{ calculationItem.name }}
              </div>
            </td>
            <DpdCalculation v-if="calculationItem.name === 'DPD'" :item="calculationItem.data"/>
            <YandexDeliveryCalculation v-if="calculationItem.name === 'YandexDelivery'" :item="calculationItem.data"/>
            <RuPostCalculation v-if="calculationItem.name === 'RuPost'" :item="calculationItem.data"/>
            <ShiptorCalculation v-if="calculationItem.name === 'Shiptor'" :item="calculationItem.data"/>
            <DostavistaCalculation v-if="calculationItem.name === 'Dostavista'" :item="calculationItem.data"/>
            <DeliveryGuruCalculation v-if="calculationItem.name === 'DeliveryGuru'" :item="calculationItem.data"/>
            <TakengoCalculation v-if="calculationItem.name === 'Takengo'" :item="calculationItem.data"/>
            <PecCalculation v-if="calculationItem.name === 'PEC'" :item="calculationItem.data"/>
            <KitCalculation v-if="calculationItem.name === 'KIT'" :item="calculationItem.data"/>
            <EbulkyCalculation v-if="calculationItem.name === 'EBulky'" :item="calculationItem.data"/>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import DpdCalculation from '@/components/logistics-price-calculation-form/calculation-result-items/dpd-calculation/Main'
import RuPostCalculation
from '@/components/logistics-price-calculation-form/calculation-result-items/rupost-calculation/Main'
import ShiptorCalculation
from '@/components/logistics-price-calculation-form/calculation-result-items/shiptor-calculation/Main'
import DostavistaCalculation
from '@/components/logistics-price-calculation-form/calculation-result-items/dostavista-calculation/Main'
import PecCalculation
from '@/components/logistics-price-calculation-form/calculation-result-items/pec-calculation/Main'
import KitCalculation
from '@/components/logistics-price-calculation-form/calculation-result-items/kit-calculation/Main'
import YandexDeliveryCalculation
from '@/components/logistics-price-calculation-form/calculation-result-items/yandex-delivery-calculation/Main'
import DeliveryGuruCalculation
from '@/components/logistics-price-calculation-form/calculation-result-items/delivery-guru-calculation/Main'
import TakengoCalculation from '@/components/logistics-price-calculation-form/calculation-result-items/takengo-calculation/Main'
import EbulkyCalculation
from '@/components/logistics-price-calculation-form/calculation-result-items/ebulky-calculation/Main'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'CalculationResultList',
  components: {
    EbulkyCalculation,
    TakengoCalculation,
    DeliveryGuruCalculation,
    YandexDeliveryCalculation,
    KitCalculation,
    PecCalculation,
    DostavistaCalculation,
    ShiptorCalculation,
    RuPostCalculation,
    DpdCalculation
  },
  props: {
    calculationData: Object
  },
  setup() {
    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          transportCompanyText: 'شركة نقل',
          servicesText: 'خدمات',
          priceAndDeliveryTimeText: 'السعر ووقت التسليم'
        },
        en: {
          transportCompanyText: 'Transport Company',
          servicesText: 'Services',
          priceAndDeliveryTimeText: 'Price and delivery time'
        },
        ru: {
          transportCompanyText: 'Транспортная компания',
          servicesText: 'Услуги',
          priceAndDeliveryTimeText: 'Цена и время в пути'
        }
      }
    })

    return { t }
  }
})
</script>

<style scoped>

</style>
