const state = () => {
  return {
    loyaltyStatuses: null,
    currentLoyaltyStatus: null
  }
}

// getters
const getters = {
  getLoyaltyStatuses: state => state.loyaltyStatuses,
  getCurrentLoyaltyStatus: state => state.currentLoyaltyStatus
}

// actions
const actions = {
  async loadLoyaltyStatuses({ dispatch, commit }) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-statuses/get',
      data: {},
      method: 'POST'
    }, { root: true })
      .then(res => {
        const data = Object.values(res.data.result)
        commit('setLoyaltyStatuses', data)
      })
  },
  async loadLoyaltyStatusByID({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-statuses/get-by-id/' + payload.id,
      method: 'POST'
    }, { root: true })
      .then(res => {
        commit('setCurrentLoyaltyStatus', res.data)
      })
  },
  async addLoyaltyStatuses({ getters, dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-statuses/add',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async updateLoyaltyStatuses({ getters, dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-statuses/update',
      data: payload,
      method: 'POST'
    }, { root: true })
  },
  async deleteLoyaltyStatus({ dispatch, commit }, payload) {
    await dispatch('query/sendRequest', {
      url: 'loyalty-statuses/delete',
      data: payload.items,
      method: 'POST'
    }, { root: true })
  }
}

// mutations
const mutations = {
  setLoyaltyStatuses(state, data) {
    state.loyaltyStatuses = data
  },
  setCurrentLoyaltyStatus(state, data) {
    state.currentLoyaltyStatus = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
