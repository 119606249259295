<template>
  <div class="bg-white dark:bg-dark-3 p-3 rounded-md mb-4">
    <div class="font-medium text-base mb-2">{{ t('titleText') }}</div>
    <div class="grid grid-cols-1 gap-2 md:gap-4 mb-4">
      <div class="z-50">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <AddressInput
            id="itinerary_leave_address"
            name="itineraryLeaveAddress"
            :label-text="t('deliveryAddressText')"
            :preferredAddress="preferredAddresses"
            v-model="itineraryData.leave.address"/>
          <div class="select-block input-group relative">
            <div class="input-group-text md:w-48 flex-shrink-0">
              {{ t('sendingMethodText') }}
            </div>
            <select class="form-select w-full border-l-0 rounded-l-none"
                    v-model="itineraryData.leave.delivery">
              <option value="courier" selected>{{ t('courierOptionText') }}</option>
              <option value="pvz">{{ t('deliveryPointOptionText') }}</option>
            </select>
            <BaseErrorMessage :validationState="v$" validatedProperty="leave" nestedValidatedProperty="delivery"/>
          </div>
        </div>
      </div>

      <div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <AddressInput
            id="itinerary_arrive_address"
            name="itineraryArriveAddress"
            :label-text="t('deliveryAddressText')"
            :preferredAddress="deliveryAddressData"
            v-model="itineraryData.arrive.address"/>
          <div class="select-block input-group relative">
            <div class="input-group-text md:w-48 flex-shrink-0">
              {{ t('wayToGetText') }}
            </div>
            <select class="form-select w-full border-l-0 rounded-l-none"
                    v-model="itineraryData.arrive.delivery">
              <option value="courier" selected>{{ t('courierOptionText') }}</option>
              <option value="pvz">{{ t('deliveryPointOptionText') }}</option>
            </select>
            <BaseErrorMessage :validationState="v$" validatedProperty="arrive" nestedValidatedProperty="delivery"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { helpers, required } from '@vuelidate/validators'
import { helper } from '@/utils/helper'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import AddressInput from '@/components/address-input/Main.vue'
import BaseErrorMessage from '@/components/base-error-message/Main.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ItineraryData',
  components: { AddressInput, BaseErrorMessage },
  props: {
    modelValue: Object,
    deliveryAddress: String
  },
  setup(props, { emit }) {
    const itineraryData = computed({
      get: () => props.modelValue,
      set: (val) => { emit('update:modelValue', val) }
    })

    // получение предпочтительных адресов отправки
    const store = useStore()
    const preferredAddresses = computed(() => {
      const settings = store.getters['logistics/getLogisticSettings']
      return settings.preferredSendingAddresses
    })

    // загрузка предпочтительных адресов доставки
    const deliveryAddressData = ref()
    watch(() => props.deliveryAddress, () => {
      helper.loadAddressSuggestions({ query: props.deliveryAddress }).then(res => {
        deliveryAddressData.value = res.data.suggestions
      })
    }, { immediate: true })

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          titleText: 'بيانات المسار',
          deliveryAddressText: 'عنوان التسليم',
          sendingMethodText: 'طريقة الإرسال',
          courierOptionText: 'ساع',
          deliveryPointOptionText: 'نقطة التسليم',
          wayToGetText: 'طريقة للحصول على',
          requiredFieldText: 'حقل مطلوب'
        },
        en: {
          titleText: 'Route data',
          deliveryAddressText: 'Delivery address',
          sendingMethodText: 'Sending method',
          courierOptionText: 'Courier',
          deliveryPointOptionText: 'Delivery Point',
          wayToGetText: 'Way to get',
          requiredFieldText: 'Required field'
        },
        ru: {
          titleText: 'Данные маршрута',
          deliveryAddressText: 'Адрес отправки',
          sendingMethodText: 'Способ отправки',
          courierOptionText: 'Курьер',
          deliveryPointOptionText: 'ПВЗ',
          wayToGetText: ' Способ получения',
          requiredFieldText: 'Обязательное поле'
        }
      }
    })

    // валидация
    const validationRules = computed(() => ({
      leave: {
        delivery: {
          required: helpers.withMessage(t('requiredFieldText'), required)
        }
      },
      arrive: {
        delivery: {
          required: helpers.withMessage(t('requiredFieldText'), required)
        }
      }
    }))
    const v$ = useVuelidate(validationRules, itineraryData)

    return {
      itineraryData,
      preferredAddresses,
      deliveryAddressData,
      v$,
      t
    }
  }
})
</script>

<style scoped>

</style>
