import { ref } from 'vue'
import { useStore } from 'vuex'

const useListItemRemoving = (methodPath) => {
  const store = useStore()
  const itemId = ref(false)
  const deleteItem = () => {
    store.dispatch(methodPath, { items: [itemId.value] }, { root: true })
  }

  return {
    itemId,
    deleteItem
  }
}

export { useListItemRemoving }
