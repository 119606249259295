<template>
  <!-- BEGIN: Upload Avatar modal -->
  <div id="upload_avatar_modal"
       class="modal modal-slide-over"
       tabindex="-1"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header p-5">
          <h2 class="text-lg font-medium">Загрузите свое изображение</h2>
        </div>
        <div class="modal-body">
          <Dropzone paramName="image"
                    :options="{
                      url:'https://apisf.nikit.me/file.php',
                      dictCancelUploadConfirmation: 'Вы уверены что хотите прервать загрузку файла?',
                      dictMaxFilesExceeded: 'Вы можете загрузить только одно фото',
                      dictCancelUpload: 'Остановить',
                      dictRemoveFile: 'Удалить',
                      addRemoveLinks: true,
                      multipleUpload: true,
                      maxFilesize: 5,
                      maxFiles: 1,
                      init: addDropzoneEvents
                    }" class="dropzone">
            <div class="text-lg font-medium">
              Перетащите сюда фото товара для загрузки.
            </div>
            <div class="text-gray-600">
              Максимальный размер одного файла 5МБ, не больше 20 фото.
            </div>
          </Dropzone>
          <button v-if="avatarLink"
                  class="mt-4 btn btn-primary"
                  @click="onSavePhoto">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Upload Avatar modal -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'UploadAvatarModal',
  props: {
    user: Object
  },
  setup(props) {
    const store = useStore()
    const pictureFile = ref()
    const avatarLink = ref()

    const uploadFiles = () => {
      const formData = new FormData()
      formData.append('file', pictureFile.value)
      avatarLink.value = ''

      store.dispatch('query/sendRequest', {
        url: 'companies/upload-pic/',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        formData
      }).then(res => {
        avatarLink.value = res.data[0]
      })
    }

    const addDropzoneEvents = function () {
      this.on('success', file => {
        pictureFile.value = file
        uploadFiles()
      })
      this.on('removedfile', () => {
        pictureFile.value = undefined
        avatarLink.value = ''
      })
    }

    const onSavePhoto = () => {
      store.dispatch('user/updateAvatar', {
        ID: props.user.id,
        AVATAR: avatarLink.value
      }).then(() => cash('#upload_avatar_modal').modal('hide'))
    }

    return {
      avatarLink,
      pictureFile,
      addDropzoneEvents,
      onSavePhoto
    }
  }
})
</script>
