<template>
  <div class="intro-y box p-4 mt-4">
    <template v-if="!loading">
      <vue-excel-editor v-model="clients" width="1000">
        <vue-excel-column field="COMPANY"
                          label="ID компании"
                          type="string"
                          width="200px" />
        <vue-excel-column field="FIRSTNAME"
                          label="Имя"
                          type="string"
                          width="200px" />
        <vue-excel-column field="ID"
                          label="ID"
                          type="string"
                          width="200px" />
        <vue-excel-column field="TOTALSPEND"
                          label="Потрачено баллов"
                          type="select"
                          width="200px"
                          :options="['F','M','U']" />
      </vue-excel-editor>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'

export default defineComponent({
  name: 'ExcelEditor',
  setup() {
    const clients = useStoreDataGetting(
      'clients/getClients',
      'clients/loadClients')
    return {
      clients: clients.data,
      loading: clients.loading
    }
  }
})
</script>

<style scoped>

</style>
