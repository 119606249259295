<template>
  <IntroPreloader v-if="tableLoading" />
  <IdNotFound
    v-else-if="!tableLoading && tableData === false"
    :essenceName="t('essenceNameText')"
  />
  <div class="flex flex-col" v-else>
    <a
      href="javascript:;"
      class="btn btn-primary shadow-md ml-auto inline-block"
      data-toggle="modal"
      data-target="#collect_earning_point_modal"
    >
      Начислить баллы
    </a>
    <div v-if="$_.isEmpty(tableData)" class="text-md font-medium">
      {{ t('noDataText') }}
    </div>
    <template v-else>
      <div class="flex mb-4">
        <div class="text-md font-medium mr-4">
          {{ t('remainingPointsText') }}
          {{ tableData?.calculatePoints?.totalPoints }}
        </div>
        <div class="text-md font-medium">
          {{ t('burnedPointsText') }}
          {{ tableData?.calculatePoints?.totalNoActivePoints }}
        </div>
      </div>
      <table class="table p-1">
        <thead>
          <tr class="bg-gray-200 dark:bg-dark-1">
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ t('accrualIDColText') }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ t('orderIDColText') }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ t('accruedPointsColText') }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ t('pointsSpentColText') }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ t('accrualDateColText') }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ t('burningDateColText') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(key, value, index) in tableData.points"
            :key="key.ID"
            :class="{ 'bg-gray-200 dark:bg-dark-1': index % 2 === 1 }"
          >
            <td class="border-b whitespace-nowrap">{{ key.ID }}</td>
            <td class="border-b whitespace-nowrap">
              <router-link
                :to="{ name: 'order-detail', params: { id: key.ORDER } }"
                v-if="key.ORDER"
              >
                {{ key.ORDER }}
              </router-link>
              <div v-else>Подарок</div>
            </td>
            <td class="border-b whitespace-nowrap">
              {{ Math.trunc(key.POINTS) }}
            </td>
            <td class="border-b whitespace-nowrap">
              {{ Math.trunc(key.PAID) }}
            </td>
            <td class="border-b whitespace-nowrap">
              {{ key.DATE_COLLECT }}
            </td>
            <td class="border-b whitespace-nowrap">
              {{ key.DATE_LOST }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
  <CollectEarningPoints :clientID="id" />
</template>

<script>
import { defineComponent } from 'vue'
import { useStoreDataGetting } from '@/use/useStoreDataGetting'
import { useI18n } from 'vue-i18n'
import IdNotFound from '@/components/id-not-found/Main'
import CollectEarningPoints from '@/components/client/collect-earning-points/Main.vue'

export default defineComponent({
  name: 'EarningPointsTable',
  components: { CollectEarningPoints, IdNotFound },
  props: {
    id: String
  },
  setup(props) {
    // инициализация таблицы
    const table = useStoreDataGetting(
      'clients/getEarningPointsData',
      'clients/loadEarningPointsInfo',
      { id: props.id }
    )

    // локализация
    const { t } = useI18n({
      messages: {
        ar: {
          essenceNameText: 'عميل',
          noDataText: 'أي بيانات',
          remainingPointsText: 'النقاط المتبقية',
          burnedPointsText: 'نقاط محترقة',
          accrualIDColText: 'استحقاق ID',
          orderIDColText: 'نظام ID',
          accruedPointsColText: 'مستحقة',
          pointsSpentColText: 'قضى',
          accrualDateColText: 'تاريخ الاستحقاق',
          burningDateColText: 'تاريخ الحرق'
        },
        en: {
          essenceNameText: 'Client',
          noDataText: 'No data',
          remainingPointsText: 'Remaining points:',
          burnedPointsText: 'Burned points:',
          accrualIDColText: 'accrual ID',
          orderIDColText: 'Order ID',
          accruedPointsColText: 'Accrued',
          pointsSpentColText: 'Spent',
          accrualDateColText: 'Accrual date',
          burningDateColText: 'Burning date'
        },
        ru: {
          essenceNameText: 'Клиент',
          noDataText: 'Данные отсутствуют',
          remainingPointsText: 'Остаток баллов:',
          burnedPointsText: 'Сгоревшие баллы:',
          accrualIDColText: 'ID начисления',
          orderIDColText: 'ID заказа',
          accruedPointsColText: 'Начислено',
          pointsSpentColText: 'Потрачено',
          accrualDateColText: 'Дата начисления',
          burningDateColText: 'Дата сгорания'
        }
      }
    })

    return {
      tableData: table.data,
      tableLoading: table.loading,
      t
    }
  }
})
</script>

<style scoped></style>
